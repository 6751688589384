import { ViewMode } from '../components/eligibility/eligibility.types';
import { SelectOption } from '../interfaces/common';
import {
  QueryParametersForTask,
  SortFields,
  SortOptions,
  TaskFilterInitialValues,
  TaskStatus,
} from '../interfaces/eligibility';
import { formatDateMMDDYYYY } from './dateFormat';

export const toSelectOptions = (options: string[]): SelectOption[] =>
  options.map((value) => {
    const parseValue = value.charAt(0).toUpperCase() + value.slice(1).replace(/-/g, ' ');
    return {
      label: parseValue,
      value: value,
    } as SelectOption;
  });

export const getButtonText = (viewMode: ViewMode, isSaving: boolean, resource: string) => {
  const action = isSaving
    ? viewMode === ViewMode.Create
      ? 'Creating'
      : 'Updating'
    : viewMode === ViewMode.Create
    ? 'Create'
    : 'Update';
  const loadingText = isSaving ? '...' : '';
  return `${action} ${resource} ${loadingText}`;
};

export const getInitialValuesForTask = ({
  parameters,
}: {
  parameters: QueryParametersForTask;
}): TaskFilterInitialValues => {
  const pageInitialValue = parameters?.page ? parseInt(parameters.page) : 1;
  const statusInitialValue = parameters?.status?.split(',') ?? [
    TaskStatus.MxReturned,
    TaskStatus.Pending,
  ];
  const sourceInitialValue = parameters?.source?.split(',') ?? [];
  const requestedDateFromInitialValue =
    parameters?.requestedDateFrom ?? formatDateMMDDYYYY(new Date('07/15/2023'));
  const requestedDateToInitialValue = parameters?.requestedDateTo ?? formatDateMMDDYYYY(new Date());
  const sortByInitialValue = (parameters?.sort as SortFields) ?? SortFields.RequestedDate;
  const orderInitialValue = (parameters?.order as SortOptions) ?? SortOptions.Asc;

  return {
    pageInitialValue,
    statusInitialValue,
    sourceInitialValue,
    requestedDateFromInitialValue,
    requestedDateToInitialValue,
    sortByInitialValue,
    orderInitialValue,
  };
};
