import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { IClinic } from '../../../../interfaces/clinic';
import { PayerClinic } from '../../../../interfaces/eligibility';

interface PayerClinicsTableProps {
  editableClinics: PayerClinic[];
  clinics: IClinic[];
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditAperoPayerId: (id: number, value: string) => void;
}

const PayerClinicsTable: React.FC<PayerClinicsTableProps> = ({
  editableClinics,
  clinics,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleEditAperoPayerId,
}) => {
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell>Clinic ID</TableCell>
              <TableCell>Clinic Name</TableCell>
              <TableCell>Apero Payer ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editableClinics
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((clinic) => (
                <TableRow key={clinic.clinicId}>
                  <TableCell>{clinic.clinicId}</TableCell>
                  <TableCell>{clinics.find((c) => c.id === clinic.clinicId)?.name}</TableCell>
                  <TableCell>
                    <TextField
                      value={clinic.aperoPayerId}
                      onChange={(e) => handleEditAperoPayerId(clinic.clinicId, e.target.value)}
                      variant='outlined'
                      size='small'
                      autoComplete='off'
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component='div'
        count={editableClinics.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PayerClinicsTable;
