import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { ClaimStatus, ClaimType, EhrClaim } from '../../interfaces/billing';
import { StyledTheme } from '../../styles/styleTheme';
import { formatDateToDayAndDate } from '../../util/dateFormat';

interface ClaimListProps {
  date: string;
  claims: EhrClaim[];
  onSelectEvent: (event: EhrClaim) => void;
  onClose: () => void;
}

export const ClaimList: React.FC<ClaimListProps> = ({ date, claims, onSelectEvent, onClose }) => {
  return (
    <Box className='event-list' display='flex' flexDirection='column' height='100%'>
      <Box display='flex' justifyContent='space-between'>
        <Box p={2} flexGrow={1}>
          <Button
            onClick={onClose}
            fullWidth
            style={{
              color: StyledTheme.tealDark,
            }}
            startIcon={<CloseIcon />}>
            Close
          </Button>

          <Typography variant='h6'>
            <Box style={{ textAlign: 'center' }}>
              <Typography variant='h6'>{formatDateToDayAndDate(date)}</Typography>
            </Box>
          </Typography>

          <List>
            {claims.map((claim) => {
              const className = claim.source === ClaimType.RPM ? 'rpm-event' : 'other-event';
              return (
                <ListItem key={claim.id} onClick={() => onSelectEvent(claim)} className={className}>
                  <ListItemIcon>
                    {claim.status === ClaimStatus.UploadedWithErrors ? (
                      <WarningIcon className={className} />
                    ) : (
                      <CheckCircleIcon className={className} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={`Claim ID: ${claim.id} - ${
                      claim.corePatient?.firstName || 'None provided'
                    }`}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </Box>
  );
};
