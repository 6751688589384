import LockIcon from '@mui/icons-material/Lock';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import React, { FC, useEffect, useState } from 'react';
import {
  Authenticated,
  CheckboxGroupInput,
  FileField,
  FileInput,
  SelectInput,
  SimpleForm,
  usePermissions,
} from 'react-admin';
import { ChoicesType } from '../../interfaces';
import { BillingTransform, ExtensionType, IBillingClinic } from '../../interfaces/billing';
import { AlertComponentProps, SnackBarProps } from '../../interfaces/common';
import { getClinicsForSelect } from '../../lib/clinic';
import { AuthService } from '../../services';
import { BillingService } from '../../services/billing';
import { ROLES, billingExport, labelButton } from '../../util/constants';
import { downloadFileFromBase64 } from '../../util/file';
import { CustomAlertComponent } from '../common/CustomAlert';
import { CustomSnackbar } from '../common/CustomSnackbar';
import { SectionTitle } from '../common/SectionTitle';

export const BillingExporter: FC = () => {
  const { loaded } = usePermissions();
  const [selectClinic, setSelectedClinic] = useState<IBillingClinic>();
  const [selectedBillingExport, setSelectedBillingExport] = useState<File>();
  const [selectedExportTypes, setSelectedExportTypes] = useState<string[]>();
  const [snackbarProps, setSnackbarProps] = useState<SnackBarProps>();
  const [processingBillingExport, setProcessingBillingExport] = useState<boolean>(false);
  const [clinics, setClinics] = useState<ChoicesType[]>([]);

  const getAlerts: AlertComponentProps[] = [
    {
      key: 'alert-1',
      title: 'What is the flow?',
      content: <b>{billingExport.FLOW}</b>,
      severity: 'info',
    },
    {
      key: 'alert-2',
      title: 'Documentation',
      content: (
        <Link href={billingExport.URL_DOCUMENTATION} target='_blank'>
          {billingExport.URL_DOCUMENTATION}
        </Link>
      ),
      severity: 'info',
    },
    {
      key: 'alert-3',
      title: 'Warning',
      content: <p>{billingExport.FEATURE}</p>,
      severity: 'warning',
    },
  ];

  const ContentForm = () =>
    clinics.length ? (
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Box mt={2}>
            <SelectInput
              source='clinic'
              choices={clinics}
              isRequired
              onChange={setSelectedClinic}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CheckboxGroupInput
            source='Export Type'
            choices={[{ id: 'hl7', name: 'HL7' }]}
            defaultChecked={true}
            onChange={handleChangeExportType}
            row={true}
          />
        </Grid>
        <Grid item xs={12}>
          <FileInput
            source={'files'}
            accept={'text/csv'}
            label={'File'}
            isRequired
            multiple={false}
            placeholder={<div>{billingExport.FILE_INPUT}</div>}
            onChange={setSelectedBillingExport}>
            <FileField source={'src'} title={'title'} />
          </FileInput>
        </Grid>
      </Grid>
    ) : (
      <CircularProgress />
    );

  const handleChangeExportType = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedExportTypes(event as any as string[]);

  const downloadAllFiles = async (files: BillingTransform[] | null, isHL7: boolean) => {
    if (!files) {
      return;
    }

    for (const fileItem in files) {
      const file = files[fileItem] as BillingTransform;

      if (!file.success) {
        continue;
      }

      const successFile = file.data?.file;
      const errorFile = file.data?.errors;
      const version = fileItem + 1;

      if (successFile) {
        console.info(`Downloading file ${version}...`);
        const extension = isHL7 ? ExtensionType.zip : ExtensionType.csv;
        downloadFileFromBase64(successFile, `${new Date().valueOf()}_v${version}.${extension}`);
      }

      if (errorFile) {
        console.info(`Downloading error file ${version}...`);
        downloadFileFromBase64(
          errorFile,
          `${new Date().valueOf()}_v${version}.${ExtensionType.csv}`,
        );
      }
    }

    setSnackbarProps({
      open: true,
      message: 'Downloading file(s)',
      severity: 'success',
      horizontal: 'center',
      vertical: 'top',
      onClose: () => {
        setSnackbarProps({ open: false });
      },
    });
  };

  const handleOnUploadCsv = async (): Promise<void> => {
    if (!selectClinic) {
      return setSnackbarProps({
        open: true,
        message: 'Please select a clinic',
        severity: 'error',
        horizontal: 'center',
        vertical: 'top',
        onClose: () => {
          setSnackbarProps({ open: false });
        },
      });
    }

    if (!selectedBillingExport) {
      return setSnackbarProps({
        open: true,
        message: 'Please select a file',
        severity: 'error',
        horizontal: 'center',
        vertical: 'top',
        onClose: () => {
          setSnackbarProps({ open: false });
        },
      });
    }

    setProcessingBillingExport(true);
    setSelectedBillingExport(selectedBillingExport);

    const formData: FormData = new FormData();
    formData.append('file', selectedBillingExport);

    const clinicId = Number(selectClinic.target?.value);
    const isHL7: boolean = selectedExportTypes?.includes('hl7') ?? false;
    const billingResponse = await new BillingService().getTransformedFile(
      formData,
      clinicId,
      isHL7,
    );

    setProcessingBillingExport(false);
    setSelectedClinic({ target: { value: '' } });
    setSelectedBillingExport(new File([], 'No file Selected'));

    if (!billingResponse) {
      setSnackbarProps({
        open: true,
        message: 'Something went wrong',
        severity: 'error',
        horizontal: 'center',
        vertical: 'top',
        onClose: () => {
          setSnackbarProps({ open: false });
        },
      });
    }

    await downloadAllFiles(billingResponse, isHL7);

    window.location.reload();
  };

  useEffect(() => {
    (async () => {
      const ids: number[] = JSON.parse(`${process.env.REACT_APP_DRCHRONO_ALLOWED_CLINIC_IDS}`);
      const clinicsAllowed = await getClinicsForSelect({
        ids,
      });
      setClinics(clinicsAllowed);
    })();
  }, []);

  return (
    <>
      {loaded ? (
        <>
          {AuthService.checkRoleAccess(ROLES.BILLING) ? (
            <>
              <Authenticated>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Card>
                      <CardContent>
                        <SectionTitle
                          label={billingExport.TITLE_PAGE}
                          variant='h5'
                          style={{ fontWeight: '500' }}
                        />

                        <Divider />

                        <SimpleForm toolbar={false}>
                          <ContentForm />
                        </SimpleForm>

                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                          <Button
                            variant='outlined'
                            disabled={processingBillingExport}
                            onClick={handleOnUploadCsv}
                            startIcon={<SendIcon />}>
                            {processingBillingExport ? billingExport.PROCESSING : labelButton.SEND}
                            {processingBillingExport && (
                              <CircularProgress style={{ marginLeft: 5 }} size={20} />
                            )}
                          </Button>
                        </div>

                        <div>
                          <CustomSnackbar
                            message={snackbarProps?.message}
                            vertical={snackbarProps?.vertical}
                            horizontal={snackbarProps?.horizontal}
                            onClose={snackbarProps?.onClose}
                            severity={snackbarProps?.severity}
                            open={snackbarProps?.open}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card style={{ width: '100%' }}>
                      <CardContent>
                        <SectionTitle label='Alerts' variant='h5' style={{ fontWeight: '500' }} />
                        <Divider />
                        {getAlerts.map((alert) => {
                          return (
                            <CustomAlertComponent
                              key={alert.title}
                              severity={alert.severity}
                              title={alert.title}
                              content={alert.content}
                            />
                          );
                        })}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Authenticated>
            </>
          ) : (
            <>
              <Card>
                <CardContent>
                  <Container maxWidth='md' className='e-container'>
                    <Grid container spacing={4} justifyContent='center' alignItems='center'>
                      <Grid item xs={12}>
                        <div className='e-center-content'>
                          <LockIcon className='e-icon-custom text-danger' />{' '}
                          {billingExport.NO_ACCESS}
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </CardContent>
              </Card>
            </>
          )}
        </>
      ) : (
        <>
          <h1>{billingExport.LOADING}</h1>
        </>
      )}
    </>
  );
};
