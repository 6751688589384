import { useCallback, useEffect, useRef, useState } from 'react';

const TRIGGER_SEARCH_BY_FILTER_DELAY = 700;

const useDebounce = <T>(callback: (filter: T) => void) => {
  const ref = useRef<NodeJS.Timeout>();
  const [filter, setFilter] = useState<T | null>(null);

  const onSearch = useCallback((params: T) => callback(params), []);

  useEffect(() => {
    if (ref.current) {
      clearTimeout(ref.current);
    }

    ref.current = setTimeout(async () => {
      if (filter !== null) {
        onSearch(filter);
      }
    }, TRIGGER_SEARCH_BY_FILTER_DELAY);
  }, [filter, onSearch]);

  return (params: T) => {
    setFilter(params);
  };
};

export default useDebounce;
