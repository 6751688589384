import mixpanel from 'mixpanel-browser';
import { AuthProvider } from 'react-admin';
import { AuthService } from './services/auth';
import MixpanelService from './services/mixpanel';
import { StorageName } from './util/constants';

const authProvider: AuthProvider = {
  login: async (payload) => {
    const phone = payload.username;
    const password = payload.password;
    try {
      const response = await AuthService.signIn({ phone, password });
      if (response.status === 301) {
        throw new Error(
          'You are trying to log in as a provider. In order to login to your patient account, click on the www.enaradrive.com',
        );
      } else {
        AuthService.setAuth(response.data);
        AuthService.setRoles(response.data?.roles);
        try {
          const profile = await AuthService.getProfile(response.data.auth_token);
          AuthService.setProfile(profile.data);
        } catch (e) {
          console.log(e);
        }

        MixpanelService.track('Login');
        return Promise.resolve();
      }
    } catch (err) {
      const response = (err as any).response;
      if (response && response.status === 403) {
        const { message } = response.data;
        throw new Error(message);
      }
      throw new Error(`Username or password is incorrect.`);
    }
  },
  logout: () => {
    localStorage.removeItem(StorageName.AUTH);
    localStorage.removeItem(StorageName.ROLES);
    MixpanelService.track('Logout');
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error?.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(StorageName.AUTH);
      localStorage.removeItem(StorageName.ROLES);
      return Promise.reject({ message: false });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem(StorageName.AUTH) ? Promise.resolve() : Promise.reject()),
  getPermissions: () => {
    const access = localStorage.getItem(StorageName.ROLES);
    return access ? Promise.resolve(access) : Promise.reject();
  },
  getIdentity: () => {
    try {
      const jsonAuth: any = localStorage.getItem(StorageName.PROFILE);
      const { id, fullName, avatar } = JSON.parse(jsonAuth);

      mixpanel.identify(id);
      mixpanel.people.set({ $name: fullName });

      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
