import { format } from 'date-fns';
import React from 'react';
import { useRecordContext } from 'react-admin';

interface Props extends React.HTMLAttributes<string> {
  source: string;
  label: string;
}

const DateTextField = (props: Props) => {
  const record = useRecordContext();

  const keys = props.source.split('.');

  const date = keys.reduce((prev, curr, i) => {
    if (i === 0) {
      return record[curr];
    }

    if (!prev) {
      return '';
    }

    return prev[curr as any];
  }, '');

  return <span>{date ? format(new Date(date), 'MM/dd/yyyy') : ''}</span>;
};

export default DateTextField;
