import { Card, CardContent } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Datagrid, FunctionField, Record, ShowButton, TextField, Title } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { IClinic } from '../../../interfaces/clinic';
import { InsurancePlanPaginated, PaginatedEntities } from '../../../interfaces/eligibility';
import { CommonService } from '../../../services/common';
import eligibilityService from '../../../services/eligibility';
import MixpanelService from '../../../services/mixpanel';
import ActionBar, { SearchFilters } from '../../common/ActionBar';
import { AutocompleteOption } from '../../common/Autocomplete';
import { Loader } from '../../common/Loader';
import NoRecords from '../../common/NoRecords';
import Pagination from '../../common/Pagination';
import { RelatedClinicsField } from '../../common/RelatedClinicsField';
import { SpeedDialCustom } from '../../common/SpeedDialCustom';
import { EligibilityTab } from '../eligibility.types';
import { ELIGIBILITY_ELEMENTS_PER_PAGE } from '../eligibilityTabs';

export const AllInsurancePlans = (): JSX.Element => {
  const clinics = useRef<IClinic[] | null>(null);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [insurancePlans, setInsurancePlans] = useState<PaginatedEntities<InsurancePlanPaginated>>();
  const [clinicPayerOptions, setClinicPayerOptions] = useState<AutocompleteOption[]>([]);
  const [filters, setFilters] = useState<{
    groupId: string;
    groupName: string;
    payerId: string;
    payerName: string;
    clinicId: string;
    insurancePlanId: string;
  }>({ groupId: '', groupName: '', payerId: '', payerName: '', clinicId: '', insurancePlanId: '' });

  const { pathname } = useLocation();

  const fetchInsurancePlansData = useCallback(
    async ({ page, filters }: { page: number; filters?: { [param: string]: string } }) => {
      const data = await eligibilityService.listPaginatedData<InsurancePlanPaginated>({
        ...(filters && Object.keys(filters).length ? { filters } : {}),
        page,
        resource: EligibilityTab.InsurancePlans,
      });

      if (!clinics.current) {
        clinics.current = await CommonService.getClinics();
      }

      if (data) {
        const { entities, totalCount } = data;

        setInsurancePlans(entities);
        setTotalCount(totalCount);
      }

      setIsLoaded(true);
    },
    [],
  );

  useEffect(() => {
    fetchInsurancePlansData({ page, ...(Object.values(filters) && filters) });
  }, [page, fetchInsurancePlansData]);

  useEffect(() => {
    if (!clinicPayerOptions.length && clinics.current?.length) {
      const options = clinics.current.map((op) => ({ label: op.name, value: op.id }));
      setClinicPayerOptions(options);
    }
  }, [clinics.current]);

  const handleRaiseSearch = async (
    filters: SearchFilters<
      'groupId' | 'payerId' | 'payerName' | 'clinicId' | 'groupName' | 'insurancePlanId'
    >,
  ) => {
    const { groupId, groupName, payerId, payerName, clinicId, insurancePlanId } = filters;

    const searchFilters = {
      ...(groupId.searchText && { groupId: groupId.searchText }),
      ...(groupName.searchText && { groupName: groupName.searchText }),
      ...(payerId.searchText && { payerId: payerId.searchText }),
      ...(payerName.searchText && { payerName: payerName.searchText }),
      ...(clinicId.searchText && { clinicId: clinicId.searchText }),
      ...(insurancePlanId.searchText && { insurancePlanId: insurancePlanId.searchText }),
    };

    setFilters((prev) => ({
      ...prev,
      ...searchFilters,
    }));

    setIsLoaded(false);

    await fetchInsurancePlansData({
      page,
      ...(Object.values(searchFilters) && {
        filters: {
          ...searchFilters,
        },
      }),
    });

    setIsLoaded(true);
  };

  return (
    <>
      <div>
        <Title title='Eligibility: Insurance Plans' />
        <ActionBar
          onRaiseSearch={handleRaiseSearch}
          searchInputFilters={[
            {
              filter: 'groupId',
              label: 'Group ID',
              placeholder: 'Type in group ID',
            },
            {
              filter: 'groupName',
              label: 'Group Name',
              placeholder: 'Type in group name',
            },
            {
              filter: 'payerId',
              label: 'Payer ID',
              placeholder: 'Type in payer ID',
            },
            {
              filter: 'payerName',
              label: 'Payer Name',
              placeholder: 'Type in payer name',
            },
            {
              filter: 'clinicId',
              label: 'Clinic',
              placeholder: 'Type in clinic',
              options: clinicPayerOptions,
            },
            {
              filter: 'insurancePlanId',
              label: 'Internal pID',
              placeholder: 'Type in internal pID',
            },
          ]}
        />
        <Card>
          {!isLoaded ? (
            <Loader />
          ) : (
            <CardContent>
              <Datagrid
                data={insurancePlans}
                ids={insurancePlans && Object.keys(insurancePlans)}
                currentSort={{ field: 'id', order: 'DESC' }}
                empty={<NoRecords />}>
                <TextField source='payerName' label='Payer Name' />
                <TextField source='id' label='Internal pID' />
                <TextField source='payerId' label='Payer ID' />
                <FunctionField
                  label='Related Clinics'
                  source='relatedClinicIds'
                  render={(record?: Record) => (
                    <RelatedClinicsField clinics={clinics.current || []} record={record} />
                  )}
                />
                <TextField source='planName' label='Group Name' />
                <TextField source='clinicName' label='' />
                <TextField source='planId' label='Group ID' />
                <TextField source='nationalInsurance' label='National Insurance (Primary)' />
                <TextField source='leadsCount' label='Task Count' />
                <FunctionField
                  label={'Actions'}
                  render={(record?: Record) => (
                    <ShowButton
                      label='View'
                      basePath='insurance-plans'
                      record={record}
                      onClick={() => {
                        MixpanelService.trackButtonClicked({
                          Field: 'View',
                          source: pathname,
                          Target: 'View Insurance',
                          Type: 'link',
                        });
                      }}
                    />
                  )}
                />
              </Datagrid>
            </CardContent>
          )}
        </Card>
        <SpeedDialCustom title='Create a new insurance plan' pathname={`${pathname}/create`} />
        <Pagination
          pages={Math.ceil(totalCount / ELIGIBILITY_ELEMENTS_PER_PAGE)}
          page={page}
          onChange={setPage}
        />
      </div>
    </>
  );
};
