import { Button, ButtonProps, Modal, Paper, Skeleton, Stack, SxProps } from '@mui/material';
import { useState } from 'react';
import { buttonDefaultStyles, defaultStyles } from './style';

type Props = {
  children: React.ReactChild;
  buttonProps?: ButtonProps & { buttonText: string };
  modalProps?: {
    style: SxProps;
  };
};

export const CustomModal = ({ children, buttonProps, modalProps }: Props) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen((prev) => !prev);

  return (
    <>
      <Button {...buttonProps} onClick={handleToggle}>
        {buttonProps?.buttonText ?? 'Replace me'}
      </Button>

      <Modal
        open={open}
        onClose={handleToggle}
        BackdropProps={{
          timeout: 500,
        }}>
        <Stack sx={modalProps?.style ?? defaultStyles} flexDirection='column' spacing={4}>
          <Paper sx={{ width: '100%', height: '91%' }}>
            {children ?? (
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='100%'
                height='100%'
                sx={{ borderRadius: '5px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
              />
            )}
          </Paper>
          <Paper
            sx={{
              alignSelf: 'end',
            }}>
            <Button onClick={handleToggle} sx={buttonDefaultStyles}>
              Close
            </Button>
          </Paper>
        </Stack>
      </Modal>
    </>
  );
};
