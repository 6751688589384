import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField as MuiTextField,
  Portal,
  Select,
  Snackbar,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IClinic } from '../../../interfaces/clinic';
import {
  ProcessACEResponse,
  SortFields,
  SortOptions,
  TaskSource,
  TaskStatus,
} from '../../../interfaces/eligibility';
import { getClinicsForSelect } from '../../../lib/clinic';
import EligibilityService from '../../../services/eligibility';
import { StyledTheme } from '../../../styles/styleTheme';
import { AutoACEImporter, labelButton } from '../../../util/constants';
import { getResourceUrlWithQueryParams } from '../../../util/url';
import { CustomAlertComponent } from '../../common/CustomAlert';
import { CustomTable } from '../../common/CustomTable';
import { EntitySection } from '../tasks/EntitySection';
import { ACEItemStatus } from './ACEItemStatus';
import { ACEFormFields } from './ace.types';

export const ACEImporter = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [fields, setFields] = useState<ACEFormFields>({
    cookie: '',
    content: '',
  });
  const [message, setMessage] = useState<{ content: string } | null>(null);
  const [result, setResult] = useState<ProcessACEResponse | null>(null);
  const [clinics, setClinics] = useState<IClinic[]>([]);
  const [selectedClinic, setSelectedClinic] = useState('');

  useEffect(() => {
    fetchClinicData();
  }, []);

  const handleFieldsChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    setFields({
      ...fields,
      ...{ [field]: e.target.value },
    });
  };

  const handleSubmit = async () => {
    setIsProcessing(true);

    if (!fields.cookie.length || !fields.content.length || selectedClinic === '') {
      setMessage({ content: 'Please fill all fields' });
      setIsProcessing(false);
      return;
    }

    const response = await EligibilityService.processACECalendars({
      content: fields.content,
      cookie: fields.cookie,
      clinicId: selectedClinic,
    });

    if (!response) {
      setMessage({ content: 'Something went wrong' });
    }

    setResult(response);

    setIsProcessing(false);
  };

  const handleClear = () => {
    setFields({ cookie: '', content: '' });
    setResult(null);
  };

  const handleViewTasks = () => {
    const queryParams = {
      page: '1',
      sortBy: SortFields.RequestedDate,
      order: SortOptions.Desc,
      status: TaskStatus.AutoCompleted,
      source: TaskSource.AdvancedEligibility,
    };

    const resourceUrl = getResourceUrlWithQueryParams({
      queryParams,
      resourceUrl: '#/eligibility/tasks',
    });

    window.open(resourceUrl, '_blank');
  };

  const fetchClinicData = async () => {
    const clinics = await getClinicsForSelect();
    setClinics(clinics);
  };

  return (
    <>
      <EntitySection
        title='Auto-ACE'
        children={
          <>
            <Grid item xs={12} md={12}>
              <CustomAlertComponent
                key={'alert'}
                severity={'info'}
                title={'What is the flow?'}
                content={AutoACEImporter.FLOW}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <FormControl fullWidth>
                <InputLabel id='clinic-label'>Clinic</InputLabel>
                <Select
                  name='clinicId'
                  label='Clinic'
                  variant='filled'
                  value={selectedClinic}
                  size='medium'
                  required
                  inputProps={{
                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                      setSelectedClinic(e.target.value);
                    },
                  }}
                  placeholder='Clinic'>
                  {clinics?.map(({ id, name }) => (
                    <MenuItem key={`${id}-${name}`} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <MuiTextField
                fullWidth
                multiline
                name='cookie'
                key={`cookie`}
                label='Cookie'
                variant='filled'
                value={fields.cookie}
                rows={2}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldsChange(e, 'cookie')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MuiTextField
                fullWidth
                multiline
                name='content'
                key={`content`}
                label='Content'
                variant='filled'
                value={fields.content}
                rows={2}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldsChange(e, 'content')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant='outlined'
                disabled={isProcessing}
                onClick={handleSubmit}
                startIcon={<SendIcon />}>
                {isProcessing ? AutoACEImporter.LOADING : labelButton.SEND}
                {isProcessing && <CircularProgress style={{ marginLeft: 5 }} size={20} />}
              </Button>

              <Button
                variant='outlined'
                disabled={isProcessing}
                onClick={handleClear}
                startIcon={<RefreshIcon />}
                style={{ marginLeft: 10 }}>
                {labelButton.CLEAR}
              </Button>
            </Grid>
          </>
        }
      />
      {result && (
        <EntitySection
          title=''
          children={
            <>
              {result?.summary && (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                    <Chip label='Process Summary' />
                    <Button
                      variant='outlined'
                      style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
                      startIcon={<OpenInNewIcon />}
                      onClick={handleViewTasks}>
                      View Tasks In Dashboard
                    </Button>
                  </Grid>

                  <CustomTable
                    columns={[
                      { key: 'receivedCount', label: 'Received Count' },
                      { key: 'successCount', label: 'Success Count' },
                      { key: 'errorCount', label: 'Error Count' },
                    ]}
                    key={`result`}
                    rows={[
                      {
                        receivedCount: result.summary.receivedCount,
                        successCount: result.summary.successCount,
                        errorCount: result.summary.errorCount,
                      },
                    ]}
                  />
                </>
              )}

              {result?.results && (
                <>
                  <Grid item xs={12}>
                    <Chip label='Details' />
                  </Grid>
                  <CustomTable
                    columns={[
                      { key: 'patientId', label: 'Patient Id' },
                      { key: 'status', label: 'Status' },
                      { key: 'message', label: 'Message' },
                      { key: 'taskStatus', label: 'Task Status' },
                      { key: 'taskId', label: 'Task Link' },
                    ]}
                    key={`result`}
                    rows={result.results.map((result) => ({
                      patientId: result.patientId,
                      status: result.status,
                      message: result.message,
                      taskStatus: result.taskStatus ? (
                        <ACEItemStatus status={result.taskStatus} />
                      ) : (
                        ''
                      ),
                      taskId: result.taskId ? (
                        <Link target='_blank' to={`/eligibility/tasks/${result.taskId}/show`}>
                          View Task {result.taskId}
                        </Link>
                      ) : (
                        ''
                      ),
                    }))}
                  />
                </>
              )}
            </>
          }
        />
      )}
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!message}
          autoHideDuration={10000}
          onClose={() => setMessage(null)}
          message={message?.content}
        />
      </Portal>
    </>
  );
};
