import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Popover, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import React, { isValidElement, useState } from 'react';
import { StyledTheme } from '../../styles/styleTheme';

export const CustomDialog = ({
  button: { label },
  modal: { title, children },
}: {
  button: { label: string };
  modal: { title: string; children: number | string | React.ReactNode };
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClickModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  return (
    <div style={{ width: '100%' }}>
      <Button
        fullWidth
        sx={{
          padding: 0,
          justifyContent: 'left',
        }}
        onClick={handleClickModal}
        size='small'
        startIcon={
          <Add
            sx={{
              fontSize: '14px',
              color: StyledTheme.tealDark,
            }}
          />
        }>
        <Typography
          sx={{
            fontSize: '12px',
            color: StyledTheme.tealDark,
          }}>
          {label}
        </Typography>
      </Button>
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        onClose={handleClickModal}
        open={open}>
        <Box
          sx={{
            minWidth: 200,
            boxShadow:
              'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;',
            p: '25px',
            borderRadius: '4px',
          }}>
          <IconButton
            size='small'
            aria-label='close'
            onClick={handleClickModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <Typography variant='subtitle2' id='customized-dialog-title'>
            <strong>{title}</strong>
          </Typography>
          <Box>
            {isValidElement(children) ? (
              children
            ) : (
              <Typography sx={{ marginLeft: '10px' }}>{children}</Typography>
            )}
          </Box>
        </Box>
      </Popover>
    </div>
  );
};
