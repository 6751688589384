import { InsurancePlanCategory, InsurancePlanType } from '../../../interfaces/eligibility';

const MEDICARE_ADVANTAGE_PLAN_TYPES = [
  InsurancePlanType.HMO,
  InsurancePlanType.PPO,
  InsurancePlanType.FFS,
  InsurancePlanType.EPO,
  InsurancePlanType.POS,
];
const MEDICARE_PLAN_TYPES = [InsurancePlanType.Direct, InsurancePlanType.FFS];
const MEDICAID_CHIP_MCO_PLAN_TYPES = [
  InsurancePlanType.HMO,
  InsurancePlanType.FFS,
  InsurancePlanType.POS,
];
const MEDICAID_CHIP_PLAN_TYPES = [InsurancePlanType.Direct];
const VA_CHAMPVA_PLAN_TYPES = [InsurancePlanType.Direct];
const COMMERCIAL_EMPLOYER_SPONSORED_PLAN_TYPES = [
  InsurancePlanType.HMO,
  InsurancePlanType.PPO,
  InsurancePlanType.FFS,
  InsurancePlanType.EPO,
  InsurancePlanType.POS,
];
const COMMERCIAL_MARKETPLACE_PLAN_TYPES = [
  InsurancePlanType.HMO,
  InsurancePlanType.PPO,
  InsurancePlanType.FFS,
  InsurancePlanType.EPO,
  InsurancePlanType.POS,
];
const TRICARE_PLAN_TYPES = [InsurancePlanType.Direct];
const TRICARE_MCSC_PLAN_TYPES = [InsurancePlanType.HMO, InsurancePlanType.PPO];

export const PLAN_CATEGORY_PLAN_TYPE_MAPPING = {
  [InsurancePlanCategory.CommercialEmployerSponsored]: COMMERCIAL_EMPLOYER_SPONSORED_PLAN_TYPES,
  [InsurancePlanCategory.CommercialMarketplace]: COMMERCIAL_MARKETPLACE_PLAN_TYPES,
  [InsurancePlanCategory.MedicaidChip]: MEDICAID_CHIP_PLAN_TYPES,
  [InsurancePlanCategory.MedicaidChipMco]: MEDICAID_CHIP_MCO_PLAN_TYPES,
  [InsurancePlanCategory.Medicare]: MEDICARE_PLAN_TYPES,
  [InsurancePlanCategory.MedicareAdvantage]: MEDICARE_ADVANTAGE_PLAN_TYPES,
  [InsurancePlanCategory.Tricare]: TRICARE_PLAN_TYPES,
  [InsurancePlanCategory.TricareMcsc]: TRICARE_MCSC_PLAN_TYPES,
  [InsurancePlanCategory.VaChampva]: VA_CHAMPVA_PLAN_TYPES,
};
