import { Variant as ThemeVariant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';
import { useTranslate } from 'react-admin';

export const SectionTitle = ({
  label,
  variant,
  style,
}: {
  label: string;
  variant: ThemeVariant;
  style?: CSSProperties;
}) => {
  const translate = useTranslate();
  return (
    <Typography variant={variant} gutterBottom style={style}>
      {translate(label)}
    </Typography>
  );
};
