import { Chip, List, ListItem, Typography } from '@mui/material';
import { Record } from 'react-admin';
import { IClinic } from '../../interfaces/clinic';
import { StyledTheme } from '../../styles/styleTheme';
import { CustomDialog } from './CustomDialog';

export const RelatedClinicsField = ({
  clinics,
  record,
}: {
  clinics: IClinic[];
  record?: Record;
}) => {
  const relatedClinicIds =
    clinics.filter((clinic) => record?.relatedClinicIds?.includes(clinic.id) || false) || [];

  const numberOfClinics = relatedClinicIds.length;

  if (!numberOfClinics) {
    return (
      <Chip label='No related clinics' variant='filled' sx={{ color: StyledTheme.tealDark }} />
    );
  }

  if (numberOfClinics === 1) {
    return <Typography variant='subtitle2'>{relatedClinicIds[0].name}</Typography>;
  }

  return (
    <CustomDialog
      button={{ label: `Show (${numberOfClinics})` }}
      modal={{
        title: 'Related Clinics',
        children: (
          <List
            sx={{
              listStyleType: 'disc',
              pl: 2,
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }}>
            {relatedClinicIds.map((clinic) => (
              <ListItem disableGutters disablePadding key={clinic.zip_code}>
                <Typography variant={'subtitle2'}>{clinic.name}</Typography>
              </ListItem>
            ))}
          </List>
        ),
      }}
    />
  );
};
