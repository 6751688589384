import { Button } from '@mui/material';
import { Column, CustomTable } from '../../common/CustomTable';
import { INSURANCE_BASE_PATH } from '../insurancePlans/InsurancePlans';

export type RelatedInsurance = {
  id: number;
  payerName: string;
  payerId: string;
  groupId: string;
  groupName: string;
};

export const RelatedInsurancePlan = ({ insurancePlan }: { insurancePlan: RelatedInsurance }) => {
  const columns: Column[] = [
    { key: 'id', label: 'Internal pID' },
    { key: 'payerName', label: 'Payer Name', align: 'right' },
    { key: 'payerId', label: 'Payer ID', align: 'right' },
    { key: 'groupId', label: 'Group ID', align: 'right' },
    { key: 'groupName', label: 'Group Name', align: 'right' },
    { key: 'linkToInsurancePlan', label: 'Actions', align: 'right' },
  ];

  const relatedInsurancesWithLinkDetail = {
    ...insurancePlan,
    linkToInsurancePlan: (
      <Button
        variant='outlined'
        href={`#${INSURANCE_BASE_PATH}/${insurancePlan.id}/show`}
        target='_blank'>
        View Insurance Plan
      </Button>
    ),
  };

  return <CustomTable columns={columns} rows={[relatedInsurancesWithLinkDetail]} />;
};
