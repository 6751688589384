import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  InsuranceDetail,
  MembershipComputeByVersionResponse,
} from '../../../interfaces/eligibility';

export const ShowSuggestMembership = ({
  membershipType,
  summary,
}: MembershipComputeByVersionResponse) => {
  return (
    <Box sx={{ backgroundColor: '#f5f5f5' }} p={2}>
      <Typography variant='body1'>
        Suggested Membership: <b>{membershipType}</b>
      </Typography>

      {summary?.insurance?.map((insurance: InsuranceDetail) => {
        return (
          <Box key={`container-${insurance?.type}`}>
            <Typography variant='body1'>{`${insurance?.type} Insurance`}</Typography>

            <Typography variant='body1'>{`Status: ${insurance.status}`}</Typography>

            <Typography variant='body1'>{`Payer Id: ${insurance.payerId}`}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
