import { EligibilityTab } from '../components/eligibility/eligibility.types';
import { InsuranceStatusResponse } from '../components/eligibility/tasks/pVerify/pVerify.types';
import { IClinic } from './clinic';

export type PaginationParams = {
  filterBy?: string;
  perPage?: number;
  page: number;
  resource: EligibilityTab;
  leadStatus?: string;
  source?: string;
  requiredDate?: string;
  sortBy?: string;
  order?: 'ASC' | 'DESC';
  filters?: { [filter: string]: string };
};

export type PaginatedEntities<T> = { [entityId: number]: T };

export type PaginatedEntitiesResult<T> = { entities: PaginatedEntities<T>; totalCount: number };

export enum NationalInsurance {
  No = 'no',
  Medicare = 'medicare',
  Medicaid = 'medicaid',
}

export enum InsurancePlanStatus {
  Active = 'active',
  ValidationRecommend = 'validation-recommend',
  Inactive = 'inactive',
  Pending = 'pending',
}

export enum LeadInsuranceStatus {
  Active = 'active',
  Inactive = 'inactive',
  Unknown = 'unknown',
}

export enum InsurancePlanType {
  PPO = 'PPO',
  HMO = 'HMO',
  EPO = 'EPO',
  POS = 'POS',
  HSA = 'HSA',
  NA = 'N/A',
  Direct = 'Direct',
  FFS = 'FFS',
}

export enum NetworkStatus {
  InNetwork = 'In Network',
  OONWithBenefits = 'OON With Benefits',
  Cash = 'Cash',
}

export enum StatusCodes {
  NotCovered = 'Not Covered',
  DeductibleFirst = 'Deductible first',
  CopayFirst = 'Copay first',
  CoInsurance = 'Coinsurance',
  Covered100 = 'Covered 100%',
}

export enum Membership {
  Tech = 'Tech',
  Umbrella = 'Umbrella',
  InNetwork = 'In-Network',
  Private = 'Private',
}

export enum TelehealthCoverage {
  No = 'false',
  Yes = 'true',
}

export enum TaskStatus {
  AutoCompleted = 'auto-completed',
  CompletedWithAssistance = 'completed-with-assistance',
  CompletedReportedAce = 'completed-reported-ace',
  ManuallyCompleted = 'manually-completed',
  MxNotReady = 'pending-mx-not-ready',
  MxReturned = 'pending-mx-returned',
  Pending = 'pending',
  Dismissed = 'dismissed',
}

export enum TaskSource {
  AdvancedEligibility = 'advanced-eligibility',
  CRM = 'crm',
  Manual = 'manual',
  Funnel = 'funnel',
  InPersonClinic = 'in-person-clinic',
  ReferralHub = 'referral-hub',
}

export enum EligibilityRelationship {
  Unknown = 'unknown',
  OtherRelation = 'other relation',
  Spouse = 'spouse',
  Husband = 'husband',
  Child = 'child',
  Self = 'self',
}

export enum EligibilityGender {
  Unknown = 'Unknown',
  Female = 'Female',
  Male = 'Male',
}

export const POLICY_HOLDER_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export type CreateOrUpdateEntityResponse<T> = Record<'insurancePlan' | 'leads' | 'payer', T> & {
  success: boolean;
  message?: string;
};

/**
 * Payers
 */

export type GetPaginatedPayersResponse = {
  payers: Payer[];
  totalCount: number;
  success: boolean;
};

export type PayerWithLeadCount = Payer & { leadsCount: number };

export interface PayerClinic {
  aperoPayerId: string;
  clinicId: number;
}

export type Payer = {
  id: number;
  payerId: string;
  name: string;
  memberCallNumber: string;
  providerCallNumber: string;
  notes: string;
  externalId: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: number;
  updatedBy: number;
  aperoPayerId: string;
  payerClinics: PayerClinic[];
};

export type PayerWithAuditDetails = Payer & {
  audit: AuditInfo;
};

type AuditDetails = {
  id: number;
  firstName: string;
  lastName: string;
  date: string;
};

export type AuditInfo = {
  create: AuditDetails;
  update: AuditDetails;
};

export type EditablePayerFields = {
  name: string;
  payerId: string;
  memberCallNumber: string;
  providerCallNumber: string;
  notes: string;
  externalId: string;
};

export type InsurancePlanPaginated = {
  id: number;
  payerName: string;
  planName: string;
  planId: string;
  membership: string;
  relatedClinicIds: number[];
  leadsCount: number;
  nationalInsurance: 'si' | 'no';
  planCategory: InsurancePlanCategory | null;
  payerId: string;
};

export type EntitiesPaginatedResponse<T> = {
  success: boolean;
  totalCount: number;
  entities: T[];
};

export type InsurancePlansPaginatedResponse = EntitiesPaginatedResponse<InsurancePlanPaginated>;

export type PayerPaginatedResponse = EntitiesPaginatedResponse<Payer>;

/**
 * Insurance Plans
 */

export type InsurancePlanCodeDetail = {
  id: number;
  codeName: string;
  codeDescription: string;
  coverageStatus: string;
  telehealthCoverage: boolean;
};

export type InsurancePlanDescription = {
  createdAt: Date;
  createdBy: number;
  id: number;
  insurancePlanId: number;
  nationalInsurance: NationalInsurance;
  notes: string;
  planCategory: InsurancePlanCategory | null;
  planDescription: string;
  planId: string;
  planName: string;
  planNumber: string;
  planType: string;
  status: boolean;
};

export type InsurancePlanDescriptionWithDetails = InsurancePlanDescription & {
  insurancePlans: InsurancePlan;
};

export type InsurancePlanClinic = {
  id: number;
  clinicId: number;
  enaraPlanId: number;
  status: InsurancePlanStatus;
  networkStatus: string;
  telehealthCoverage: boolean;
  totalDeductible: number;
  copay: number;
  coinsurance: number;
  planCodeDetail: InsurancePlanCodeDetail[];
  createdAt: Date;
  createdBy: number;
};

export type RelatedClinicToInsurancePlan = InsurancePlanClinic & {
  enaraPlans: EnaraPlans;
} & {
  clinic: IClinic;
};

export type RawInsurance = {
  id: number;
  clinicId: number;
  payerName: string;
  planName: string;
  planId: string;
  membership: string;
  leadsCount: number;
};

export type InsurancePlan = {
  id: number;
  planName: string;
  clinicName: string;
  groupId: string;
  memberCount: number;
  membership: string;
  status: string;
  payers?: Payer;
  insurancePlanDescription?: InsurancePlanDescription | InsurancePlanDescription[];
  insurancePlanClinic?: InsurancePlanClinic;
};

export type InsurancePlanDetail = {
  id: number;
  status: string;
  payers: Payer;
  insurancePlanDescription: InsurancePlanDescription;
  insurancePlanClinic: (Omit<InsurancePlanClinic, 'planCodeDetail'> & {
    planCodeDetail: InsurancePlanCodeDetail[];
    enaraPlans: {
      id: number;
      name: Membership;
    };
  })[];
  insurancePlanClinics: (InsurancePlanClinic & { enaraPlans: EnaraPlans })[];
  success: boolean;
};

export type InsurancePlanDetailWithAuditDetails = InsurancePlanDetail & {
  audit: AuditInfo;
};

export interface PlanCodeDetail {
  codeName: string;
  codeDescription: string;
  coverageStatus: string;
  telehealthCoverage: boolean;
}

export type EditableInsurancePlanFields = {
  audit: AuditInfo;
  clinicId: string | number;
  coinsurance: number;
  copay: number;
  insurancePlanClinicStatus: InsurancePlanStatus;
  insurancePlanDescriptionStatus: string;
  insurancePlanStatus: string;
  membersCallNumber: string;
  membership: string;
  networkStatus: string;
  notes: string;
  payer?: Payer;
  payerId: string;
  payerName?: string;
  planCategory: InsurancePlanCategory | string;
  planCodeDetail: PlanCodeDetail[];
  planId: string;
  planName: string;
  planNumber: string;
  planType: string;
  providerCallNumber: string;
  telehealthCoverage: TelehealthCoverage;
  totalDeductible: number;
};

export type EnaraPlans = {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: number;
  updatedBy: number;
};

/**
 * Leads
 */

export type LeadNotes = {
  [crmName: string]: {
    notes: {
      leadId: string;
      noteId: string;
      content: string;
      fileName: string;
      fileUrl: string;
      createdBy: string;
      modifiedByName: string;
      modifiedBy: string;
      modifiedOn: string;
    }[];
    totalCount: number;
  } | null;
};

export type TaskDetail = {
  audit: AuditInfo;
  insurancePlans: InsurancePlanDetail;
  lead: Omit<Task, 'bmi' | 'dateOfBirth' | 'notes' | 'leadCheck'> & {
    bmi?: string;
    dateOfBirth?: string;
    notes?: string;
    notesFromCrm: LeadNotes;
    leadCheck: TaskCheck;
  };
  success: boolean;
};

export type TaskCheck = {
  id: number;
  insurancePlans: InsurancePlanDetail;
  enaraPlan: number;
  activeCoverage: string;
  deductibleRemaining: number;
  outOfPocket: number;
  checkDate: Date;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date;
  updatedBy: number;
  planId: string;
  payerId: string;
  planName: string;
  insuranceStatusResponse: InsuranceStatusResponse;
  programType: Membership;
  status: string;
  isComputedAutomatically: boolean;
  crmSystemSource: string;
  primaryInsuranceSubscriber?: InsuranceSubscriber;
};

export type Task = {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  phoneNumber: string;
  bmi: number;
  nationalInsurance: NationalInsurance;
  secondaryNationalInsurance: string;
  clinicId: number;
  leadOwnerId: number;
  sourceRequest: TaskSource;
  crmId: string;
  requestInsurancePlan: string;
  requestInsuranceId: string;
  insuranceCard: boolean;
  insuranceCardFront: string;
  insuranceCardBack: string;
  requiredDate: Date;
  status: string;
  notes: string;
  primaryPolicyHolder: boolean;
  partnerPatientId: string;
  secondaryPolicyHolder: string;
  secondaryInsuranceMemberId: string;
  secondaryPayerId: string;
  secondaryPayerName: string;
  secondaryGroupId: string;
  secondaryGroupName: string;
  taskOwner: string;
  memberId: string;
  hasScheduledMsCallDate: boolean;
  isReferral: boolean;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date;
  updatedBy: number;
  deletedAt: Date;
  leadCheck: TaskCheck[];
};

export type EditableLeadFields = {
  lead: {
    firstName: string;
    lastName: string;
    clinicId: number;
    crmId: string;
    status: string;
    leadOwnerId: number;
    requiredDate: Date;
    notes?: string;
  };
  leadCheck: {
    planId: string | undefined;
    enaraPlanId: number;
    activeCoverage?: string;
    deductibleRemaining: number;
    outOfPocket: number;
    checkDate: Date;
    pVerifyInsuranceStatus: InsuranceStatusResponse;
    programType: string;
    primaryInsuranceSubscriber?: InsuranceSubscriber;
  };
  payer: {
    notes: string;
  };
  insurancePlan?: {
    id?: number;
  };
};

export type EditableFields = {
  lead: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    bmi: string;
    clinicId: string;
    crmId: string;
    status: string;
    leadOwnerId: string;
    requiredDate: string;
    requestInsuranceId: string;
    secondaryInsuranceMemberId: string;
    nationalInsurance: NationalInsurance;
    primaryPolicyHolder: boolean;
    secondaryPolicyHolder: string;
    secondaryNationalInsurance: string;
    secondaryPayerId: string;
    secondaryPayerName: string;
    secondaryGroupId: string;
    secondaryGroupName: string;
    taskOwner: string;
    sourceRequest: TaskSource;
    notes?: string;
    partnerPatientId?: string;
    memberId?: string;
    insuranceCardFront: string;
    notesFromCrm?: LeadNotes;
    externalEHRProfileLink?: string;
  };
  leadCheck: {
    planId: string | undefined;
    planName: string | undefined;
    enaraPlanId: string;
    activeCoverage?: string;
    deductibleRemaining: string;
    outOfPocket?: string;
    checkDate: string;
    programType: string;
    primaryInsuranceSubscriber?: InsuranceSubscriber;
  };
  payer: {
    notes: string;
    name: string;
    payerId: string;
  };
  insurancePlans?: {
    id?: number;
    insurancePlanClinic?: {
      id?: number;
      clinicId?: number;
    };
    insurancePlanDescription?: {
      id?: number;
      planId?: string;
      planName?: string;
    };
  };
  audit?: AuditInfo;
};

export interface InsuranceSubscriber {
  address?: string | null;
  city?: string | null;
  dateOfBirth?: string | null;
  firstName?: string | null;
  gender?: string | null;
  isPrimary?: boolean;
  lastName?: string | null;
  relationship?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export type PVerifyEligibility = {
  payerId: string;
  firstName: string;
  lastName: string;
  dob: string;
  memberInsuranceId: string;
  startDate?: string;
  endDate?: string;
};

export type EligibilityStatus = {
  status: string;
  deductible: number;
  outOfPocket: number;
  response: InsuranceStatusResponse;
};

export enum ExternalAPI {
  PVerify = 'pVerify',
  OneSource = 'OneSource',
}

export enum ProgramType {
  Remote = 'remote',
  InClinic = 'in-clinic',
}

export interface MemberDemographics {
  firstName?: string;
  lastName?: string;
  dob?: string;
  bmi?: number;
  clinicId?: number;
  programType?: ProgramType;
  memberInsuranceId?: string;
}

export interface MemberInsurance {
  payerId: string;
  groupId: string;
  groupName: string;
}

export type MembershipComputeByVersionPayload = {
  taskId?: number;
  demographics: MemberDemographics;
  primaryInsurance: MemberInsurance;
  secondaryInsurance?: MemberInsurance;
};

export enum MembershipType {
  InNetwork = 'in-network',
  Tech = 'tech',
  InNetworkHD = 'in-network-hd',
  Umbrella = 'umbrella',
  Private = 'private',
  NotEligible = 'not-eligible',
  Empty = '',
}

export type MembershipSummary = {
  memberInsuranceId?: string;
  dob?: string;
  insurance?: InsuranceDetail[];
};

export type MembershipComputeByVersionResponse = {
  insurancePlanId?: number;
  membershipType?: MembershipType;
  message?: string;
  success: boolean;
  summary?: MembershipSummary;
};

export type InsuranceDetail = {
  type: 'primary' | 'secondary';
  groupId?: string;
  groupName?: string;
  payerId: string;
  payerName?: string;
  status?: string;
  deductibleRemaining?: number;
};

export enum AuditType {
  Create = 'create',
  Update = 'update',
  LastUpdate = 'last-update',
}

export enum SortOptions {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortFields {
  RequiredDate = 'requiredDate',
  RequestedDate = 'requestedDate',
  Priority = 'priorityLevel',
}

export interface PatientProcessedResponse {
  patientId: number;
  taskId: number;
  taskStatus: TaskStatus;
  status: 'success' | 'error';
  message: string;
}

export type ProcessACEResponse = {
  summary: {
    receivedCount: number;
    successCount: number;
    errorCount: number;
  };
  results: PatientProcessedResponse[];
};

export type QueryParametersForTask = {
  page: string;
  sort: string;
  order: string;
  status: string;
  source: string;
  requestedDateFrom: string;
  requestedDateTo: string;
};

export type TaskFilterInitialValues = {
  pageInitialValue: number;
  statusInitialValue: string[];
  sourceInitialValue: string[];
  requestedDateFromInitialValue: string | null;
  requestedDateToInitialValue: string | null;
  sortByInitialValue: SortFields;
  orderInitialValue: SortOptions;
};
export enum InsurancePlanCategory {
  MedicareAdvantage = 'medicare-advantage',
  Medicare = 'medicare',
  MedicaidChipMco = 'medicaid-chip-mco',
  MedicaidChip = 'medicaid-chip',
  VaChampva = 'va-champva',
  CommercialEmployerSponsored = 'commercial-employer-sponsored',
  CommercialMarketplace = 'commercial-marketplace',
  Tricare = 'tricare',
  TricareMcsc = 'tricare-mcsc',
}

interface BaseResponse {
  success: boolean;
  message?: string;
}

export type TaskResponse = BaseResponse & {
  lead?: Task;
};

export type InsurancePlanResponse = BaseResponse & {
  insurancePlan?: InsurancePlan;
};

export type PayerResponse = BaseResponse & {
  payer?: Payer;
};
