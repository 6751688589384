export const billingExport = {
  TITLE_PAGE: 'Billing Extract',
  FLOW: 'File from drchrono => Billing => Live claims feed => Custom export',
  URL_DOCUMENTATION: 'https://bit.ly/mankato-billing-extract-documentation',
  TEXT_ABOVE_URL: 'click link above for documentation',
  NO_FILE_CHOSEN: 'No file chosen',
  LOADING: 'Loading...',
  NO_ACCESS: 'Sorry, you are not authorized to access this option.',
  FEATURE: 'Once the file is done processing it will also be sent to your email.',
  FILE_INPUT: 'Drop (or click to upload) the csv file of the billing export that will be processed',
  PROCESSING: 'Processing...',
};

export const labelButton = {
  FILTER: 'Filter',
  SEARCH: 'Search',
  CLOSE: 'Close',
  SHOW: 'Show',
  BACK: 'Back',
  SEND: 'Send',
  CLEAR: 'Clear',
};

export const labelPartner = {
  TITLE_DETAIL: 'Partner detail',
  TITLE_APPOINTMENT: 'Appointments',
  NOT_MAPPED: 'Not mapped',
};

export const ROLES = {
  BILLING: 'Billing',
  CONTENT_EDITOR: 'Content Editor',
};

export const StorageName = {
  AUTH: 'Authorization',
  ROLES: 'Roles',
  PROFILE: 'Profile',
};

export const AutoACEImporter = {
  FLOW: 'Copy and paste from Athena => Paste the cookie from Athena EHR => Paste the content copied from Athena EHR => Proceed',
  LOADING: 'Loading...',
  PROCESSING: 'Processing...',
};
