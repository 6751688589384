import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Snackbar,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EhrClaim } from '../../interfaces/billing';
import { BillingService } from '../../services';
import { StyledTheme } from '../../styles/styleTheme';
import { formatDateToDayAndDate } from '../../util/dateFormat';
import {
  getAperoUrl,
  getDrChronoAppointmentUrl,
  getDrChronoPatientUrl,
  getEnaraUrl,
} from '../../util/url';
import { ClaimLogs } from './ClaimLogs';

const SingleClaims: React.FC = () => {
  const { claimId } = useParams<{ claimId: string }>();
  const history = useHistory();

  const [claim, setClaim] = useState<EhrClaim | null>(null);
  const [loading, setLoading] = useState(true);
  const [forceEhrFetch, setForceEhrFetch] = useState(false);
  const [showLogsDialog, setShowLogsDialog] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchClaim = async () => {
      try {
        const response = await new BillingService().getClaim(Number(claimId));
        if (response?.claim) {
          setClaim(response.claim);
        } else {
          history.push('/claims');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchClaim();
  }, [claimId, history]);

  const handleToggleLogs = () => setShowLogsDialog(!showLogsDialog);
  const handleForceEhrFetch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setForceEhrFetch(event.target.checked);
  const handleBack = () => history.push('/claims');

  const handleResync = async () => {
    if (!claim) {
      setMessage('No claim selected for resync');
      return;
    }

    try {
      const response = await new BillingService().reSyncEhrClaim(claim.id, {
        forceEhrFetching: forceEhrFetch,
      });
      if (!response) {
        setMessage('Unexpected error resyncing claim');
      } else {
        setMessage(
          response.success
            ? 'Claim resynced successfully'
            : response.message || 'Error resyncing claim',
        );
      }
    } catch (err) {
      setMessage('Error resyncing claim');
    }
  };

  if (loading) {
    return (
      <div className='loading-overlay'>
        <div className='loading-spinner'></div>
      </div>
    );
  }

  return (
    <Box>
      <Box mb={2}>
        <Button onClick={handleBack} color='primary' variant='contained'>
          <ArrowBackIcon /> Back
        </Button>
      </Box>
      <Box p={2}>
        <Box mb={2} textAlign='center'>
          <Typography variant='h6'>{formatDateToDayAndDate(claim?.serviceDate ?? '')}</Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={{ fontWeight: 'bolder' }}>Claim ID:</Typography>
          <Typography>{claim?.id ?? 'N/A'}</Typography>
        </Box>
        <Grid container spacing={2} mb={4}>
          {[
            {
              label: 'DrChrono Patient',
              value: claim?.ehrAppointmentData?.appointment?.patient
                ? `${claim?.corePatient?.firstName ?? 'N/A'} ${
                    claim?.corePatient?.lastName ?? 'N/A'
                  }`
                : 'Name not available',
              link: claim?.ehrAppointmentData?.appointment?.patient
                ? getDrChronoPatientUrl(claim?.ehrAppointmentData?.appointment?.patient)
                : '',
            },
            {
              label: 'DrChrono Appointment',
              value: claim?.ehrAppointmentData?.appointment?.id ?? 'N/A',
              link: claim?.ehrAppointmentData?.appointment?.id
                ? getDrChronoAppointmentUrl(claim?.ehrAppointmentData?.appointment?.id)
                : '',
            },
            {
              label: 'Core appointment',
              value: claim?.coreAppointmentData?.id ?? 'N/A',
              link: claim?.coreAppointmentData?.id
                ? getEnaraUrl(`/activeadmin/schedulings/${claim?.coreAppointmentData?.id}`)
                : '',
            },
            {
              label: 'Provider',
              value: claim?.coreProvider?.firstName
                ? `${claim?.coreProvider?.firstName ?? 'N/A'} ${
                    claim?.coreProvider?.lastName ?? 'N/A'
                  }`
                : 'N/A',
              link: claim?.coreProvider?.id
                ? getEnaraUrl(`/activeadmin/users/${claim?.coreProvider?.id}`)
                : '',
            },
            {
              label: 'Apero invoice',
              value: claim?.aperoInvoiceData?.invoice?.id ?? 'N/A',
              link: claim?.aperoInvoiceData?.invoice?.id
                ? getAperoUrl(`/invoices/${claim?.aperoInvoiceData?.invoice?.id}`)
                : '',
            },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Typography sx={{ fontWeight: 'bolder' }}>{item.label}:</Typography>
              <Typography>
                {item.link ? (
                  <Link href={item.link} target='_blank'>
                    {item.value}
                  </Link>
                ) : (
                  item.value
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent='space-between' alignItems='center' mt={4}>
          <Grid item>
            <Button
              size='large'
              style={{
                background: StyledTheme.grayDark,
                color: StyledTheme.white,
                textTransform: 'capitalize',
              }}
              onClick={handleToggleLogs}>
              <FormatAlignJustifyIcon /> View Logs
            </Button>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      style={{ color: StyledTheme.tealDark }}
                      value={forceEhrFetch}
                      onChange={handleForceEhrFetch}
                      checked={forceEhrFetch}
                    />
                  }
                  label='Force EHR appointment download?'
                />
              </FormGroup>
              <Button size='large' color='primary' variant='contained' onClick={handleResync}>
                Attempt Resync
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={showLogsDialog} onClose={handleToggleLogs} maxWidth='md' fullWidth>
        <DialogTitle>
          Claim logs
          <IconButton
            aria-label='close'
            onClick={handleToggleLogs}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ClaimLogs logs={claim?.ehrClaimLogs ?? []} />
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!message}
        autoHideDuration={10000}
        onClose={() => setMessage('')}
        message={message}
        ContentProps={{
          style: { marginTop: '50px' },
        }}
      />
    </Box>
  );
};

export default SingleClaims;
