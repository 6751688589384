import { Avatar } from '@mui/material';
import { useState } from 'react';
import { AppBar, useGetIdentity, UserMenu } from 'react-admin';

const CustomUserIcon = () => {
  const { identity, loading: identityLoading } = useGetIdentity();
  return (
    <>
      {identityLoading ? (
        <div>
          <span>{identity?.fullName}</span>
          <Avatar src='/user-default.png' />
        </div>
      ) : null}
    </>
  );
};

const CustomUserMenu = (props: any) => {
  const [chatVisible, setChatVisible] = useState(false);
  const { identity } = useGetIdentity();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <UserMenu {...props}></UserMenu>
      <div
        style={{
          display: identity?.id ? undefined : 'hidden',
          cursor: 'pointer',
          padding: '0 10px',
          borderLeft: '1px solid #ccc',
        }}
        onClick={() => setChatVisible(!chatVisible)}>
        ChatBot{' '}
        <div
          style={{
            display: 'inline-block',
            transition: 'all 0.6s',
            transform: `rotate(-${chatVisible ? 90 : 0}deg)`,
          }}>
          ◀️
        </div>
      </div>
      {chatVisible && identity?.id && (
        <div
          style={{
            position: 'fixed',
            right: 20,
            top: 60,
            color: 'black',
            borderRadius: 15,
            background: 'white',
            boxShadow: '0 0 10px rgba(0,0,0,0.5)',
            overflow: 'hidden',
          }}>
          <iframe
            // No margins or padding
            frameBorder='0'
            src={`https://develop.d2m6hpemon7czr.amplifyapp.com/?track_user_id=${identity?.id}`}
            style={{ width: 400, height: `calc(100vh - 80px)` }}></iframe>
        </div>
      )}
    </div>
  );
};

export const CustomAppBar = (props: any) => (
  <AppBar {...props} userMenu={<CustomUserMenu icon={<CustomUserIcon />} />} />
);
