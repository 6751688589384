import { CircularProgress } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField as MuiTextField,
  Select,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Datagrid,
  FunctionField,
  Record,
  ShowButton,
  TextField,
  Title,
  TopToolbar,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { IClinic } from '../../../interfaces/clinic';
import { SelectOption } from '../../../interfaces/common';
import {
  PaginatedEntities,
  SortFields,
  SortOptions,
  TaskSource,
  TaskStatus,
} from '../../../interfaces/eligibility';
import { Provider, User } from '../../../interfaces/provider';
import { CommonService } from '../../../services/common';
import eligibilityService from '../../../services/eligibility';
import MixpanelService, { TrackableElement } from '../../../services/mixpanel';
import { StyledTheme } from '../../../styles/styleTheme';
import { formatDateMMDDYYYY } from '../../../util/dateFormat';
import { getInitialValuesForTask, toSelectOptions } from '../../../util/eligibility';
import { isAValidDate } from '../../../util/validations';
import DateTextField from '../../common/DateTextField';
import { Loader } from '../../common/Loader';
import NoRecords from '../../common/NoRecords';
import Pagination from '../../common/Pagination';
import { SpeedDialCustom } from '../../common/SpeedDialCustom';
import { EligibilityTab } from '../eligibility.types';
import { ELIGIBILITY_ELEMENTS_PER_PAGE } from '../eligibilityTabs';
import { TaskPriorityHint } from './TaskPriorityHint';
import { TaskView, TaskWithLeadOwnerAndClinic } from './tasks.types';

export const AllLeadsChecks = () => {
  const { pathname } = useLocation();
  const searchParams = useQueryParams();
  const {
    pageInitialValue,
    statusInitialValue,
    sourceInitialValue,
    requestedDateFromInitialValue,
    requestedDateToInitialValue,
    sortByInitialValue,
    orderInitialValue,
  } = getInitialValuesForTask({
    parameters: {
      page: searchParams.page,
      status: searchParams.status,
      source: searchParams.source,
      requestedDateFrom: searchParams.requestedDateFrom,
      requestedDateTo: searchParams.requestedDateTo,
      sort: searchParams.sort,
      order: searchParams.order,
    },
  });

  const leadOwners = useRef<Provider[] | null>(null);
  const clinics = useRef<IClinic[] | null>(null);
  const taskOwners = useRef<User[] | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [tasks, setTasks] = useState<PaginatedEntities<TaskWithLeadOwnerAndClinic>>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(pageInitialValue);
  const [filterByName, setFilterByName] = useState<string>('');
  const [filterByStatus, setFilterByStatus] = useState<string[]>(statusInitialValue);
  const [filterBySource, setFilterBySource] = useState<string[]>(sourceInitialValue);
  const [filterByMemberId, setFilterByMemberId] = useState<string>('');
  const [filterByRequestedDateFrom, setFilterByRequestedDateFrom] = useState<string | null>(
    requestedDateFromInitialValue,
  );
  const [filterByRequestedDateTo, setFilterByRequestedDateTo] = useState<string | null>(
    requestedDateToInitialValue,
  );
  const [filterByNext48hr, setFilterByNext48hr] = useState<boolean>(false);
  const [filterByClinic, setFilterByClinic] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<SortFields>(sortByInitialValue);
  const [order, setOrder] = useState<SortOptions>(orderInitialValue);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const isEnabledFilter48hr = false;

  const fetchLeadsData = useCallback(
    async ({
      page,
      sortBy,
      order,
      filters,
    }: {
      page: number;
      filters?: {
        name?: string;
        status?: string;
        source?: string;
        requiredDate?: string;
        memberId?: string;
      };
      order?: 'ASC' | 'DESC';
      sortBy?: string;
    }) => {
      const paginatedTasks = await eligibilityService.listPaginatedData<TaskView>({
        page,
        sortBy,
        order,
        ...(filters && Object.keys(filters).length ? { filters } : {}),
        resource: EligibilityTab.Leads,
      });

      if (paginatedTasks) {
        const { entities, totalCount } = paginatedTasks;

        if (!leadOwners.current) {
          leadOwners.current = (await CommonService.getLeadOwners())?.providers || [];
        }

        if (!clinics.current) {
          clinics.current = await CommonService.getClinics();
        }

        if (!taskOwners.current) {
          taskOwners.current = await eligibilityService.getTaskOwners();
        }

        if (leadOwners.current && clinics.current) {
          const taskEntries = Object.entries(entities).map(([key, task]) => {
            const providerFound = leadOwners.current!.find(
              (leadOwner: Provider) => leadOwner.id === task.leadOwnerId,
            );

            let taskOwner = '';
            if (taskOwners.current) {
              const ownerFound =
                (task.taskOwner &&
                  taskOwners.current!.find(
                    (taskOwner: User) => taskOwner.id === parseInt(task.taskOwner),
                  )) ||
                null;

              if (ownerFound) {
                taskOwner = ownerFound
                  ? `${ownerFound?.first_name} ${ownerFound?.last_name}`
                  : 'Unknown';
              }
            }

            const leadOwner = providerFound
              ? `${providerFound?.first_name} ${providerFound?.last_name}`
              : '';

            const rowId = task.rowId;
            const modifiedLead: TaskWithLeadOwnerAndClinic = {
              ...task,
              id: rowId,
              originalId: task.id,
              name: task.name,
              leadOwner,
              clinic: clinics.current!.find((clinic: IClinic) => clinic.id === task.clinicId),
              taskOwner,
              sourceRequest: task.sourceRequest || TaskSource.Manual,
            };
            return [rowId, modifiedLead];
          });

          setTasks(Object.fromEntries(taskEntries));
        }

        setTotalCount(totalCount);
      }
      setIsLoaded(true);
    },
    [],
  );

  const filterOptions = (
    e: SelectChangeEvent<string[]>,
    options: string[],
    selectedOptions: string[],
    setFilter: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    let value: string[] = e.target.value as string[];
    const allOptions = options;
    const isAllOptionsSelected = options.length === selectedOptions.length;

    if (value.includes('all')) {
      value = isAllOptionsSelected ? [] : allOptions;
    }

    setFilter(value);
  };

  const filtersSet = {
    filters: {
      ...(filterByName && { name: filterByName }),
      ...(filterByStatus.length && { status: filterByStatus.join() }),
      ...(filterBySource.length && { source: filterBySource.join() }),
      ...(filterByRequestedDateFrom && { requestedDateFrom: filterByRequestedDateFrom }),
      ...(filterByRequestedDateTo && { requestedDateTo: filterByRequestedDateTo }),
      ...(filterByMemberId && { memberId: filterByMemberId }),
      ...(filterByNext48hr && { next48hr: filterByNext48hr }),
      ...(filterByClinic.length && { clinicId: filterByClinic.join() }),
    },
  };

  const handleRaiseSearch = async () => {
    setIsSearching(true);

    MixpanelService.trackButtonClicked({
      Section: 'Task Filter',
      Name: 'Search',
      Value: {
        Name: filterByName,
        Status: filterByStatus,
        Source: filterBySource,
        FromRequiredDate: filterByRequestedDateFrom,
        ToRequiredDate: filterByRequestedDateTo,
        MemberId: filterByMemberId,
        Next48hr: filterByNext48hr,
      },
    });

    await fetchLeadsData({
      page,
      ...filtersSet,
      sortBy,
      order,
    });

    setIsSearching(false);
  };

  useEffect(() => {
    fetchLeadsData({
      page,
      ...filtersSet,
      sortBy,
      order,
    });
  }, [page, fetchLeadsData]);

  const getSelectCheckboxOptions = (values: string[]): SelectOption[] => {
    return [{ label: 'All', value: 'all' }, ...toSelectOptions(values)];
  };

  const getSelectCheckboxOptionsWithLabel = (
    items: {
      label: string;
      value: string;
    }[],
  ): SelectOption[] => {
    return [{ label: 'All', value: 'all' }, ...items];
  };

  const setAndValidateFilterDate = (
    field: string,
    value: string | null,
    state: React.Dispatch<React.SetStateAction<string | null>>,
  ) => {
    if (!value || !isAValidDate(value)) {
      state('');
    } else {
      const date = formatDateMMDDYYYY(new Date(value));
      state(date);

      if (field === 'from' && filterByRequestedDateTo && date > filterByRequestedDateTo) {
        setFilterByRequestedDateTo(null);
      }
    }
  };

  return (
    <>
      <div>
        <Title title='Eligibility: Tasks' />

        <TopToolbar>
          <Grid container spacing={4} mb={1}>
            <Grid item xs={12} md={10}>
              <Grid container spacing={2}>
                <>
                  <Grid item xs={4} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='From Requested Date'
                        value={filterByRequestedDateFrom}
                        onChange={(value) =>
                          setAndValidateFilterDate('from', value, setFilterByRequestedDateFrom)
                        }
                        renderInput={(params) => (
                          <TrackableElement
                            properties={{
                              Section: 'Task Filter',
                              Name: 'From Requested Date',
                            }}>
                            <MuiTextField
                              {...params}
                              variant='filled'
                              size='small'
                              helperText={''}
                              autoComplete='off'
                            />
                          </TrackableElement>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='To Requested Date'
                        value={filterByRequestedDateTo}
                        onChange={(value) =>
                          setAndValidateFilterDate('to', value, setFilterByRequestedDateTo)
                        }
                        renderInput={(params) => (
                          <TrackableElement
                            properties={{
                              Section: 'Task Filter',
                              Name: 'To Requested Date',
                            }}>
                            <MuiTextField
                              {...params}
                              variant='filled'
                              size='small'
                              helperText={''}
                              autoComplete='off'
                            />
                          </TrackableElement>
                        )}
                        minDate={filterByRequestedDateFrom || ''}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>

                <Grid item xs={4} md={2}>
                  <TrackableElement properties={{ Section: 'Task Filter', Name: 'Name' }}>
                    <MuiTextField
                      label='Name'
                      value={filterByName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFilterByName(e.target.value)
                      }
                      variant='filled'
                      size='small'
                      fullWidth
                      style={{
                        backgroundColor: StyledTheme.grayDisabled,
                        color: StyledTheme.tealDark,
                      }}
                      autoComplete='off'
                    />
                  </TrackableElement>
                </Grid>

                <Grid item xs={4} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='taskSource-filter-select-label'>Task Source</InputLabel>
                    <TrackableElement properties={{ Section: 'Task Filter', Name: 'Source' }}>
                      <Select
                        key={`taskSource-filter-select`}
                        label='Task Source'
                        variant='filled'
                        placeholder='Task Source'
                        inputProps={{
                          name: 'source',
                        }}
                        size='small'
                        value={filterBySource}
                        multiple
                        renderValue={(selected) => selected.join(', ')}
                        onChange={(e: SelectChangeEvent<string[]>) =>
                          filterOptions(
                            e,
                            Object.values(TaskSource),
                            filterBySource,
                            setFilterBySource,
                          )
                        }>
                        {getSelectCheckboxOptions(Object.values(TaskSource)).map((option) => (
                          <MenuItem key={`taskSource-${option.label}`} value={option.value}>
                            <Checkbox
                              checked={
                                filterBySource.includes(option.value) ||
                                Object.values(TaskSource).length === filterBySource.length
                              }
                            />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </TrackableElement>
                  </FormControl>
                </Grid>

                <Grid item xs={4} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='taskStatus-filter-select-label'>Task Status</InputLabel>
                    <TrackableElement properties={{ Section: 'Task Filter', Name: 'Status' }}>
                      <Select
                        key={`taskStatus-filter-select`}
                        label='Task Status'
                        variant='filled'
                        placeholder='Task Status'
                        value={filterByStatus}
                        size='small'
                        multiple
                        renderValue={(selected) => selected.join(', ')}
                        onChange={(e: SelectChangeEvent<string[]>) =>
                          filterOptions(
                            e,
                            Object.values(TaskStatus),
                            filterByStatus,
                            setFilterByStatus,
                          )
                        }>
                        {getSelectCheckboxOptions(Object.values(TaskStatus)).map((option) => {
                          return (
                            <MenuItem key={`taskStatus-${option.label}`} value={option.value}>
                              <Checkbox
                                checked={
                                  filterByStatus.includes(option.value) ||
                                  Object.values(TaskStatus).length === filterByStatus.length
                                }
                              />
                              <ListItemText primary={option.label} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TrackableElement>
                  </FormControl>
                </Grid>

                <Grid item xs={4} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='clinic-filter-select-label'>Clinic</InputLabel>
                    <TrackableElement properties={{ Section: 'Task Filter', Name: 'Clinic' }}>
                      <Select
                        key={`clinic-filter-select`}
                        label='Clinic'
                        variant='filled'
                        placeholder='Clinic'
                        value={filterByClinic}
                        size='small'
                        multiple
                        renderValue={(selected) => selected.join(', ')}
                        onChange={(e: SelectChangeEvent<string[]>) =>
                          filterOptions(
                            e,
                            clinics.current?.map((clinic: IClinic) => `${clinic.id}`) || [],
                            filterByClinic,
                            setFilterByClinic,
                          )
                        }>
                        {getSelectCheckboxOptionsWithLabel(
                          clinics.current?.map((clinic: IClinic) => ({
                            label: clinic.name,
                            value: `${clinic.id}`,
                          })) || [],
                        ).map((option) => (
                          <MenuItem key={`clinic-${option.label}`} value={option.value}>
                            <Checkbox
                              checked={
                                filterByClinic.includes(option.value) ||
                                clinics.current?.length === filterByClinic?.length
                              }
                            />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </TrackableElement>
                  </FormControl>
                </Grid>

                <Grid item xs={4} md={2}>
                  <TrackableElement properties={{ Section: 'Task Filter', Name: 'Member ID' }}>
                    <MuiTextField
                      label='Member ID'
                      value={filterByMemberId}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFilterByMemberId(e.target.value)
                      }
                      variant='filled'
                      fullWidth
                      size='small'
                      style={{
                        backgroundColor: StyledTheme.grayDisabled,
                        color: StyledTheme.tealDark,
                      }}
                      autoComplete='off'
                    />
                  </TrackableElement>
                </Grid>

                {isEnabledFilter48hr && (
                  <Grid item xs={4} md={2}>
                    <Box
                      display='flex'
                      alignItems='center'
                      flexDirection='column'
                      justifyItems='center'
                      style={{ paddingLeft: 0 }}>
                      <Chip
                        label='Next 48hr tasks'
                        size='small'
                        style={{
                          backgroundColor: StyledTheme.tealDark,
                          color: '#ffff',
                        }}
                      />
                      <Switch onChange={(e) => setFilterByNext48hr(e.target.checked)} />
                    </Box>
                  </Grid>
                )}

                <>
                  <Grid item xs={4} md={2}>
                    <FormControl fullWidth>
                      <InputLabel id='sortBy-filter-select-label'>Sort By</InputLabel>
                      <TrackableElement properties={{ Section: 'Task Filter', Name: 'Sort By' }}>
                        <Select
                          key={`sortBy-filter-select`}
                          label='Sort By'
                          variant='filled'
                          placeholder='Sort By'
                          value={sortBy}
                          size='small'
                          onChange={(e: SelectChangeEvent<string>) =>
                            setSortBy(e.target.value as SortFields)
                          }>
                          {toSelectOptions(Object.values(SortFields)).map((option) => (
                            <MenuItem key={`sortBy-${option.label}`} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </TrackableElement>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} md={2}>
                    <FormControl fullWidth>
                      <InputLabel id='sortOrder-filter-select-label'>Sort Order</InputLabel>
                      <TrackableElement properties={{ Section: 'Task Filter', Name: 'Sort Order' }}>
                        <Select
                          key={`sortOrder-filter-select`}
                          label='Sort Order'
                          variant='filled'
                          placeholder='Sort Order'
                          value={order}
                          size='small'
                          onChange={(e: SelectChangeEvent<string>) =>
                            setOrder(e.target.value as SortOptions)
                          }>
                          {toSelectOptions(Object.values(SortOptions)).map((option) => (
                            <MenuItem key={`sortOrder-${option.label}`} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </TrackableElement>
                    </FormControl>
                  </Grid>
                </>
              </Grid>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button
                label='Search'
                size='large'
                fullWidth
                style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
                startIcon={
                  isSearching ? (
                    <CircularProgress style={{ color: '#ffffff' }} size={20} />
                  ) : (
                    <SearchIcon />
                  )
                }
                disabled={isSearching}
                onClick={handleRaiseSearch}
              />
            </Grid>
          </Grid>
        </TopToolbar>

        <Card>
          {!isLoaded ? (
            <Loader />
          ) : (
            <CardContent>
              <Datagrid
                data={tasks}
                ids={tasks ? Object.keys(tasks) : []}
                currentSort={{ field: sortBy, order }}
                empty={<NoRecords />}>
                <TextField key={`task-name`} source='name' label='Name' />
                <FunctionField
                  label={'Priority'}
                  align='center'
                  render={(record?: Record) =>
                    TaskPriorityHint({ priority: record?.priorityLevel })
                  }
                />

                <TextField key={`task-clinic-name`} source='clinic.name' label='Clinic' />
                <TextField key={`task-lead-owner`} source='leadOwner' label='Lead Owner' />
                <TextField key={`task-crmId`} source='crmId' label='CRM ID' />
                <DateTextField
                  key={`task-requested-date`}
                  source={'requestedDate'}
                  label='Requested Date'
                />
                <DateTextField
                  key={`task-required-date`}
                  source={'requiredDate'}
                  label='Required Date'
                />
                <TextField key={`task-membership`} source='membership' label='Membership' />
                <TextField key={`task-status`} source='status' label='Status' />
                <TextField key={`task-owner`} source='taskOwner' label='Task Owner' />
                <TextField key={`task-source`} source='sourceRequest' label='Task Source' />

                <FunctionField
                  label={'Actions'}
                  render={(record?: Record) => (
                    <ShowButton
                      label='View'
                      basePath='tasks'
                      record={{ id: record?.originalId }}
                      onClick={() => {
                        MixpanelService.trackButtonClicked({});
                      }}
                    />
                  )}
                />
              </Datagrid>
            </CardContent>
          )}
        </Card>

        <SpeedDialCustom title='Create a new task' pathname={`${pathname}/create`} />

        <Pagination
          pages={Math.ceil(totalCount / ELIGIBILITY_ELEMENTS_PER_PAGE)}
          page={page}
          onChange={setPage}
        />
      </div>
    </>
  );
};
