import {
  Autocomplete,
  AutocompleteInputChangeReason,
  CircularProgress,
  FilterOptionsState,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

export type AutocompleteOption = {
  id?: number | string;
  label: string;
  value: number | string;
  selected?: boolean;
  notFound?: boolean;
};

type Props = {
  onChange: (
    e: SyntheticEvent<Element, Event>,
    value: string | number | undefined,
    option: AutocompleteOption | null,
  ) => void;
  options: AutocompleteOption[];
  initialValue?: AutocompleteOption | null;
  disabled?: boolean;
  getOptionLabel?: (options: AutocompleteOption) => string;
  isLoading?: boolean;
  size?: 'small' | 'medium';
  inputProps: {
    name: string;
    label: string;
    value?: string | undefined | null;
    error?: { isError: boolean; helperText: string };
    required?: boolean;
    endAdornment?: React.ReactChild;
    missingOptionLabel?: string;
  };
};

const filter = createFilterOptions<AutocompleteOption>();

export const AutocompleteField = ({
  onChange,
  options,
  disabled = false,
  initialValue,
  getOptionLabel,
  isLoading = false,
  inputProps,
  size = 'medium',
}: Props) => {
  const [optionSelected, setOptionSelected] = useState<AutocompleteOption | null>(null);

  const addNotFoundOption = (
    options: AutocompleteOption[],
    params: FilterOptionsState<AutocompleteOption>,
  ) => {
    const filtered = filter(options, params);

    const { inputValue } = params;

    if (inputValue !== '') {
      const optionFound = options.find((option) => option.value === inputValue);

      if (!optionFound) {
        filtered.push({
          notFound: true,
          value: inputValue,
          label: `${
            inputProps.missingOptionLabel ??
            `Add a new ${inputProps.name} with value "${inputValue}"`
          }`,
        });
      }
    }

    return filtered;
  };

  const handleOptions = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === 'input') {
      onChange(event, value, optionSelected);
    }
  };

  useEffect(() => {
    if (initialValue) {
      setOptionSelected(initialValue);
    }
    if (inputProps.value) {
      setOptionSelected({ label: inputProps.value, value: inputProps.value, selected: true });
    }
  }, []);

  return (
    <Autocomplete
      options={options}
      loading={isLoading}
      value={optionSelected}
      size={size}
      fullWidth
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      onInputChange={handleOptions}
      onChange={(e: SyntheticEvent<Element, Event>, option: AutocompleteOption | null) => {
        if (!option) {
          setOptionSelected(null);
        } else {
          setOptionSelected({ ...option, selected: true });
        }
        onChange(e, option?.value, option);
      }}
      filterOptions={addNotFoundOption}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          key={`${inputProps.name}`}
          name={inputProps.name}
          label={inputProps.label}
          variant='filled'
          value={inputProps.value}
          error={!!inputProps.error?.isError}
          required={inputProps.required ?? false}
          helperText={inputProps.error?.isError ? inputProps.error?.helperText : ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: inputProps.endAdornment ?? (
              <>
                {isLoading && (
                  <CircularProgress
                    {...params}
                    color='inherit'
                    size={20}
                    style={{
                      marginBottom: '15px',
                    }}
                  />
                )}{' '}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
