import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ClaimStatus, EhrClaim } from '../../interfaces/billing';
import { SelectOption } from '../../interfaces/common';
import { StyledTheme } from '../../styles/styleTheme';
import { formatDateToDayAndDate } from '../../util/dateFormat';
import {
  getAperoUrl,
  getDrChronoAppointmentUrl,
  getDrChronoPatientUrl,
  getEnaraUrl,
} from '../../util/url';
import { ClaimLogs } from './ClaimLogs';

interface ClaimDetailsProps {
  clinics: SelectOption[];
  claim: EhrClaim;
  forceEhrFetch: boolean;
  onForceEhrFetch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  onResync: () => Promise<void>;
  onBack: () => void;
}

export const ClaimDetails: React.FC<ClaimDetailsProps> = ({
  clinics,
  claim,
  forceEhrFetch,
  onForceEhrFetch,
  onClose,
  onResync,
  onBack,
}) => {
  const [onResyncDisabled, setOnResyncDisabled] = useState(false);
  const [showLogsDialog, setShowLogsDialog] = useState(false);

  const clinic = clinics.find((c) => c.value === `${claim.clinicId}`);

  const handleToggleLogs = () => {
    setShowLogsDialog(!showLogsDialog);
  };

  const handleOnResync = async () => {
    setOnResyncDisabled(true);
    await onResync();
    setOnResyncDisabled(false);
  };

  return (
    <Box className='event-details' display='flex' flexDirection='column' height='100%'>
      <Box display='flex' justifyContent='space-between'>
        <Button onClick={onBack} style={{ color: StyledTheme.tealDark }}>
          <ArrowBackIcon /> Back
        </Button>
        <Button onClick={onClose} style={{ color: StyledTheme.tealDark }}>
          <CloseIcon /> Close
        </Button>
      </Box>
      <Box p={2} flexGrow={1}>
        <Box style={{ textAlign: 'center' }}>
          <Typography variant='h6'>{formatDateToDayAndDate(claim.serviceDate)}</Typography>
        </Box>
        {clinic && (
          <Box mb={2}>
            <Typography sx={{ fontWeight: 'bolder' }}>Clinic:</Typography>
            <Typography>
              <Link href={getEnaraUrl(`/activeadmin/clinics/${claim?.clinicId}`)} target='_blank'>
                {clinic.label}
              </Link>
            </Typography>
          </Box>
        )}
        <Box mb={2}>
          <Typography sx={{ fontWeight: 'bolder' }}>Claim ID:</Typography>
          <Typography>
            <Link href={`/#/claims/${claim.id}/show`} target='_blank'>
              {claim.id}
            </Link>
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={{ fontWeight: 'bolder' }}>DrChrono Patient:</Typography>
          <Typography>
            {claim?.ehrAppointmentData?.appointment?.patient ? (
              <Link
                href={getDrChronoPatientUrl(claim?.ehrAppointmentData?.appointment.patient)}
                target='_blank'>
                {claim?.corePatient?.firstName
                  ? `${claim?.corePatient?.firstName} ${claim?.corePatient?.lastName}`
                  : 'Name not available'}
              </Link>
            ) : (
              'N/A'
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={{ fontWeight: 'bolder' }}>DrChrono Appointment:</Typography>
          <Typography>
            {claim?.ehrAppointmentData?.appointment?.id ? (
              <Link
                href={getDrChronoAppointmentUrl(claim?.ehrAppointmentData?.appointment?.id)}
                target='_blank'>
                {claim?.ehrAppointmentData?.appointment?.id || 'N/A'}
              </Link>
            ) : (
              'N/A'
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={{ fontWeight: 'bolder' }}>Core appointment:</Typography>
          <Typography>
            {claim?.coreAppointmentData?.id ? (
              <Link
                href={getEnaraUrl(`/activeadmin/schedulings/${claim?.coreAppointmentData?.id}`)}
                target='_blank'>
                {claim?.coreAppointmentData?.id || 'N/A'}
              </Link>
            ) : (
              'N/A'
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={{ fontWeight: 'bolder' }}>Core Patient:</Typography>
          <Typography>
            {claim?.corePatient ? (
              <Link
                href={getEnaraUrl(`/activeadmin/users/${claim.corePatient.id}`)}
                target='_blank'>
                {`${claim.corePatient.firstName} ${claim.corePatient.lastName}`.trim()}
              </Link>
            ) : (
              'N/A'
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={{ fontWeight: 'bolder' }}>Core Provider:</Typography>
          <Typography>
            {claim?.coreProvider?.firstName ? (
              <Link
                href={getEnaraUrl(`/activeadmin/users/${claim?.coreProvider?.id}`)}
                target='_blank'>
                {`${claim?.coreProvider?.firstName} ${claim?.coreProvider?.lastName}`.trim()}
              </Link>
            ) : (
              'N/A'
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={{ fontWeight: 'bolder' }}>Apero invoice:</Typography>
          <Typography>
            {claim?.aperoInvoiceData?.invoice?.id ? (
              <Link
                href={getAperoUrl(`/invoices/${claim?.aperoInvoiceData?.invoice?.id}`)}
                target='_blank'>
                {claim?.aperoInvoiceData?.invoice?.id}
              </Link>
            ) : (
              'N/A'
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          <Button
            size='large'
            fullWidth
            onClick={handleToggleLogs}
            style={{
              background: StyledTheme.grayDark,
              color: StyledTheme.white,
              textTransform: 'capitalize',
            }}>
            <FormatAlignJustifyIcon /> View logs
          </Button>
        </Box>
        {claim.status !== ClaimStatus.Processed && (
          <Box mb={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    style={{ color: StyledTheme.tealDark }}
                    value={forceEhrFetch}
                    onChange={onForceEhrFetch}
                    checked={forceEhrFetch}
                  />
                }
                label='Force EHR appointment download?'
              />
            </FormGroup>

            <Button
              size='large'
              fullWidth
              color='primary'
              variant='contained'
              disabled={onResyncDisabled}
              onClick={handleOnResync}>
              Attempt resync
            </Button>
          </Box>
        )}
      </Box>

      <Dialog open={showLogsDialog} onClose={handleToggleLogs} maxWidth='md' fullWidth>
        <DialogTitle>
          Claim logs
          <IconButton
            aria-label='close'
            onClick={handleToggleLogs}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ClaimLogs logs={claim.ehrClaimLogs} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
