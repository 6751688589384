import { ChangeEvent } from 'react';

type OnError = (
  value: React.SetStateAction<{
    [field: string]: boolean;
  }>,
) => void;

export const validateField = ({
  name,
  removeError,
  onError,
}: {
  removeError?: boolean;
  name: string;
  onError: OnError;
}) => {
  if (name && !removeError) {
    onError((prev) => ({
      ...prev,
      [name]: true,
    }));
  } else {
    onError((prev) => ({
      ...prev,
      [name]: false,
    }));
  }
};

export const validateAndSetField = <T>(
  e: ChangeEvent<HTMLInputElement>,
  setErrors: OnError,
  setFields: (value: React.SetStateAction<T>) => void,
) => {
  const {
    target: { value, name },
  } = e;

  validateField({
    name,
    onError: setErrors,
    removeError: e.target.checkValidity(),
  });

  setFields((prev) => ({ ...prev, [name]: value }));
};

export const validateFieldsBeforeSubmit = (
  e: React.FormEvent<HTMLFormElement>,
  setErrors: OnError,
): boolean => {
  e.preventDefault();

  const allInputTextFields = Object.values(e.target).filter(
    (input: HTMLFormElement) => input.type === 'text' || input.type === 'number',
  );

  const validatedFields = allInputTextFields.map((input) => ({
    name: input.name,
    valid: input.checkValidity(),
  }));

  const allFieldsAreValid = validatedFields.every(({ valid }) => valid);

  if (allFieldsAreValid) {
    return true;
  } else {
    const invalidFields = validatedFields.filter((f) => !f.valid);
    invalidFields.forEach((input) => validateField({ name: input.name, onError: setErrors }));
    return false;
  }
};
