import { Add } from '@mui/icons-material';
import { Card, CardContent, Button as MuiButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
  Datagrid,
  FunctionField,
  Record,
  ShowButton,
  TextField,
  Title,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { PaginatedEntities, PayerWithLeadCount } from '../../../../interfaces/eligibility';
import eligibilityService from '../../../../services/eligibility';
import MixpanelService from '../../../../services/mixpanel';
import { StyledTheme } from '../../../../styles/styleTheme';
import ActionBar, { SearchFilters } from '../../../common/ActionBar';
import { Loader } from '../../../common/Loader';
import NoRecords from '../../../common/NoRecords';
import Pagination from '../../../common/Pagination';
import { SpeedDialCustom } from '../../../common/SpeedDialCustom';
import { EligibilityTab } from '../../eligibility.types';
import { ELIGIBILITY_ELEMENTS_PER_PAGE } from '../../eligibilityTabs';

const InsurancePlanButton = () => {
  const redirect = useRedirect();
  const { id: payerId } = useRecordContext();
  const { pathname } = useLocation();

  const handleClick = () => {
    redirect(`/eligibility/insurance-plans/payers/${payerId}/create`);

    MixpanelService.trackButtonClicked({
      Field: 'Create',
      source: pathname,
      Target: 'Create Insurance Plan From Payer',
      Type: 'link',
    });
  };

  return (
    <MuiButton
      size='small'
      style={{ color: StyledTheme.tealDark }}
      startIcon={<Add />}
      onClick={handleClick}>
      New Plan
    </MuiButton>
  );
};

export const AllPayers = (): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [payers, setPayers] = useState<PaginatedEntities<PayerWithLeadCount>>();
  const [filters, setFilters] = useState<{ name: string }>({ name: '' });

  const { pathname } = useLocation();

  const fetchPayersData = useCallback(
    async ({ page, filters }: { page: number; filters?: { [param: string]: string } }) => {
      const paginatedPayers = await eligibilityService.listPaginatedData<PayerWithLeadCount>({
        ...(filters && Object.keys(filters).length ? { filters } : {}),
        page,
        resource: EligibilityTab.Payers,
      });

      if (paginatedPayers) {
        const { entities, totalCount } = paginatedPayers;

        setPayers(entities);
        setTotalCount(totalCount);
      }

      setIsLoaded(true);
    },
    [],
  );

  useEffect(() => {
    fetchPayersData({
      page,
      ...(filters.name && {
        filters: {
          name: filters.name,
        },
      }),
    });
  }, [page, fetchPayersData]);

  const handleRaiseSearch = async (filters: SearchFilters<'name'>) => {
    const { name } = filters;

    setFilters({ name: name.searchText });

    setIsLoaded(false);

    await fetchPayersData({
      page,
      ...(name.searchText && {
        filters: {
          name: name.searchText,
        },
      }),
    });

    setIsLoaded(true);
  };

  return (
    <>
      <div>
        <Title title='Eligibility: Payers' />

        <ActionBar
          onRaiseSearch={handleRaiseSearch}
          searchInputFilters={[
            {
              filter: 'name',
              label: 'Payer Name',
              placeholder: 'Type in your Payer Name',
            },
          ]}
        />
        <Card>
          {!isLoaded ? (
            <Loader />
          ) : (
            <CardContent>
              <Datagrid
                data={payers}
                ids={payers ? Object.keys(payers) : []}
                currentSort={{ field: 'id', order: 'DESC' }}
                empty={<NoRecords />}>
                <TextField source='name' label='Payer Name' />
                <TextField source='payerId' label='Payer ID' />
                <TextField source='leadsCount' label='Task Count' />
                <TextField source='externalId' label='pVerify Payer ID' />
                <FunctionField
                  label={'Actions'}
                  render={(record?: Record) => (
                    <>
                      <ShowButton
                        label='View'
                        basePath='payers'
                        record={record}
                        onClick={() => {
                          MixpanelService.trackButtonClicked({
                            Field: 'Show',
                            source: pathname,
                            Target: 'Show Payer',
                            Type: 'link',
                          });
                        }}
                      />
                      <InsurancePlanButton />
                    </>
                  )}
                />
              </Datagrid>
            </CardContent>
          )}
        </Card>

        <SpeedDialCustom title='Create a new payer' pathname={`${pathname}/create`} />
        <Pagination
          pages={Math.ceil(totalCount / ELIGIBILITY_ELEMENTS_PER_PAGE)}
          page={page}
          onChange={setPage}
        />
      </div>
    </>
  );
};
