import { ReactNode } from 'react';

export enum EligibilityTab {
  InsurancePlans = 'insurance-plans',
  Tasks = 'tasks',
  Payers = 'payers',
  Leads = 'leads',
}

export enum EligibilityAllEntities {
  InsurancePlans = 'all-insurances',
  Leads = 'all-leads',
  Payers = 'all-payers',
  EnaraPlans = 'all-enara-plans',
  InsurancePlanDescription = 'all-insurances-description',
}

export type EligibilityTabComponent = {
  label: string;
  name: EligibilityTab;
  href: string;
  ListComponent: () => JSX.Element;
};

export enum ViewMode {
  Create = 'Create',
  Update = 'Update',
  Show = 'Show',
}

export type PlanNameOption = { planId: string; planName: string };

export enum pVerifyFields {
  memberID = 'Member ID',
  self = 'Self',
  other = 'other',
  coverage = 'coverage',
  deductible = 'deductible',
  outOfPocket = 'out of pocket',
  yes = 'yes',
  no = 'no',
  planBegin = 'Plan Begin',
  eligibilityBegin = 'Eligibility Begin',
}

export type WrapperFormProps = {
  title: string;
  children: ReactNode;
  isLoaded: boolean;
  promptProps: { message: string | null; buttonLabel: string };
  actionButtonProps: { label: string };
  basePath: string;
};

export type WithJoinFields = { joinFields: { insurancePlanId: string } };
