import { createTheme } from '@material-ui/core/styles';
import { Record } from 'react-admin';
import { StyledTheme } from './styleTheme';

const BackgroundColor = (status: string) => {
  const backgroundColor =
    status === 'completed'
      ? StyledTheme.greenLight
      : status === 'pending'
      ? StyledTheme.yellowLight
      : status === 'not-started'
      ? StyledTheme.grayLight
      : StyledTheme.white;
  return backgroundColor;
};

export const RowColor = () => (record: Record) => {
  let style = {};
  const leftColor: string = BackgroundColor(record?.agreements?.status);
  if (!leftColor) {
    return style;
  }
  return {
    ...style,
    borderLeftColor: leftColor,
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  };
};

export const theme = createTheme({
  palette: {
    primary: {
      main: StyledTheme.tealDark,
      contrastText: StyledTheme.white,
    },
    secondary: {
      main: StyledTheme.tealDark,
      contrastText: StyledTheme.white,
    },
  },
  typography: {
    fontFamily: StyledTheme.fontProximaNova,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        '&$containedPrimary': {
          backgroundColor: StyledTheme.tealDark,
          color: StyledTheme.white,
          '&:hover': {
            backgroundColor: '#237c96',
            textDecoration: 'none',
            cursor: 'pointer',
          },
        },
      },
      containedPrimary: {},
    },
  },
});
