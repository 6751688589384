import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { StyledTheme } from '../../styles/styleTheme';

export const ClaimLegend: React.FC = () => {
  return (
    <Box display='flex' justifyContent='space-around' alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Box width={12} height={12} sx={{ backgroundColor: StyledTheme.tealDark }} mr={1} />
        <Typography variant='body2'>Appointments</Typography>
      </Box>
      <Divider orientation='vertical' flexItem />
      <Box display='flex' alignItems='center'>
        <Box width={12} height={12} sx={{ backgroundColor: StyledTheme.grayLight }} mr={1} />
        <Typography variant='body2'>RPM's</Typography>
      </Box>
      <Divider orientation='vertical' flexItem />
      <Box display='flex' alignItems='center'>
        <Tooltip
          title={
            <Box>
              <Box display='flex' flexDirection='column' alignItems='flex-start'>
                <Box display='flex' alignItems='center' mb={1}>
                  <WarningIcon />
                  <Typography variant='body2'>Uploaded With Errors</Typography>
                </Box>
                <Box display='flex' alignItems='center' mb={1}>
                  <CheckCircleIcon />
                  <Typography variant='body2'>Processed</Typography>
                </Box>
                <Box display='flex' alignItems='center' mb={1}>
                  <AccessTimeFilledIcon />
                  <Typography variant='body2'>Pending</Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <CloudSyncIcon />
                  <Typography variant='body2'>Uploaded</Typography>
                </Box>
              </Box>
            </Box>
          }
          placement='right'>
          <Box display='flex' alignItems='center'>
            <InfoIcon />
            <Typography variant='body2' ml={1}>
              {' '}
              Statuses
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
