import { IClinic } from '../interfaces/clinic';
import { CommonService } from '../services/common';

export const getClinicsForSelect = async (filter?: { ids?: number[] }): Promise<IClinic[]> => {
  const response = await CommonService.getClinics();
  const mapResponse = response
    .filter((clinic) => {
      if (filter?.ids) {
        return filter.ids.includes(clinic.id);
      }
      return true;
    })
    .sort((a, b) => a.id - b.id);
  return mapResponse;
};
