import { Grid, TextField, TextField as TextInput } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { IClinic } from '../../../../interfaces/clinic';
import { getClinicsForSelect } from '../../../../lib/clinic';
import GridEmptyLine from '../../../common/GridEmptyLine';
import { AuditInformation } from '../../AuditInformation';
import { WrapperForm } from '../../WrapperForm';
import { EntitySection } from '../../tasks/EntitySection';
import {
  validateAndSetField,
  validateFieldsBeforeSubmit,
  validateField as validateFormField,
} from '../../validation';
import PayerClinicsTable from './PayerClinicTable';
import { PayerFormFields, PayerFormProps } from './payer.types';

export const PayerForm = ({
  title,
  promptProps,
  actionButtonProps,
  isLoaded,
  children,
  initialFields,
  onChange,
  onSubmit,
  action,
}: PayerFormProps): JSX.Element => {
  const [didComponentMount, setDidComponentMount] = useState<boolean>(false);
  const [fields, setFields] = useState<PayerFormFields>({
    name: '',
    payerId: '',
    memberCallNumber: '',
    providerCallNumber: '',
    notes: '',
    externalId: '',
    aperoPayerId: '',
    payerClinics: [],
  });

  const [errors, setErrors] = useState<{ [field: string]: boolean }>({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [clinics, setClinics] = useState<IClinic[]>([]);
  const [editableClinics, setEditableClinics] = useState<
    {
      clinicId: number;
      aperoPayerId: string;
    }[]
  >([]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditAperoPayerId = (id: number, value: string) => {
    const updatedClinics = editableClinics.map((clinic) =>
      clinic.clinicId === id ? { ...clinic, aperoPayerId: value } : clinic,
    );
    setEditableClinics(updatedClinics);
    setFields({ ...fields, payerClinics: updatedClinics });
  };

  const validateField = (e: ChangeEvent<HTMLInputElement>) => {
    validateAndSetField<PayerFormFields>(e, setErrors, setFields);
  };

  const validateForm = (e: React.FormEvent<HTMLFormElement>) => {
    const areValid = validateFieldsBeforeSubmit(e, setErrors);

    if (areValid) {
      onSubmit(e);
    }
  };

  useEffect(() => {
    fetchClinicData();
  }, []);

  useEffect(() => {
    if (clinics.length > 0 && initialFields) {
      const mappedClinics = clinics.map((clinic) => {
        const payerClinic = initialFields.payerClinics.find(
          (payerClinic) => payerClinic.clinicId === clinic.id,
        );
        return {
          clinicId: clinic.id,
          aperoPayerId: payerClinic ? payerClinic.aperoPayerId : '',
        };
      });
      setEditableClinics(mappedClinics);
    }
  }, [clinics, initialFields]);

  const fetchClinicData = async () => {
    const clinics = await getClinicsForSelect();
    setClinics(clinics);
  };

  useEffect(() => {
    onChange(fields);
  }, [fields]);

  useEffect(() => {
    if (initialFields) {
      if (!didComponentMount) {
        setFields(initialFields);
        setDidComponentMount(true);
      }

      if (didComponentMount && initialFields !== fields) {
        setFields(initialFields);
      }
    }
  }, [initialFields]);

  return (
    <WrapperForm
      title={title}
      promptProps={{ message: promptProps.message, buttonLabel: 'View Payers' }}
      actionButtonProps={{ label: actionButtonProps.label }}
      onSubmit={validateForm}
      isLoaded={isLoaded}
      basePath='/eligibility/payers'
      onChange={(e: ChangeEvent<HTMLFormElement>) =>
        validateFormField({
          name: e.target.name,
          onError: setErrors,
          removeError: e.target.checkValidity(),
        })
      }>
      <>
        <EntitySection
          title='Payer Information'
          children={
            <>
              <Grid item xs={12} md={12}>
                {children}
              </Grid>
              <Grid item xs={5} md={2}>
                <TextField
                  name='name'
                  variant='filled'
                  label='Payer'
                  required
                  error={errors.payer}
                  helperText={errors.payer ? 'Payer Name is required' : ''}
                  defaultValue={fields.name}
                  value={fields.name}
                  onChange={validateField}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextField
                  name='payerId'
                  variant='filled'
                  label='Payer ID'
                  required
                  error={errors.payerId}
                  helperText={errors.payerId ? 'Payer ID is required' : ''}
                  defaultValue={fields.payerId}
                  value={fields.payerId}
                  onChange={validateField}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  name='externalId'
                  variant='filled'
                  label='pVerify External ID'
                  defaultValue={fields.externalId}
                  value={fields.externalId}
                  onChange={validateField}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <TextInput
                  name='aperoPayerId'
                  variant='filled'
                  label='Apero Payer ID'
                  defaultValue={fields.aperoPayerId}
                  value={fields.aperoPayerId}
                  onChange={validateField}
                />
              </Grid>
              <GridEmptyLine />
              <Grid item xs={5} md={2}>
                <TextInput
                  name='memberCallNumber'
                  variant='filled'
                  label='Members Call #'
                  defaultValue={fields.memberCallNumber}
                  value={fields.memberCallNumber}
                  onChange={validateField}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  name='providerCallNumber'
                  variant='filled'
                  label='Providers Call #'
                  defaultValue={fields.providerCallNumber}
                  value={fields.providerCallNumber}
                  onChange={validateField}
                />
              </Grid>
              <Grid item xs={8} md={4}>
                <TextInput
                  name='notes'
                  variant='filled'
                  label='Notes'
                  defaultValue={fields.notes}
                  value={fields.notes}
                  onChange={validateField}
                  fullWidth
                />
              </Grid>
            </>
          }
        />

        <EntitySection
          title='Payer Clinics'
          children={
            <PayerClinicsTable
              editableClinics={editableClinics}
              clinics={clinics}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleEditAperoPayerId={handleEditAperoPayerId}
            />
          }
        />

        {fields.audit && (
          <EntitySection
            title='Audit Information'
            children={<AuditInformation entity='payers' audit={fields.audit} />}
          />
        )}
      </>
    </WrapperForm>
  );
};
