import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export interface Column {
  label: string;
  key: string;
  align?: 'left' | 'right';
}

interface Row {
  [key: string]: string | number | JSX.Element;
}

interface Props {
  columns: Column[];
  rows: Row[];
  minWidth?: number;
  defaultText?: string;
}

export const CustomTable = ({ columns, rows, minWidth = 650, defaultText }: Props) => {
  if (!columns.length && !rows.length) {
    return <></>;
  }

  return (
    <Table sx={{ minWidth }}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.label} align={column.align || 'left'}>
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length ? (
          rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <TableCell key={columnIndex} align={column.align || 'left'}>
                  {row[column.key]}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell>
              <Typography variant='body1'>{defaultText}</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
