import { CircularProgress } from '@material-ui/core';
import { Save } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Portal,
  Select,
  SelectChangeEvent,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as TextInput,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { SimpleForm, Title, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { IClinic } from '../../../interfaces/clinic';
import { SelectOption } from '../../../interfaces/common';
import {
  EditableInsurancePlanFields,
  EnaraPlans,
  InsurancePlan,
  InsurancePlanCategory,
  InsurancePlanDetailWithAuditDetails,
  InsurancePlanResponse,
  InsurancePlanStatus,
  InsurancePlanType,
  NetworkStatus,
  Payer,
  RelatedClinicToInsurancePlan,
  StatusCodes,
  Task,
  TelehealthCoverage,
} from '../../../interfaces/eligibility';
import { getClinicsForSelect } from '../../../lib/clinic';
import eligibilityService from '../../../services/eligibility';
import MixpanelService from '../../../services/mixpanel';
import { StyledTheme } from '../../../styles/styleTheme';
import { toSelectOptions } from '../../../util/eligibility';
import { AutocompleteField, AutocompleteOption } from '../../common/Autocomplete';
import { BackButton } from '../../common/BackButton';
import { Loader } from '../../common/Loader';
import { AuditInformation } from '../AuditInformation';
import { EligibilityAllEntities, EligibilityTab } from '../eligibility.types';
import { EntitySection } from '../tasks/EntitySection';
import { validateField } from '../validation';
import { RelatedTask, RelatedTasks } from './RelatedTasks';
import { PLAN_CATEGORY_PLAN_TYPE_MAPPING } from './rules';

const INSURANCE_PLAN_CODES = [
  { id: 1, codeName: 'G0447' },
  { id: 2, codeName: '99402' },
  { id: 3, codeName: '94690' },
  { id: 4, codeName: '99205' },
  { id: 5, codeName: '99215' },
  { id: 6, codeName: '99454' },
  { id: 7, codeName: '99457' },
];

const STATUS_CODES_OPTIONS = [
  StatusCodes.CoInsurance,
  StatusCodes.CopayFirst,
  StatusCodes.Covered100,
  StatusCodes.NotCovered,
  StatusCodes.DeductibleFirst,
];

const NETWORK_STATUS_OPTIONS = [
  NetworkStatus.Cash,
  NetworkStatus.InNetwork,
  NetworkStatus.OONWithBenefits,
];

const PLAN_CATEGORY_OPTIONS = {
  [InsurancePlanCategory.MedicareAdvantage]: 'Medicare Advantage',
  [InsurancePlanCategory.Medicare]: 'Medicare',
  [InsurancePlanCategory.MedicaidChipMco]: 'Medicaid/CHIP MCO',
  [InsurancePlanCategory.MedicaidChip]: 'Medicaid/CHIP',
  [InsurancePlanCategory.VaChampva]: 'VA/CHAMPVA',
  [InsurancePlanCategory.CommercialEmployerSponsored]: 'Commercial/Employer-Sponsored',
  [InsurancePlanCategory.CommercialMarketplace]: 'Commercial/Marketplace',
  [InsurancePlanCategory.Tricare]: 'TRICARE',
  [InsurancePlanCategory.TricareMcsc]: 'TRICARE MCSC',
};

export const INSURANCE_BASE_PATH = '/eligibility/insurance-plans';

const SNACK_BAR_MILLISECONDS_DURATION = 1500;

export const InsurancePlansCreateOrEdit = (): JSX.Element => {
  // Payer ID comes into path when an insurance plan is created from "All payers" tab.
  const { payerId, insuranceId } = useParams<{ payerId: string; insuranceId: string }>();
  const searchParams = useQueryParams();

  const [isLoading, setIsLoading] = useState<boolean>(() => Boolean(insuranceId));
  const [clinics, setClinics] = useState<IClinic[]>([]);
  const [relatedClinics, setRelatedClinics] = useState<RelatedClinicToInsurancePlan[]>([]);
  const [memberships, setMemberships] = useState<EnaraPlans[]>([]);
  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [savingChanges, setSavingChanges] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [fieldName: string]: boolean }>({});
  const [payers, setPayers] = useState<Payer[]>([]);
  const [relatedClinic, setRelatedClinic] = useState<RelatedClinicToInsurancePlan | undefined>();
  const [payerOptions, setPayerOptions] = useState<AutocompleteOption[]>([]);
  const [isLoadingPayerOptions, setIsLoadingPayerOptions] = useState(false);
  const [relatedTasks, setRelatedTasks] = useState<RelatedTask[]>([]);
  const [relatedTasksByClinic, setRelatedTasksByClinic] = useState<RelatedTask[]>([]);
  const [fields, setFields] = useState<EditableInsurancePlanFields>({
    planName: '',
    planId: searchParams.planId ?? '',
    membership: '',
    insurancePlanStatus: InsurancePlanStatus.Pending,
    payerId: searchParams.payerId ?? '',
    payerName: searchParams.payerName ?? '',
    notes: '',
    planType: '',
    networkStatus: '',
    telehealthCoverage: TelehealthCoverage.No,
    totalDeductible: 0,
    copay: 0,
    coinsurance: 0,
    clinicId: searchParams.clinicId ?? '',
    insurancePlanDescriptionStatus: 'true',
    insurancePlanClinicStatus: InsurancePlanStatus.Pending,
    membersCallNumber: '',
    providerCallNumber: '',
    planCodeDetail: INSURANCE_PLAN_CODES.map((item) => ({
      id: item.id,
      codeName: item.codeName,
      codeDescription: '',
      coverageStatus: '',
      telehealthCoverage: false,
    })),
    audit: {
      create: { id: 0, firstName: '', lastName: '', date: '' },
      update: { id: 0, firstName: '', lastName: '', date: '' },
    },
    planCategory: '',
    planNumber: '',
  });

  const isUpdateView = !!insuranceId;

  const redirect = useRedirect();

  const fetchInsurancePlan = useCallback(async (id: string, clinics: IClinic[]) => {
    const response = await eligibilityService.getDetails<InsurancePlanDetailWithAuditDetails>({
      id,
      resource: EligibilityTab.InsurancePlans,
    });

    if (!response) {
      return setResultMessage('There was an error trying to get this insurance plan');
    }

    const withClinicInfo = response.insurancePlanClinics.map((ipc) => {
      const relatedClinic = clinics.find((c) => c.id === ipc.clinicId)!;
      return { ...ipc, clinic: relatedClinic };
    });

    setRelatedClinics(withClinicInfo);

    const firstRelatedClinic = withClinicInfo?.[0] || null;

    setRelatedClinic(firstRelatedClinic);

    setFields({
      planName: response.insurancePlanDescription.planName,
      planId: response.insurancePlanDescription.planId,
      insurancePlanStatus: response.status,
      payerId: response.payers.payerId,
      notes: response.insurancePlanDescription.notes,
      planType: response.insurancePlanDescription.planType,
      membership: firstRelatedClinic?.enaraPlans?.name,
      networkStatus: firstRelatedClinic.networkStatus,
      telehealthCoverage: firstRelatedClinic.telehealthCoverage
        ? TelehealthCoverage.Yes
        : TelehealthCoverage.No,
      totalDeductible: firstRelatedClinic.totalDeductible,
      copay: firstRelatedClinic.copay,
      coinsurance: firstRelatedClinic.coinsurance,
      insurancePlanDescriptionStatus: 'true',
      insurancePlanClinicStatus: firstRelatedClinic.status || InsurancePlanStatus.Pending,
      planCodeDetail:
        firstRelatedClinic.planCodeDetail.map((item) => ({
          id: item.id ?? INSURANCE_PLAN_CODES.find((ipc) => ipc.codeName === item.codeName)?.id,
          codeName: item.codeName,
          codeDescription: item.codeDescription,
          coverageStatus: item.coverageStatus,
          telehealthCoverage: !!item.telehealthCoverage,
        })) ||
        INSURANCE_PLAN_CODES.map((code) => ({
          id: code.id,
          codeName: code.codeName,
          codeDescription: '',
          coverageStatus: '',
        })),
      payer: response.payers,
      clinicId: `${firstRelatedClinic.clinicId}`,
      membersCallNumber: '',
      providerCallNumber: '',
      audit: response.audit,
      planCategory: response.insurancePlanDescription.planCategory ?? '',
      planNumber: response.insurancePlanDescription.planNumber ?? '',
    });
    setIsLoading(false);
  }, []);

  const fetchClinicData = async () => {
    const clinics = await getClinicsForSelect();
    setClinics(clinics);
    return clinics;
  };

  const fetchEnaraPlans = useCallback(async () => {
    const memberships = await eligibilityService.listData<EnaraPlans>({
      entity: EligibilityAllEntities.EnaraPlans,
    });
    if (memberships) {
      setMemberships(memberships.entities);
      return memberships.entities;
    }

    return [];
  }, []);

  const fetchPayersPaginated = useCallback(
    async ({ filters }: { filters?: { name?: string; payerId?: string } }) => {
      const payersPaginated = await eligibilityService.getPayerPaginated({
        page: 1,
        ...(filters && { filters }),
      });

      if (payers) {
        setPayers(payersPaginated);
      }
      return payersPaginated;
    },
    [],
  );

  const fetchPayerOptions = useCallback(
    async ({ filters }: { filters?: { name?: string; payerId?: string } }) => {
      setIsLoadingPayerOptions(true);
      const payersPaginated = await fetchPayersPaginated({ filters });

      const options = payersPaginated.map((payer) => ({
        label: `${payer.payerId} | ${payer.name}`,
        value: `${payer.payerId}`,
      }));

      setPayerOptions(options);
      setIsLoadingPayerOptions(false);
    },
    [],
  );

  const fetchRelatedTasks = useCallback(async (insuranceId: string, memberships: EnaraPlans[]) => {
    const tasks = await eligibilityService.listData<Task>({
      entity: EligibilityAllEntities.Leads,
      filters: { joinFields: { insurancePlanId: insuranceId } },
    });

    if (tasks) {
      const { entities } = tasks;

      const relatedTasks = entities.map((task) => {
        const enaraPlanId = task.leadCheck.slice(-1)?.[0].enaraPlan;

        const membership = memberships.find((m) => m.id === enaraPlanId)?.name || 'Unknown';

        return {
          id: task.id,
          memberId: Number(task.memberId),
          firstName: task.firstName,
          lastName: task.lastName,
          membership,
          relatedClinicId: task.clinicId,
        };
      });

      setRelatedTasks(relatedTasks);
    }
  }, []);

  // TODO: This needs to be removed from here, It would be a function that response all that we need
  // to set fields with this value returned
  useEffect(() => {
    (async () => {
      const clinics = await fetchClinicData();
      const memberships = await fetchEnaraPlans();

      if (insuranceId) {
        fetchInsurancePlan(insuranceId, clinics);
        fetchRelatedTasks(insuranceId, memberships);
      }

      fetchPayersPaginated({});
    })();
  }, [insuranceId, payerId, fetchInsurancePlan, fetchEnaraPlans, fetchPayersPaginated]);

  useEffect(() => {
    // When a payerId comes into path (when an insurance plan is created from "all payers" tab) or comes in query params it needs to pull
    // paginated payers when mounting the component
    (async () => {
      if (!payers && (fields.payerId || payerId)) {
        const payer = (
          await fetchPayersPaginated({ filters: { payerId: fields.payerId ?? payerId } })
        )[0];

        if (payer) {
          const updatedFields = {
            payerId: payer.payerId,
            notes: payer.notes,
            membersCallNumber: payer.memberCallNumber,
            providerCallNumber: payer.providerCallNumber,
            payer,
          };

          setFields((prev) => ({ ...prev, ...updatedFields }));
        }
      }
    })();
  }, [payers, payerId, fields.payerId]);

  useEffect(() => {
    // To make more faster first payers load
    if (!fields.clinicId) {
      fetchPayerOptions({});
    }
  }, [fields.clinicId]);

  useEffect(() => {
    if (relatedTasks.length) {
      const relatedTasksByClinic = relatedTasks.filter(
        (task) => task.relatedClinicId === relatedClinic?.clinicId,
      );

      setRelatedTasksByClinic(relatedTasksByClinic);
    }
  }, [relatedClinic, relatedTasks]);

  // TODO: This function is similar in many parts of the code, we can centralize it in a function
  const setFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;

    validateField({
      name: e.target.name,
      onError: setErrors,
      removeError: !!e.target.value,
    });

    setFields((prev) => ({ ...prev, [name]: value }));
  };

  const setPlanCodeValue = (index: number, e: SelectChangeEvent<string>) => {
    const {
      target: { name, value, checked, type },
    } = e as ChangeEvent<HTMLInputElement>;

    const nameWithoutIndex = name.slice(0, -1);

    setFields((prev) => ({
      ...prev,
      planCodeDetail: prev.planCodeDetail.map((planCode, key) => {
        if (index === key) {
          return {
            ...planCode,
            [nameWithoutIndex]: type === 'checkbox' ? !!checked : value,
          };
        }
        return planCode;
      }),
    }));
  };

  const onChangePayerSelection = (
    e: SyntheticEvent<Element, Event>,
    value: string | number | undefined,
    option: AutocompleteOption | null,
  ) => {
    if (!option) {
      if (value) {
        fetchPayerOptions({
          filters: { payerId: `${value}`, name: `${value}` },
        });
        return;
      } else {
        setFields({
          ...fields,
          payerId: '',
          payer: {
            ...fields.payer!,
            payerId: '',
            name: '',
          },
        });
        return;
      }
    }

    if (option.notFound) {
      redirect(`/eligibility/payers/create?payerId=${option.value}`);
    }

    const matchingPayer = payers.find((payer) => payer.payerId === option?.value);

    if (matchingPayer) {
      setFields({
        ...fields,
        payer: {
          ...fields.payer!,
          name: `${matchingPayer.name}`,
          payerId: `${matchingPayer.payerId}`,
        },
        payerId: `${matchingPayer.payerId}`,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const allInputTextFields = Object.values(e.target).filter((input) => input.type === 'text');

    const allFieldsAreValid = allInputTextFields
      .map((input) => input.checkValidity())
      .every((field) => field);

    e.preventDefault();

    if (!allFieldsAreValid) {
      allInputTextFields.forEach((input) => {
        !input.checkValidity() && validateField({ name: input.name, onError: setErrors });
      });
      return;
    }

    setSavingChanges(true);

    const { clinicId, planCategory, ...restFields } = fields;

    const planCategoryExists = planCategory !== '';

    let response: InsurancePlanResponse | null = null;

    if (isUpdateView) {
      response = await eligibilityService.editDetails<InsurancePlan>({
        id: insuranceId,
        fields: {
          clinicId: Number(clinicId),
          ...(planCategoryExists && { planCategory }),
          ...restFields,
        },
        resource: EligibilityTab.InsurancePlans,
      });
    } else {
      response = await eligibilityService.createRegister<InsurancePlan>({
        fields: {
          clinicId: Number(clinicId),
          planCategory,
          ...restFields,
        },
        resource: EligibilityTab.InsurancePlans,
      });
    }

    MixpanelService.track((insuranceId ? 'Update Lead' : 'Update') + ' Insurance', {
      Fields: fields,
      isCreatedFrom: payerId ? 'Payers' : 'Insurance Plans',
    });

    let message: string = '';

    if (!response?.success) {
      message = response?.message || 'There was an error trying to save your changes';

      setResultMessage(message);
    } else {
      message = `Insurance plan was successfully ${
        isUpdateView ? 'updated' : 'created. Redirecting...'
      }`;

      setResultMessage(message);

      if (isUpdateView) {
        await fetchInsurancePlan(insuranceId, clinics);
      } else {
        setTimeout(() => {
          if (response?.insurancePlan?.id) {
            redirect(`/eligibility/insurance-plans/${response.insurancePlan.id}/show`);
          }
        }, SNACK_BAR_MILLISECONDS_DURATION);
      }
    }

    setSavingChanges(false);
  };

  const getPlanCategoryOptions = (isUpdateView: boolean): SelectOption[] => {
    const planCategoryOptions = Object.values(InsurancePlanCategory).map((ipc) => ({
      label: PLAN_CATEGORY_OPTIONS[ipc],
      value: ipc,
    }));
    const planCategoryWasSet = fields.planCategory !== '';
    if (isUpdateView) {
      return [
        ...(!planCategoryWasSet ? [{ value: '', label: 'No plan category' }] : []),
        ...planCategoryOptions,
      ];
    }

    return planCategoryOptions;
  };
  const getRelatedClinic = (clinicId: string) => {
    if (!clinicId) {
      setRelatedClinic(undefined);
      setFields((prev) => ({
        ...prev,
        membership: '',
        networkStatus: '',
        telehealthCoverage: TelehealthCoverage.No,
        totalDeductible: 0,
        copay: 0,
        coinsurance: 0,
        planCodeDetail: INSURANCE_PLAN_CODES.map((item) => ({
          id: item.id,
          codeName: item.codeName,
          codeDescription: '',
          coverageStatus: '',
          telehealthCoverage: false,
        })),
        clinicId: '',
      }));
      return;
    }

    const relatedClinic = relatedClinics.find((c) => c.clinicId === Number(clinicId));

    if (relatedClinic) {
      setFields((prev) => ({
        ...prev,
        membership: relatedClinic.enaraPlans.name,
        networkStatus: relatedClinic.networkStatus,
        telehealthCoverage: relatedClinic.telehealthCoverage
          ? TelehealthCoverage.Yes
          : TelehealthCoverage.No,
        totalDeductible: relatedClinic.totalDeductible,
        copay: relatedClinic.copay,
        coinsurance: relatedClinic.coinsurance,
        planCodeDetail: relatedClinic.planCodeDetail,
        clinicId: `${relatedClinic.clinicId}`,
      }));

      setRelatedClinic(relatedClinic);
    }
  };

  if (isLoading) {
    return <Loader backButton={{ basePath: INSURANCE_BASE_PATH }} />;
  }

  return (
    <Box my={4}>
      <Title
        title={`Eligibility: ${
          isUpdateView ? `Edit Insurance Plan #${insuranceId}` : 'Create Insurance Plan'
        }`}
      />
      <SimpleForm
        toolbar={false}
        noValidate
        onSubmit={handleSubmit}
        onChange={(e: any) =>
          validateField({
            name: e.target.name,
            onError: setErrors,
            removeError: e.target.checkValidity(),
          })
        }>
        <Grid container justifyContent='space-between' alignItems='center' display='flex'>
          <Grid item mb={2} xs={12} display='flex' justifyContent='space-between'>
            <BackButton basePath={'/eligibility/insurance-plans'} />
            <Button
              type='submit'
              startIcon={
                savingChanges ? (
                  <CircularProgress style={{ color: '#ffffff' }} size={20} />
                ) : (
                  <Save />
                )
              }
              variant='contained'
              style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
              size='medium'
              disabled={savingChanges}>
              {<>{isUpdateView ? 'Update' : 'Save'} </>}
            </Button>
          </Grid>
        </Grid>

        <EntitySection
          title=''
          children={
            <>
              {isUpdateView && (
                <Grid item xs={12} md={12}>
                  <Typography>
                    Insurance Plan: {fields.planId} {fields.planName}
                  </Typography>
                </Grid>
              )}
              {isUpdateView && (
                <>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='related-clinic'>
                        Related clinic
                      </InputLabel>
                      <Select
                        label='Related Clinics'
                        variant='filled'
                        value={relatedClinic?.clinicId}
                        size='medium'
                        displayEmpty
                        inputProps={{
                          onChange: (e: ChangeEvent<HTMLInputElement>) =>
                            getRelatedClinic(e.target.value),
                        }}>
                        {[
                          <MenuItem key={`new-related-clinic`} value={undefined}>
                            Add a new related clinic
                          </MenuItem>,
                          ...relatedClinics.map(({ clinicId, clinic, createdAt }) => (
                            <MenuItem key={`${clinicId}-${createdAt}`} value={clinicId}>
                              {clinic.name}
                            </MenuItem>
                          )),
                        ]}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={0} md={9} />
                </>
              )}

              <Grid item xs={5} md={2}>
                <FormControl fullWidth variant='filled'>
                  <InputLabel shrink id='plan-category'>
                    Plan Category
                  </InputLabel>
                  <Select
                    name='planCategory'
                    id='plan-category'
                    variant='filled'
                    value={fields.planCategory}
                    label='Plan category'
                    size='medium'
                    required={!isUpdateView}
                    error={errors.planCategory}
                    disabled={isUpdateView && !!fields.planCategory}
                    displayEmpty
                    inputProps={{
                      onChange: setFieldValue,
                    }}
                    fullWidth>
                    {getPlanCategoryOptions(isUpdateView).map(({ label, value }) => (
                      <MenuItem value={value} key={`${label}-${value}`}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      color: StyledTheme.redDark,
                    }}>
                    {errors.planCategory ? 'Plan Category is required' : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={5} md={2}>
                <FormControl fullWidth>
                  <InputLabel id='plan-type-label'>Plan Type</InputLabel>
                  <Select
                    name='planType'
                    label='Plan Type'
                    variant='filled'
                    size='medium'
                    value={fields.planType}
                    required
                    disabled={isUpdateView && !!fields.planType && !fields.planCategory}
                    error={errors.planType}
                    inputProps={{
                      onChange: setFieldValue,
                    }}>
                    {!fields?.planCategory ? (
                      <MenuItem value={''}>Select a plan category first</MenuItem>
                    ) : (
                      fields?.planCategory &&
                      PLAN_CATEGORY_PLAN_TYPE_MAPPING[
                        fields?.planCategory as InsurancePlanCategory
                      ].map((planType: InsurancePlanType) => (
                        <MenuItem key={planType} value={planType}>
                          {planType}
                        </MenuItem>
                      ))
                    )}
                  </Select>{' '}
                  <FormHelperText
                    style={{
                      color: StyledTheme.redDark,
                    }}>
                    {errors.planType ? 'Plan type is required' : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={5} md={2}>
                <FormControl fullWidth>
                  <InputLabel id='clinic-label'>Clinic</InputLabel>
                  <Select
                    name='clinicId'
                    label='Clinic'
                    variant='filled'
                    value={fields.clinicId}
                    size='medium'
                    required
                    disabled={!!fields.clinicId && !!relatedClinic}
                    error={errors.clinicId}
                    inputProps={{
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        validateField({
                          name: e.target.name,
                          onError: setErrors,
                          removeError: !!e.target.value,
                        });

                        setFields({
                          ...fields,
                          clinicId: e.target.value,
                          ...(!isUpdateView && {
                            payerId: '',
                            payer: {
                              ...fields.payer!,
                              payerId: '',
                              name: '',
                            },
                          }),
                        });
                      },
                    }}
                    placeholder='Clinic'>
                    {clinics?.map(({ id, name }) => (
                      <MenuItem key={`${id}-${name}`} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      color: StyledTheme.redDark,
                    }}>
                    {errors.clinicId ? 'Clinic is required' : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={5} md={2}>
                <FormControl fullWidth>
                  <InputLabel id='insurance-status-label'>Insurance Status</InputLabel>
                  <Select
                    name='insurancePlanStatus'
                    label='Insurance Status'
                    variant='filled'
                    value={fields.insurancePlanStatus}
                    size='medium'
                    required
                    error={errors.insurancePlanStatus}
                    inputProps={{
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFields((prev) => ({
                          ...prev,
                          insurancePlanClinicStatus: e.target.value as InsurancePlanStatus,
                        }));
                        setFieldValue(e);
                      },
                    }}>
                    {toSelectOptions(Object.values(InsurancePlanStatus))?.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      color: StyledTheme.redDark,
                    }}>
                    {errors.insurancePlanStatus ? 'Insurance plan status is required' : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={5} md={2}>
                <FormControl fullWidth>
                  <InputLabel id='membership-label'>Membership</InputLabel>
                  <Select
                    name='membership'
                    label='Membership'
                    variant='filled'
                    required
                    value={fields.membership}
                    error={errors.membership}
                    inputProps={{
                      onChange: setFieldValue,
                    }}
                    size='medium'
                    placeholder='Membership'>
                    {memberships?.map(({ name, id }) => (
                      <MenuItem key={`${id}-${name}`} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      color: StyledTheme.redDark,
                    }}>
                    {errors.membership ? 'Membership is required' : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  variant='filled'
                  label='Payer Name'
                  name='payerName'
                  value={fields.payer?.name ?? searchParams?.payerName ?? ''}
                  size='medium'
                  fullWidth
                  focused={!!fields.payer?.name}
                  sx={{
                    '& .MuiFormLabel-colorPrimary': { color: '#929292' },
                  }}
                  InputProps={{
                    disabled: true,
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item xs={5} md={2}>
                <AutocompleteField
                  initialValue={
                    payerId
                      ? {
                          label: payerId,
                          value: payerId,
                          selected: true,
                        }
                      : null
                  }
                  disabled={isUpdateView || !!payerId || !fields.clinicId}
                  getOptionLabel={(op) => (op.selected ? op.value.toString() : op.label)}
                  isLoading={isLoadingPayerOptions}
                  inputProps={{
                    name: 'payerId',
                    label: 'Payer ID',
                    value: fields.payerId,
                    required: true,
                    error: { isError: !!errors.payerId, helperText: 'Payer ID is required' },
                  }}
                  options={payerOptions}
                  onChange={onChangePayerSelection}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  variant='filled'
                  label='Group Name'
                  name='planName'
                  disabled={!fields.payerId}
                  value={fields.planName}
                  onChange={setFieldValue}
                  size='medium'
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  variant='filled'
                  name='planId'
                  value={fields.planId}
                  label='Group ID'
                  disabled={!fields.payerId}
                  onChange={setFieldValue}
                  size='medium'
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  variant='filled'
                  name='planNumber'
                  value={fields.planNumber}
                  label='Plan Number'
                  disabled={!fields.payerId}
                  size='medium'
                  onChange={setFieldValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <FormControl fullWidth>
                  <InputLabel id='network-status-label'>Network Status</InputLabel>
                  <Select
                    name='networkStatus'
                    label='Network Status'
                    variant='filled'
                    value={fields.networkStatus}
                    size='medium'
                    inputProps={{
                      onChange: setFieldValue,
                    }}>
                    {NETWORK_STATUS_OPTIONS?.map((networkStatus) => (
                      <MenuItem key={networkStatus} value={networkStatus}>
                        {networkStatus}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  name='membersCallNumber'
                  variant='filled'
                  label='Members Call #'
                  size='medium'
                  value={fields?.payer?.memberCallNumber || ''}
                  onChange={setFieldValue}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  name='providerCallNumber'
                  variant='filled'
                  label='Providers Call #'
                  onChange={setFieldValue}
                  size='medium'
                  value={fields?.payer?.providerCallNumber || ''}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={3} md={4}>
                <TextInput
                  variant='filled'
                  name='notes'
                  label='Notes'
                  value={fields.notes}
                  size='medium'
                  onChange={setFieldValue}
                  fullWidth
                  disabled={!!payerId}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  variant='filled'
                  name='totalDeductible'
                  value={fields.totalDeductible}
                  label='Total Deductible'
                  size='medium'
                  onChange={setFieldValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  variant='filled'
                  name='copay'
                  value={fields.copay}
                  label='Copay'
                  size='medium'
                  onChange={setFieldValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextInput
                  variant='filled'
                  name='coinsurance'
                  value={fields.coinsurance}
                  label='Coinsurance'
                  size='medium'
                  onChange={setFieldValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <FormControl fullWidth variant='filled'>
                  <InputLabel id='telehealth-coverage'>Telehealth Coverage</InputLabel>
                  <Select
                    id='telehealth-coverage'
                    variant='filled'
                    name='telehealthCoverage'
                    value={fields.telehealthCoverage}
                    label='Telehealth Coverage'
                    size='medium'
                    inputProps={{
                      onChange: setFieldValue,
                    }}
                    fullWidth>
                    <MenuItem value={TelehealthCoverage.Yes}>Yes</MenuItem>
                    <MenuItem value={TelehealthCoverage.No}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          }
        />

        <EntitySection
          title=''
          children={
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Telehealth Coverage</TableCell>
                    <TableCell>Coverage Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {INSURANCE_PLAN_CODES.map((item, index) => (
                    <TableRow key={`insurance-plan-code-${index}`}>
                      <TableCell width={200}>
                        <TextInput
                          variant='filled'
                          name={`coverageName${index}`}
                          size='medium'
                          value={fields?.planCodeDetail[index]?.codeName}
                          disabled
                          label='Code'
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setPlanCodeValue(index, e)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextInput
                          variant='filled'
                          name={`codeDescription${index}`}
                          size='medium'
                          value={fields?.planCodeDetail[index]?.codeDescription}
                          disabled
                          label='Description'
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setPlanCodeValue(index, e)
                          }
                          fullWidth
                        />
                      </TableCell>{' '}
                      <TableCell width={100} align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={`telehealthCoverage${index}`}
                              checked={!!fields?.planCodeDetail[index]?.telehealthCoverage}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setPlanCodeValue(index, e)
                              }
                              style={{ color: StyledTheme.tealDark }}
                            />
                          }
                          label=''
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id='coverage-status-label'>Coverage Status</InputLabel>
                          <Select
                            name={`coverageStatus${index}`}
                            label='Coverage Status'
                            variant='filled'
                            value={fields?.planCodeDetail[index]?.coverageStatus}
                            onChange={(e: SelectChangeEvent<string>) => {
                              validateField({
                                name: e.target.name,
                                onError: setErrors,
                                removeError: !!e.target.value,
                              });
                              setPlanCodeValue(index, e);
                            }}
                            size='medium'
                            fullWidth>
                            {STATUS_CODES_OPTIONS?.map((statusCode) => (
                              <MenuItem key={statusCode} value={statusCode}>
                                {statusCode}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          }
        />

        {insuranceId && Object.keys(fields.audit).length && (
          <EntitySection
            title='Audit Information'
            children={<AuditInformation entity='payers' audit={fields.audit} />}
          />
        )}

        {relatedTasks.length > 0 && (
          <EntitySection
            title='Related Tasks'
            children={<RelatedTasks tasks={relatedTasksByClinic} />}
          />
        )}
      </SimpleForm>

      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!resultMessage}
          autoHideDuration={SNACK_BAR_MILLISECONDS_DURATION}
          onClose={() => {
            setResultMessage(null);
            setSavingChanges(false);
          }}
          message={resultMessage}
        />
      </Portal>
    </Box>
  );
};
