import axios from 'axios';
import { IClinic } from '../interfaces/clinic';
import { IRole } from '../interfaces/common';
import { IResponseLeadOwners, IResponseUser } from '../interfaces/provider';
import { AuthService } from './auth';

export const CommonService = {
  async getRoles(): Promise<IRole[]> {
    const authData = AuthService.getAuth();
    const endpoint: string = '/roles/';
    const url: string = `${process.env.REACT_APP_PUBLIC_AUTH_BASE_URL}/v1${endpoint}${authData?.auth_token}`;
    const response = await axios.get<IRole[]>(url);
    return response?.data;
  },
  async getClinics(): Promise<IClinic[]> {
    const authData = AuthService.getAuth();
    const endpoint: string = '/clinics';
    const url: string = `${process.env.REACT_APP_CLINICS_API_BASE_URL}${endpoint}`;
    const response = await axios.get<IClinic[]>(url, {
      headers: {
        Authorization: `Bearer ${authData?.auth_token}`,
      },
    });
    return response?.data;
  },

  async getLeadOwners(): Promise<IResponseLeadOwners | null> {
    const authData = AuthService.getAuth();
    const url: string = `${process.env.REACT_APP_PUBLIC_AUTH_BASE_URL}/v2/providers?lead_owner=true`;

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${authData?.auth_token}` },
      });

      return response?.data;
    } catch (error) {
      console.error(error);
    }
    return null;
  },

  async getUserByRole(roleId: number): Promise<IResponseUser | null> {
    const authData = AuthService.getAuth();
    const url: string = `${process.env.REACT_APP_PUBLIC_AUTH_BASE_URL}/v2/users/roles/${roleId}`;

    try {
      const response = await axios.get<IResponseUser>(url, {
        headers: { Authorization: `Token ${authData?.auth_token}` },
      });

      return response?.data;
    } catch (error) {
      console.error(error);
    }
    return null;
  },
};
