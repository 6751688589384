import { ArrowBack } from '@mui/icons-material';
import { Button, useRedirect } from 'react-admin';
import { StyledTheme } from '../../styles/styleTheme';

type Props = {
  basePath: string;
  includeMargin?: boolean;
};

export const BackButton = (backButtonProps: Props) => {
  let { basePath, includeMargin } = backButtonProps;
  const redirect = useRedirect();

  if (Object.keys(backButtonProps).includes('includeMargin')) {
    includeMargin = true;
  }

  return (
    <Button
      startIcon={<ArrowBack />}
      variant='contained'
      style={{
        background: StyledTheme.tealDark,
        color: StyledTheme.white,
        ...(includeMargin && { marginTop: '24px', marginBottom: '24px' }),
      }}
      onClick={() => redirect(basePath)}>
      <>{'Return'}</>
    </Button>
  );
};
