import CloudUpload from '@material-ui/icons/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
} from '@mui/material';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';
import {
  Button,
  Datagrid,
  FunctionField,
  Record,
  ShowButton,
  SimpleForm,
  TextField,
  Title,
  TopToolbar,
} from 'react-admin';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SnackBarProps } from '../../interfaces/common';
import { NotEngagedReferrals, Referral } from '../../interfaces/referral';
import MixpanelService from '../../services/mixpanel';
import referralService from '../../services/referral';
import { StyledTheme } from '../../styles/styleTheme';
import { formatDateStringToMMDDYYYYAndHour } from '../../util/dateFormat';
import { CustomSnackbar } from '../common/CustomSnackbar';
import './referral.css';

const UploadReferralButtonAction: FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <MuiButton
      size='small'
      style={{ color: StyledTheme.tealDark }}
      startIcon={<CloudUpload />}
      onClick={() => {
        MixpanelService.trackButtonClicked({
          Field: 'Upload Referral',
          source: pathname,
        });
        history.push('/referrals/upload');
      }}>
      Upload Referral
    </MuiButton>
  );
};

export const ReferralList: FC = () => {
  const [notEngagedReferrals, setNotEngagedReferrals] = useState<NotEngagedReferrals | null>(null);
  const [filteredReferrals, setFilteredReferrals] = useState<NotEngagedReferrals>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackBarProps>();
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      const tmpReferrals = await referralService.fetchNotEngagedReferrals();
      if (tmpReferrals) {
        setNotEngagedReferrals(tmpReferrals);
        setFilteredReferrals(tmpReferrals);
      }

      setLoaded(true);
    })();
  }, []);

  const handleRaiseSearch = async () => {
    if (searchText === '') {
      return;
    }

    setIsSearching(true);
    if (!notEngagedReferrals) {
      setIsSearching(false);
      return;
    }

    const referralKeys = Object.keys(notEngagedReferrals);

    for (const key of referralKeys) {
      const ref: Referral = notEngagedReferrals[key];
      if (ref.leadsquaredId === searchText || ref.leadsquaredId?.includes(searchText)) {
        setFilteredReferrals({ [key]: ref });
        setIsSearching(false);
        return;
      }
    }

    setFilteredReferrals(notEngagedReferrals);
    setIsSearching(false);
    setSnackbarProps({
      open: true,
      autoHideDuration: 1500,
      message: 'No search found. Check if the leadsquared id is correct',
      severity: 'warning',
      horizontal: 'center',
      vertical: 'top',
      onClose: () => {
        setSnackbarProps({ open: false });
      },
    });
  };

  const handleEnterKey = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() !== 'enter') {
      return;
    }

    if (notEngagedReferrals && searchText === '') {
      setFilteredReferrals(notEngagedReferrals);
      return;
    }

    handleRaiseSearch();
  };

  const renderTopBar = () => {
    return (
      <Box width='100%'>
        <TopToolbar>
          <div className='referral-top-bar'>
            <div className='referral-search-container'>
              <MuiTextField
                label='Leadsquared ID'
                value={searchText}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                onKeyDown={handleEnterKey}
                variant='filled'
                size='small'
                fullWidth
                style={{
                  backgroundColor: StyledTheme.grayDisabled,
                  color: StyledTheme.tealDark,
                }}
                autoComplete='off'
              />

              <Button
                label='Search'
                size='small'
                fullWidth
                style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
                startIcon={
                  isSearching ? (
                    <CircularProgress style={{ color: '#ffffff' }} size={20} />
                  ) : (
                    <SearchIcon />
                  )
                }
                disabled={isSearching}
                onClick={handleRaiseSearch}
              />
            </div>
            <UploadReferralButtonAction />
          </div>
        </TopToolbar>
      </Box>
    );
  };

  return (
    <>
      {renderTopBar()}

      <Card>
        <Title title='Referrals' />
        <CardContent>
          <Datagrid
            basePath='referrals'
            loaded={loaded}
            data={filteredReferrals}
            ids={filteredReferrals ? Object.keys(filteredReferrals) : []}
            currentSort={{ field: 'id', order: 'DESC' }}>
            <FunctionField
              source='id'
              label='Referral Id'
              render={(record: Record | undefined) => {
                return (
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`${process.env.REACT_APP_JOIN_BASE_URL}/referrals/?rid=${record?.id}`}>
                    {record?.id}
                  </a>
                );
              }}
            />

            <TextField source='referralDate' label='Referral Date' />
            <TextField source='salesforceId' label='Salesforce ID' />
            <TextField source='leadsquaredId' label='Leadsquared Id' />
            <TextField source='memberFullName' label='Member Name' />
            <TextField source='referringFullName' label='Doctor Name' />
            <FunctionField
              source='smsSentAt'
              label='Sms Sent At'
              render={(record: Record | undefined) =>
                `${formatDateStringToMMDDYYYYAndHour(record?.smsSentAt) || ''}`
              }
            />
            <TextField source='phone' label='Phone Number' />
            <TextField source='clinic' label='Clinic' />
            <FunctionField
              source='resolvedBy'
              label='Resolved By'
              render={(record: Record | undefined) => {
                return record?.resolvedBy || 'Unknown';
              }}
            />
            <ShowButton
              onClick={() => {
                MixpanelService.trackButtonClicked({
                  Field: 'Show',
                  source: pathname,
                  Target: 'Show Referral',
                  Type: 'link',
                });
              }}
            />
          </Datagrid>
        </CardContent>
      </Card>
      <Box>
        <CustomSnackbar
          message={snackbarProps?.message}
          vertical={snackbarProps?.vertical}
          horizontal={snackbarProps?.horizontal}
          onClose={snackbarProps?.onClose}
          severity={snackbarProps?.severity}
          open={snackbarProps?.open}
          autoHideDuration={snackbarProps?.autoHideDuration}
        />
      </Box>
    </>
  );
};

export const ReferralShow: FC = () => {
  const { referralId } = useParams<{ referralId: string }>();

  const [loading, setLoading] = useState<boolean>(true);
  const [record, setRecord] = useState<Record | null>(null);

  useEffect(() => {
    (async () => {
      const data = await referralService.fetchReferral(referralId);

      if (data) {
        const { referral } = data;
        setRecord({ id: referral.clinicReferralId, ...referral });
      }

      setLoading(false);
    })();
  }, [referralId]);

  return (
    <Card>
      <Title title='Referrals' />
      {loading ? (
        <SimpleForm toolbar={false}>
          <CircularProgress />
        </SimpleForm>
      ) : record ? (
        <SimpleForm toolbar={false}>
          <TextField record={record} source='clinicCode' label='Clinic' />
          <TextField record={record} source='clinicPatientId' label='Clinic Patient ID' />
          <TextField record={record} source='clinicReferralId' label='Referral ID' />
          <TextField record={record} source='referralDate' label='Referral Date' />
          <TextField record={record} source='member.address' label='Member Address' />
          <TextField record={record} source='member.dateOfBirth' label='Date Of Birth' />
          <TextField record={record} source='member.fullName' label='Member Name' />
          <TextField record={record} source='member.gender' label='Member Gender' />
          <TextField record={record} source='member.salesforceId' label='Salesforce ID' />
          <TextField
            record={record}
            source='member.insurance.carrier'
            label='Member Insurance Carrier'
          />
          <TextField
            record={record}
            source='member.insurance.groupNumber'
            label='Member Insurance Group Number'
          />
          <TextField
            record={record}
            source='member.insurance.policyNumber'
            label='Member Insurance Policy Number'
          />
          <TextField label='Phone numbers' />
          <ul>
            {record.member.telephone.map((p: { type: string; number: string }) => (
              <li key={p.type}>
                {p.type} - {p.number}
              </li>
            ))}
          </ul>
          <TextField record={record} source='referralDate' label='Referral Date' />
          <TextField record={record} source='referringProvider.fullName' label='Doctor Name' />
        </SimpleForm>
      ) : (
        <SimpleForm toolbar={false}>
          <div style={{ width: '100%' }}>Could not display referral information.</div>
        </SimpleForm>
      )}
    </Card>
  );
};
