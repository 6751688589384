import { Grid, TextField } from '@mui/material';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { AutocompleteField, AutocompleteOption } from './Autocomplete';
export type SearchFilters<T extends string> = {
  [key in T]: {
    searchText: string;
  };
};

const ActionBar = ({
  onRaiseSearch,
  searchInputFilters,
}: {
  onRaiseSearch: (searchFilters: SearchFilters<string>) => Promise<void>;
  searchInputFilters: {
    filter: string;
    placeholder: string;
    label: string;
    fetchOptions?: (value?: string) => Promise<AutocompleteOption[]>;
    options?: AutocompleteOption[];
  }[];
}) => {
  const [filtersBy, setFiltersBy] = useState<SearchFilters<string>>(
    (() => {
      let filters = {} as SearchFilters<string>;
      searchInputFilters.forEach(({ filter }) => {
        filters[filter] = {
          searchText: '',
        };
      });
      return filters;
    })(),
  );

  const debounce = useDebounce(onRaiseSearch);

  const _searchFilter = (
    e: ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event>,
    key: string,
    value?: string | number,
  ) => {
    const text = value ? value.toString() : (e as ChangeEvent<HTMLInputElement>).target.value;
    setFiltersBy((prev) => {
      const newFilter = {
        ...prev,
        [key]: {
          searchText: text,
        },
      };
      debounce(newFilter);
      return newFilter;
    });
  };

  return (
    <Grid container spacing={1} mt={2} mb={2}>
      {searchInputFilters.map(({ filter, label, placeholder, options }) => {
        if (options) {
          return (
            <Grid item xs={12} md={2} key={`${label}-${filter}`}>
              <AutocompleteField
                options={options}
                size='small'
                onChange={(e: SyntheticEvent<Element, Event>, value: string | number | undefined) =>
                  _searchFilter(e, filter, value)
                }
                inputProps={{
                  name: filter,
                  label,
                }}
                key={`filter-${filter}`}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12} md={2} key={`${label}-${filter}`}>
              <TextField
                key={`filter-${filter}`}
                label={label}
                fullWidth
                value={filtersBy[filter].searchText}
                onChange={(e: ChangeEvent<HTMLInputElement>) => _searchFilter(e, filter)}
                variant='filled'
                size='small'
                placeholder={placeholder}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default ActionBar;
