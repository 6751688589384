export const getLeadDetailsUrl = (crmId: string) =>
  `${process.env.REACT_APP_LEADSQUARED_BASE_URL}/LeadManagement/LeadDetails?LeadID=${crmId}`;

export const getInsurancePlanRedirectUrl = (
  planId: string | number,
  payerId: string,
  payerName: string,
  clinicId: string,
) =>
  `/eligibility/insurance-plans/create?planId=${planId}&payerId=${payerId}&payerName=${payerName}&clinicId=${clinicId}`;

export const getResourceUrlWithQueryParams = ({
  resourceUrl,
  queryParams,
}: {
  resourceUrl: string;
  queryParams: Record<string, string>;
}) => {
  const queryString = Object.keys(queryParams)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');

  const separator = resourceUrl.includes('?') ? '&' : '?';
  const urlWithParams = `${resourceUrl}${separator}${queryString}`;

  return urlWithParams;
};

export const getEnaraUrl = (path: string) => {
  if (!path) {
    return '';
  }

  return `${process.env.REACT_APP_ENARA_URL}${path}`;
};

export const getDrChronoPatientUrl = (patientId: number | string) => {
  if (!patientId) {
    return '';
  }

  return `https://practicegroup.drchrono.com/patient_demographics/${patientId}/important`;
};

export const getDrChronoAppointmentUrl = (appointmentId: number | string) => {
  if (!appointmentId) {
    return '';
  }

  return `https://practicegroup.drchrono.com/appointments/${appointmentId}`;
};

export const getAperoUrl = (path: string) => {
  if (!path) {
    return '';
  }

  return `${process.env.REACT_APP_APERO_BASE_URL}${path}`;
};
