import { Button } from '@mui/material';
import { Column, CustomTable } from '../../common/CustomTable';
import { TASK_BASE_PATH } from '../tasks/Tasks';

export type RelatedTask = {
  id: number;
  memberId: number;
  firstName: string;
  lastName: string;
  membership: string;
  relatedClinicId: number;
};

export const RelatedTasks = ({ tasks }: { tasks: RelatedTask[] }) => {
  const columns: Column[] = [
    { key: 'id', label: 'Task ID' },
    { key: 'memberId', label: 'Member ID', align: 'right' },
    { key: 'firstName', label: 'First Name', align: 'right' },
    { key: 'lastName', label: 'Last Name', align: 'right' },
    { key: 'membership', label: 'Membership', align: 'right' },
    { key: 'linkToTask', label: 'Actions', align: 'right' },
  ];

  const relatedTasksWithLinkDetail = tasks.map((task) => ({
    ...task,
    linkToTask: (
      <Button variant='outlined' href={`#${TASK_BASE_PATH}/${task.id}/show`} target='_blank'>
        View Task
      </Button>
    ),
  }));

  return (
    <CustomTable
      columns={columns}
      rows={relatedTasksWithLinkDetail}
      defaultText='No tasks related to the current clinic'
    />
  );
};
