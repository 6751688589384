import { Route } from 'react-router-dom';
import { BillingExporter } from '../components/billing/BillingExporter';
import { Claims } from '../components/claims/Claims';
import SingleClaim from '../components/claims/SingleClaim';
import { ACEImporter } from '../components/eligibility/ace/ACEImporter';
import { InsurancePlansCreateOrEdit } from '../components/eligibility/insurancePlans/InsurancePlans';
import { PayersCreate } from '../components/eligibility/payers/Payers/PayersCreate';
import { PayersUpdate } from '../components/eligibility/payers/Payers/PayersUpdate';
import { TaskShowUpdate } from '../components/eligibility/tasks/Tasks';
import { ReferralList, ReferralShow } from '../components/referrals/Referral';
import { ReferralsUploader } from '../components/referrals/ReferralsUploader';

export const customRoutes = [
  <Route exact path='/billing-export' component={BillingExporter} />,
  <Route exact path='/referrals' component={ReferralList} />,
  <Route exact path='/referrals/:referralId/show' component={ReferralShow} />,
  <Route exact path='/referrals/upload' component={ReferralsUploader} />,
  <Route
    exact
    path='/eligibility/insurance-plans/:insuranceId/show'
    component={InsurancePlansCreateOrEdit}
  />,
  <Route
    exact
    path='/eligibility/insurance-plans/payers/:payerId/create'
    component={InsurancePlansCreateOrEdit}
  />,
  <Route exact path='/eligibility/insurance-plans/create' component={InsurancePlansCreateOrEdit} />,
  <Route exact path='/eligibility/tasks/:leadId/show' component={TaskShowUpdate} />,
  <Route exact path='/eligibility/tasks/create' component={TaskShowUpdate} />,
  <Route exact path='/eligibility/payers/:payerId/show' component={PayersUpdate} />,
  <Route exact path='/eligibility/payers/create' component={PayersCreate} />,
  <Route exact path='/ace/import' component={ACEImporter} />,
  <Route exact path='/claims' component={Claims} />,
  <Route exact path='/claims/:claimId/show' component={SingleClaim} />,
];
