import { Box, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { EligibilityTab, EligibilityTabComponent } from './eligibility.types';
import MixpanelService from '../../services/mixpanel';
import { eligibilityTabs } from './eligibilityTabs';

const Eligibility = () => {
  const [currentTab, setCurrentTab] = useState<EligibilityTabComponent>(eligibilityTabs[0]);

  const location = useLocation();

  const redirect = useRedirect();

  const getCurrentTab = useCallback(
    (name: string) => eligibilityTabs.find((tab) => tab.name === name)!,
    []
  );

  useEffect(() => {
    const tab = location.pathname.split('/').slice(-1)[0] as EligibilityTab;

    if (!Object.values(EligibilityTab).includes(tab)) {
      redirect('/eligibility/insurance-plans');
    } else {
      setCurrentTab(getCurrentTab(tab));
    }
  }, [location, getCurrentTab, currentTab]);

  const handleChange = (_: React.SyntheticEvent, value: EligibilityTab) => {
    const selectedTab = getCurrentTab(value);

    MixpanelService.track('Tab Click', {
      Field: selectedTab.label,
      Type: 'link',
    });

    setCurrentTab(selectedTab);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={currentTab.name} onChange={handleChange}>
        {eligibilityTabs.map(({ label, name, href }, i) => (
          <Tab key={i} label={label} value={name} onClick={() => redirect(href)} />
        ))}
      </Tabs>

      {<currentTab.ListComponent />}
    </Box>
  );
};

export default Eligibility;
