import { useState } from 'react';
import { useRedirect } from 'react-admin';
import { FormAction } from '../../../../interfaces/common';
import { Payer, PayerResponse } from '../../../../interfaces/eligibility';
import eligibilityService from '../../../../services/eligibility';
import MixpanelService from '../../../../services/mixpanel';
import { EligibilityTab } from '../../eligibility.types';
import { PayerForm } from './PayerForm';
import { PayerFormFields } from './payer.types';

export const PayersCreate = () => {
  const [resultMessage, setResultMessage] = useState<string>('');
  const [fields, setFields] = useState<PayerFormFields>({
    name: '',
    payerId: '',
    memberCallNumber: '',
    providerCallNumber: '',
    notes: '',
    externalId: '',
    aperoPayerId: '',
    payerClinics: [],
  });
  const redirect = useRedirect();

  const handleChange = async (fields: PayerFormFields) =>
    setFields((prev) => ({ ...prev, ...fields }));

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const response: PayerResponse | null = await eligibilityService.createRegister<Payer>({
      fields,
      resource: EligibilityTab.Payers,
    });

    MixpanelService.track('Create Payer', {
      Fields: fields,
    });

    let defaultMessage = 'There was an error trying to save your changes';
    if (response) {
      const { success, payer } = response;

      if (!success || !payer) {
        let message = response?.message ?? defaultMessage;
        setResultMessage(message);
        return;
      }

      setResultMessage('Payer was successfully created');
      redirect(`/eligibility/payers/${payer?.id}/show`);
    } else {
      setResultMessage(defaultMessage);
    }
  };

  return (
    <PayerForm
      isLoaded={true}
      promptProps={{ message: resultMessage }}
      actionButtonProps={{ label: 'Save' }}
      title='Create Payer'
      onChange={handleChange}
      onSubmit={handleSubmit}
      action={FormAction.CREATE}
    />
  );
};
