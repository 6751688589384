import { CircularProgress } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import InfoIcon from '@material-ui/icons/Info';
import { Save } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {
  Alert,
  Box,
  Button,
  Chip,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  TextField as MuiTextField,
  Portal,
  Snackbar,
  TextField as TextInput,
  Tooltip,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { SimpleForm, Title, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import { IClinic } from '../../../interfaces/clinic';
import {
  EditableFields,
  EditableLeadFields,
  EligibilityGender,
  EligibilityRelationship,
  EnaraPlans,
  ExternalAPI,
  InsurancePlanPaginated,
  InsurancePlansPaginatedResponse,
  LeadInsuranceStatus,
  MembershipComputeByVersionPayload,
  NationalInsurance,
  POLICY_HOLDER_OPTIONS,
  PVerifyEligibility,
  Payer,
  ProgramType,
  TaskDetail,
  TaskResponse,
  TaskSource,
  TaskStatus,
} from '../../../interfaces/eligibility';
import { Provider, User } from '../../../interfaces/provider';
import { CommonService } from '../../../services/common';
import eligibilityService from '../../../services/eligibility';
import MixpanelService from '../../../services/mixpanel';
import { StyledTheme } from '../../../styles/styleTheme';
import { formatDateStringToMMDDYYYY } from '../../../util/dateFormat';
import { getButtonText, toSelectOptions } from '../../../util/eligibility';
import { getInsurancePlanRedirectUrl, getLeadDetailsUrl } from '../../../util/url';
import { isValidDateMMDDYYYY } from '../../../util/validations';
import { AutocompleteField, AutocompleteOption } from '../../common/Autocomplete';
import { BackButton } from '../../common/BackButton';
import { CustomModal } from '../../common/CustomModal/CustomModal';
import GridEmptyLine from '../../common/GridEmptyLine';
import { Loader } from '../../common/Loader';
import { AuditInformation } from '../AuditInformation';
import {
  EligibilityAllEntities,
  EligibilityTab,
  PlanNameOption,
  ViewMode,
} from '../eligibility.types';
import { validateField } from '../validation';
import { EntitySection } from './EntitySection';
import { RelatedInsurancePlan } from './RelatedInsurances';
import { ShowSuggestMembership } from './Sections';
import { InsuranceStatusResponse } from './pVerify/pVerify.types';
import { PVerifySummary } from './pVerify/pVerifySummary';

export const TASK_BASE_PATH = '/eligibility/tasks';

export const TaskShowUpdate = () => {
  const [viewMode, setViewMode] = useState<ViewMode>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [leadsOwners, setLeadsOwners] = useState<Provider[]>();
  const [taskOwners, setTaskOwners] = useState<User[]>();
  const [clinics, setClinics] = useState<IClinic[]>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [sectionLoading, setSectionLoading] = useState<boolean>(false);
  const [enaraPlans, setEnaraPlans] = useState<EnaraPlans[]>();
  const [payers, setPayers] = useState<Payer[]>([]);
  const [primaryPayerOptions, setPrimaryPayerOptions] = useState<AutocompleteOption[]>([]);
  const [secondaryPayerOptions, setSecondaryPayerOptions] = useState<AutocompleteOption[]>([]);
  const [isSecondaryPayerLoading, setIsSecondaryPayerLoading] = useState<boolean>(false);
  const [isPrimaryPayerLoading, setIsPrimaryPayerLoading] = useState<boolean>(false);
  const [isPlansOptionsLoading, setIsPlansOptionsLoading] = useState<boolean>(false);
  const [isSecondaryPlansOptionsLoading, setIsSecondaryPlansOptionsLoading] =
    useState<boolean>(false);
  const [insurancePlansOptions, setInsurancePlansOptions] = useState<AutocompleteOption[]>([]);
  const [secondaryInsurancePlansOptions, setSecondaryInsurancePlansOptions] = useState<
    AutocompleteOption[]
  >([]);
  const [insurancePlans, setInsurancePlans] = useState<InsurancePlanPaginated[]>([]);
  const [errors, setErrors] = useState<{ [fieldName: string]: boolean }>({});
  const [primaryPlanNameOptions, setPrimaryPlanNameOptions] = useState<PlanNameOption[]>([]);
  const [resultStatus, setStatusResult] = useState<{ message: string; withErrors: boolean } | null>(
    null,
  );
  const [membershipLoading, setMembershipLoading] = useState<boolean>(false);
  const [membershipResult, setMembershipResult] = useState<boolean>(false);
  const [membershipSuggested, setMembershipSuggested] = useState<React.ReactNode | null>(null);
  const [pVerifyResponse, setPVerifyResponse] = useState<InsuranceStatusResponse | null>(null);
  const [showRelatedInsurancePlan, setShowRelatedInsurancePlan] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(true);

  const [fields, setFields] = useState<EditableFields>({
    lead: {
      bmi: '',
      dateOfBirth: '',
      firstName: '',
      lastName: '',
      clinicId: '',
      crmId: '',
      status: TaskStatus.Pending,
      leadOwnerId: '',
      requiredDate: '',
      requestInsuranceId: '',
      nationalInsurance: NationalInsurance.No,
      secondaryNationalInsurance: NationalInsurance.No,
      primaryPolicyHolder: true,
      secondaryInsuranceMemberId: '',
      secondaryPolicyHolder: 'false',
      secondaryGroupId: '',
      secondaryGroupName: 'Unknown',
      secondaryPayerId: '',
      secondaryPayerName: 'Unknown',
      taskOwner: '',
      sourceRequest: TaskSource.Manual,
      partnerPatientId: '',
      memberId: '',
      insuranceCardFront: '',
    },
    leadCheck: {
      planId: '',
      planName: 'Unknown',
      enaraPlanId: '',
      activeCoverage: '',
      deductibleRemaining: '',
      outOfPocket: '',
      checkDate: '',
      programType: ProgramType.InClinic,
    },
    payer: {
      notes: '',
      name: 'Unknown',
      payerId: '',
    },
  });

  const redirect = useRedirect();

  const { leadId } = useParams<{ leadId: string }>();

  const fetchPayerOptions = async (
    optionsLoading: (value: React.SetStateAction<boolean>) => void,
    setOptions: (value: React.SetStateAction<AutocompleteOption[]>) => void,
    filters?: { name?: string; payerId?: string },
  ) => {
    optionsLoading(true);
    const payersPaginated = await eligibilityService.getPayerPaginated({
      ...(filters && { filters }),
      page: 1,
    });

    setPayers(payersPaginated);

    const options = payersPaginated.map((payer) => ({
      label: `${payer.payerId} | ${payer.name}`,
      value: `${payer.payerId}`,
    }));

    setOptions(options);
    optionsLoading(false);
  };

  const fetchInsurancePlans = async ({
    filters,
  }: {
    filters?: {
      groupId?: string;
      payerId?: string;
      clinicId?: string;
    };
  }): Promise<InsurancePlansPaginatedResponse> => {
    const insurancePlansPaginated = await eligibilityService.getInsurancePlansPaginated({
      page: 1,
      ...(filters && { filters }),
    });

    setInsurancePlans(insurancePlansPaginated.entities);
    return insurancePlansPaginated;
  };

  const getInsurancePlansOptions = async ({
    filters,
    optionsLoading,
    setOptions,
  }: {
    filters: {
      planId?: string;
      payerId?: string;
      clinicId?: string;
    };
    optionsLoading: (value: React.SetStateAction<boolean>) => void;
    setOptions: (value: React.SetStateAction<AutocompleteOption[]>) => void;
  }) => {
    const { planId, clinicId, payerId } = filters;

    optionsLoading(true);

    const insurancePlansPaginated = await fetchInsurancePlans({
      filters: {
        ...(planId && { groupId: planId }),
        ...(payerId && { payerId }),
        ...(clinicId && { clinicId }),
      },
    });

    const options = insurancePlansPaginated.entities.map(
      ({ planId, planName, payerName, payerId, id }) => ({
        id,
        label: `${planId} | ${
          !planName ? 'no-insurance-plan-name' : planName
        } | (${payerName} | ${payerId})`,
        value: `${planId}`,
      }),
    );

    setOptions(options);
    optionsLoading(false);
  };

  const fetchInsuranceByQueryParams = useCallback(
    async (filter: { payerId: string; clinicId: string }) => {
      const primaryPlanNameOptions = await eligibilityService.listInsurancesByQueryParams({
        clinicId: filter.clinicId,
        payerId: filter.payerId,
      });

      if (primaryPlanNameOptions) {
        setPrimaryPlanNameOptions(primaryPlanNameOptions);
      }
    },
    [],
  );

  const debounceInsurance = useDebounce<{ payerId: string; clinicId: string }>(
    fetchInsuranceByQueryParams,
  );

  useEffect(() => {
    (async () => {
      const mode = !leadId ? ViewMode.Create : ViewMode.Show;
      setViewMode(mode);

      CommonService.getClinics()
        .then((clinics: IClinic[]) => setClinics(clinics))
        .catch(console.error);

      eligibilityService
        .listData<EnaraPlans>({
          entity: EligibilityAllEntities.EnaraPlans,
        })
        .then((enaraPlans) => enaraPlans && setEnaraPlans(enaraPlans.entities))
        .catch(console.error);

      CommonService.getLeadOwners()
        .then((leadOwners) => leadOwners?.success && setLeadsOwners(leadOwners.providers))
        .catch(console.error);

      loadTaskOwners();

      if ([ViewMode.Update, ViewMode.Show].includes(mode)) {
        const leadResponse = await eligibilityService.getDetails<TaskDetail>({
          id: leadId,
          resource: EligibilityTab.Leads,
        });

        if (leadResponse) {
          const {
            lead: { leadCheck, ...rest },
            insurancePlans,
          } = leadResponse;

          setShowRelatedInsurancePlan(insurancePlans.hasOwnProperty('id'));

          if (insurancePlans?.insurancePlanDescription?.planId) {
            leadCheck.planId = insurancePlans?.insurancePlanDescription?.planId;
          }

          if (insurancePlans?.insurancePlanDescription?.planName) {
            leadCheck.planName = insurancePlans?.insurancePlanDescription?.planName;
          }

          if (leadCheck && leadCheck.insuranceStatusResponse) {
            setPVerifyResponse(leadCheck.insuranceStatusResponse);
          }

          const payerId = leadCheck?.payerId ?? insurancePlans?.payers?.payerId;
          const payerResponse = payerId
            ? await eligibilityService.getPayerPaginated({
                page: 1,
                filters: { payerId },
              })
            : null;

          const dob = rest.dateOfBirth?.toString() ?? '';
          const checkDate = leadCheck?.checkDate.toString();
          const requiredDate = rest.requiredDate.toString();
          const insurancePlanClinic = insurancePlans?.insurancePlanClinic?.[0];

          setFields({
            lead: {
              ...rest,
              clinicId: rest.clinicId.toString(),
              leadOwnerId: rest.leadOwnerId.toString(),
              requiredDate: formatDateStringToMMDDYYYY(requiredDate),
              bmi: rest.bmi?.toString() ?? '',
              dateOfBirth: formatDateStringToMMDDYYYY(dob),
              sourceRequest: rest.sourceRequest || TaskSource.Manual,
              primaryPolicyHolder: rest.primaryPolicyHolder,
            },
            leadCheck: {
              ...leadCheck,
              enaraPlanId: leadCheck?.enaraPlan?.toString(),
              deductibleRemaining: leadCheck?.deductibleRemaining.toString(),
              outOfPocket: leadCheck?.outOfPocket?.toString(),
              checkDate: formatDateStringToMMDDYYYY(checkDate),
              primaryInsuranceSubscriber: {
                isPrimary: !!rest.primaryPolicyHolder,
                ...(leadCheck?.primaryInsuranceSubscriber && {
                  address: leadCheck.primaryInsuranceSubscriber.address ?? null,
                  city: leadCheck.primaryInsuranceSubscriber.city ?? null,
                  state: leadCheck.primaryInsuranceSubscriber.state ?? null,
                  zipCode: leadCheck.primaryInsuranceSubscriber.zipCode ?? null,
                  firstName: leadCheck.primaryInsuranceSubscriber.firstName ?? null,
                  lastName: leadCheck.primaryInsuranceSubscriber.lastName ?? null,
                  dateOfBirth: leadCheck.primaryInsuranceSubscriber.dateOfBirth ?? null,
                  gender: leadCheck.primaryInsuranceSubscriber.gender ?? null,
                  relationship:
                    leadCheck.primaryInsuranceSubscriber.relationship?.toLocaleLowerCase() ?? null,
                }),
              },
            },
            payer: {
              notes: '',
              name: payerResponse?.[0]?.name ?? '',
              payerId,
            },
            insurancePlans: {
              id: insurancePlans?.id,
              insurancePlanDescription: {
                id: insurancePlans?.insurancePlanDescription?.id,
                planId: insurancePlans?.insurancePlanDescription?.planId,
                planName: insurancePlans?.insurancePlanDescription?.planName,
              },
              insurancePlanClinic: {
                id: insurancePlanClinic?.id,
                clinicId: insurancePlanClinic?.clinicId,
              },
            },
            audit: leadResponse.audit,
          });
        }
      }

      setIsLoaded(true);
    })();
  }, [leadId]);

  /** Plan name options for Primary Insurance */

  useEffect(() => {
    (async () => {
      if (fields.payer.payerId && fields.lead.clinicId) {
        debounceInsurance({
          payerId: fields.payer.payerId,
          clinicId: fields.lead.clinicId,
        });
      }
    })();
  }, [fields.lead.clinicId, fields.payer.payerId]);

  useEffect(() => {
    if (payers && fields.payer.payerId) {
      const payer = payers.find((p) => p.payerId === fields.payer.payerId);
      if (payer) {
        setFields((prev) => ({
          ...prev,
          payer: { payerId: `${payer.payerId}`, name: payer.name, notes: payer.notes ?? '' },
        }));
      }
    }
  }, [fields.payer.payerId, payers]);

  useEffect(() => {
    if (!fields.lead.clinicId) {
      fetchPayerOptions(setIsPrimaryPayerLoading, setPrimaryPayerOptions);
      fetchPayerOptions(setIsSecondaryPayerLoading, setSecondaryPayerOptions);
    }
  }, [fields.lead.clinicId]);

  useEffect(() => {
    if (fields.payer.payerId) {
      getInsurancePlansOptions({
        filters: {
          ...(!!fields.leadCheck.planId && { planId: fields.leadCheck.planId }),
          payerId: fields.payer.payerId,
          clinicId: fields.lead.clinicId,
        },
        optionsLoading: setIsPlansOptionsLoading,
        setOptions: setInsurancePlansOptions,
      });
    } else if (fields.lead.clinicId) {
      fetchPayerOptions(setIsPrimaryPayerLoading, setPrimaryPayerOptions);
    }
  }, [fields.payer.payerId]);

  useEffect(() => {
    if (fields.lead.secondaryPayerId) {
      getInsurancePlansOptions({
        filters: {
          ...(!!fields.leadCheck.planId && { planId: fields.leadCheck.planId }),
          payerId: fields.lead.secondaryPayerId,
          clinicId: fields.lead.clinicId,
        },
        optionsLoading: setIsSecondaryPlansOptionsLoading,
        setOptions: setSecondaryInsurancePlansOptions,
      });
    } else if (fields.lead.clinicId) {
      fetchPayerOptions(setIsSecondaryPayerLoading, setSecondaryPayerOptions);
    }
  }, [fields.lead.secondaryPayerId]);

  const loadTaskOwners = async () => {
    try {
      const owners = await eligibilityService.getTaskOwners();
      if (!owners) {
        console.log("Can't get task owners...");
        return;
      }

      setTaskOwners(owners);
    } catch (err) {
      console.log('loadTaskOwner::err: ', err);
    }
  };

  const handleSaveChanges = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (viewMode === ViewMode.Show) {
      return setViewMode(ViewMode.Update);
    }

    // Custom validation
    const isValidDateOfBirth = isValidDateMMDDYYYY(fields.lead.dateOfBirth);
    const isValidDateRequired = isValidDateMMDDYYYY(fields.lead.requiredDate);
    const isValidRequestedDate = isValidDateMMDDYYYY(fields.leadCheck.checkDate);
    const memberInsuranceId = fields.lead.requestInsuranceId;
    const crmField = fields.lead.crmId;
    const hasInsurancePlan = !!fields.insurancePlans?.id;
    const hasEnaraPlan = !!fields.leadCheck.enaraPlanId;
    const isCompleted = [
      TaskStatus.AutoCompleted,
      TaskStatus.CompletedReportedAce,
      TaskStatus.CompletedWithAssistance,
      TaskStatus.ManuallyCompleted,
    ].includes(fields.lead.status as TaskStatus);

    if (!isValidDateOfBirth) {
      setStatusResult({ message: 'Date of Birth is not valid.', withErrors: true });
      return;
    }

    if (!memberInsuranceId) {
      setStatusResult({ message: 'Insurance Member ID is required.', withErrors: true });
      return;
    }

    if (!isValidDateRequired) {
      setStatusResult({ message: 'Date Required is not valid.', withErrors: true });
      return;
    }

    if (!isValidRequestedDate) {
      setStatusResult({ message: 'Requested Date is not valid.', withErrors: true });
      return;
    }

    if (fields.lead.sourceRequest !== TaskSource.AdvancedEligibility && !crmField) {
      setStatusResult({ message: 'CRM ID is required.', withErrors: true });
      return;
    }

    if (isCompleted && !hasEnaraPlan) {
      setStatusResult({
        message: 'Membership is required to set Task Status as Completed.',
        withErrors: true,
      });
      return;
    }

    if (hasEnaraPlan && !isCompleted) {
      setStatusResult({
        message: 'To set the Membership, the task status should include Completed.',
        withErrors: true,
      });
      return;
    }

    if (isCompleted && !hasInsurancePlan) {
      setStatusResult({
        message: 'A matching Insurance Plan is required to set the Task to a Completed status.',
        withErrors: true,
      });
      return;
    }

    setIsSaving(true);

    const allInputTextFields = Object.values(e.target).filter(
      (input: HTMLFormElement) => input.type === 'text' || input.type === 'number',
    );

    const allFieldsAreValid = allInputTextFields.map((input) => input.checkValidity());

    if (allFieldsAreValid.every((field) => field)) {
      const {
        lead: { clinicId, leadOwnerId, requiredDate, requestInsuranceId },
        leadCheck: {
          enaraPlanId,
          activeCoverage,
          deductibleRemaining,
          checkDate,
          planId,
          outOfPocket,
        },
        payer: { notes, payerId },
      } = fields;

      const fieldToUpdateOrCreate = {
        fields: {
          lead: {
            ...fields.lead,
            clinicId: Number(clinicId),
            leadOwnerId: Number(leadOwnerId),
            requiredDate: new Date(requiredDate),
            requestInsuranceId: requestInsuranceId,
          },
          leadCheck: {
            ...(planId && { planId }),
            enaraPlanId: enaraPlanId && Number(enaraPlanId),
            deductibleRemaining: Number(deductibleRemaining),
            outOfPocket: Number(outOfPocket),
            checkDate: new Date(checkDate),
            activeCoverage,
            status: activeCoverage,
            pVerifyInsuranceStatus: pVerifyResponse,
            programType: fields.leadCheck.programType,
            primaryInsuranceSubscriber: fields.leadCheck.primaryInsuranceSubscriber,
          },
          payer: { notes, payerId },
          insurancePlan: {
            id: fields.insurancePlans?.id,
          },
        } as EditableLeadFields,
        resource: EligibilityTab.Leads,
        id: leadId,
      };

      let response: TaskResponse | null = null;

      if (viewMode === ViewMode.Update) {
        response = await eligibilityService.editDetails(fieldToUpdateOrCreate);
      } else if (viewMode === ViewMode.Create) {
        response = await eligibilityService.createRegister(fieldToUpdateOrCreate);
      }

      if (response?.success) {
        MixpanelService.track(`${viewMode} Task`, {
          Fields: fields,
        });

        const message = `Task was successfully ${leadId ? 'updated' : 'created'}`;
        setStatusResult({ message, withErrors: false });

        if (!leadId && response?.lead?.id) {
          redirect(`/eligibility/tasks/${response.lead.id}/show`);
        }
      } else {
        let message = response?.message || 'Error saving task';
        setStatusResult({ message, withErrors: true });
      }
    } else {
      allInputTextFields.forEach((input) => {
        !input.checkValidity() && validateField({ name: input.name, onError: setErrors });
      });
    }

    setIsSaving(false);
  };

  const getInsuranceStatus = async () => {
    const requiredFieldMissing = Object.entries({
      payer_id: fields.payer.payerId,
      first_name: fields.lead.firstName,
      last_name: fields.lead.lastName,
      request_insurance_id: fields.lead.requestInsuranceId,
      date_of_birth: fields.lead.dateOfBirth,
    }).find(([_field, value]) => !value);

    if (requiredFieldMissing) {
      const [name] = requiredFieldMissing;
      setStatusResult({
        message: `Can't get the insurance status without the ${name.split('_').join(' ')}`,
        withErrors: true,
      });
      return;
    }

    const eligibilityData: PVerifyEligibility = {
      payerId: fields.payer.payerId,
      firstName: fields.lead.firstName,
      lastName: fields.lead.lastName,
      dob: fields.lead.dateOfBirth,
      memberInsuranceId: fields.lead.requestInsuranceId,
      startDate: fields.leadCheck.checkDate,
      endDate: fields.leadCheck.checkDate,
    };

    setSectionLoading(true);

    let insuranceStatusResp = null;
    try {
      insuranceStatusResp = await eligibilityService.getPVerifyInsuranceStatus({
        eligibilityData,
      });
    } catch (err) {
      let errMessage = 'Service error validation';
      if (typeof err === 'string') {
        errMessage = err;
      } else if (err instanceof Error) {
        errMessage = err.message;
      }

      setSectionLoading(false);
      setStatusResult({
        message: errMessage,
        withErrors: true,
      });
      return;
    }

    if (!insuranceStatusResp) {
      setSectionLoading(false);
      setStatusResult({
        message: "Can't get the insurance status from external service",
        withErrors: true,
      });
      return;
    }

    MixpanelService.track('Get Insurance Status', {
      payerId: fields.payer.payerId,
      payerName: fields.payer.name,
      externalAPI: ExternalAPI.PVerify,
    });

    setSectionLoading(false);
    if (insuranceStatusResp.status.toLowerCase() === LeadInsuranceStatus.Unknown) {
      setStatusResult({
        message: 'External service gets Unknown data',
        withErrors: true,
      });
      return;
    }

    const pVerifyJSON: InsuranceStatusResponse = insuranceStatusResp.response;
    setPVerifyResponse(pVerifyJSON);
    setFields({
      ...fields,
      ...{
        leadCheck: {
          ...fields.leadCheck!,
          deductibleRemaining: insuranceStatusResp.deductible.toString(),
          activeCoverage: insuranceStatusResp.status.toLowerCase(),
          outOfPocket: insuranceStatusResp?.outOfPocket?.toString(),
        },
      },
    });
  };

  const getSuggestedMemberships = async () => {
    MixpanelService.trackButtonClicked({
      Field: 'Suggest Membership',
      Target: 'View Task',
      source: 'suggest-membership',
      Type: 'link',
    });

    const payload: MembershipComputeByVersionPayload = {
      demographics: {
        clinicId: parseInt(fields.lead.clinicId),
        bmi: parseFloat(fields.lead.bmi),
        dob: fields.lead.dateOfBirth,
        firstName: fields.lead.firstName,
        lastName: fields.lead.lastName,
        memberInsuranceId: fields.lead.requestInsuranceId,
        programType: fields.leadCheck.programType as ProgramType,
      },
      ...(leadId && { taskId: parseInt(leadId) }),
      primaryInsurance: {
        groupId: fields.leadCheck.planId as string,
        groupName: fields.leadCheck.planName as string,
        payerId: fields.payer.payerId,
      },
    };

    if (!payload.demographics.clinicId) {
      setStatusResult({ message: 'Please select a clinic', withErrors: true });
      return;
    }

    if (!payload.primaryInsurance.payerId) {
      setStatusResult({ message: 'Please enter a payer', withErrors: true });
      return;
    }

    setMembershipLoading(true);
    setMembershipResult(false);
    setMembershipSuggested(null);

    const response = await eligibilityService.computeMembership(payload);

    setMembershipLoading(false);
    setMembershipResult(true);

    if (!response) {
      setStatusResult({ message: 'Error getting suggested memberships', withErrors: true });
      return;
    }

    if (!response.success) {
      const message = response?.message ?? 'Error getting suggested memberships';
      setStatusResult({ message, withErrors: true });
      return;
    }

    const content = ShowSuggestMembership({ ...response });
    setMembershipSuggested(content);
  };

  const validatePVerifyData = (localValue: string | undefined, pverifyValue: string | null) => {
    const isOk = localValue === pverifyValue;
    return (
      <InputAdornment position='start'>
        {isOk ? (
          <CheckCircleOutlineIcon style={{ color: 'green' }} />
        ) : (
          <ErrorOutlineIcon style={{ color: 'red' }} />
        )}
      </InputAdornment>
    );
  };

  const formatInsurancePlanMessage = ({
    fields,
    clinics,
  }: {
    fields: {
      payer: { payerId: string };
      lead: { clinicId: string };
    };
    clinics?: { id: number; name: string }[];
  }): string => {
    const clinicName = clinics?.find(({ id }) => `${fields.lead.clinicId}` === `${id}`)?.name || '';
    const clinicText = clinicName ? `clinic: ${clinicName}` : 'clinic: Unknown';
    return `Add a new insurance plan with payerId: ${fields.payer.payerId}, ${clinicText}`;
  };

  if (!isLoaded) {
    return <Loader backButton={{ basePath: TASK_BASE_PATH }} />;
  }

  const managePrimarySubscriberData = (value: string) => {
    const isPrimary = value === 'true';
    setFields({
      ...fields,
      lead: {
        ...fields.lead,
        primaryPolicyHolder: isPrimary,
      },
      leadCheck: {
        ...fields.leadCheck,
        primaryInsuranceSubscriber: {
          ...(!isPrimary && {
            address: null,
            city: null,
            state: null,
            zipCode: null,
            firstName: null,
            lastName: null,
            dateOfBirth: null,
            gender: null,
            relationship: null,
          }),
          isPrimary,
        },
      },
    });
  };

  return (
    <>
      <Title title={`Eligibility: ${leadId ? `Edit Task #${leadId}` : 'Create Task'}`} />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SimpleForm
          toolbar={false}
          onSubmit={handleSaveChanges}
          noValidate
          onChange={(e: any) =>
            validateField({
              name: e.target.name,
              onError: setErrors,
              removeError: e.target.checkValidity(),
            })
          }>
          {/* Controls */}
          <Grid container spacing={2} columns={10} my={1} display='flex'>
            <Grid item mb={3} xs={12} md={12} display='flex' justifyContent='space-between'>
              <BackButton basePath={'/eligibility/tasks'} />
              <Button
                type='submit'
                startIcon={
                  isSaving ? <CircularProgress style={{ color: '#ffffff' }} size={20} /> : <Save />
                }
                variant='contained'
                style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
                disabled={isSaving}>
                <>{getButtonText(viewMode as ViewMode, isSaving, 'Task')}</>
              </Button>
            </Grid>
          </Grid>

          {!fields.insurancePlans?.id && (
            <Box sx={{ width: '100%', marginBottom: 2 }}>
              <Collapse in={alertOpen}>
                <Alert
                  severity='warning'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setAlertOpen(false);
                      }}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}>
                  Please make sure a matching Insurance Plan exists before proceeding.
                </Alert>
              </Collapse>
            </Box>
          )}

          {/* Demographics */}
          <EntitySection
            title='Demographics'
            children={
              <>
                <Grid key={`first-name-container`} item xs={5} md={2}>
                  <MuiTextField
                    name='firstName'
                    fullWidth
                    key={`first-name-text-field`}
                    label='First Name'
                    variant='filled'
                    value={fields.lead.firstName}
                    disabled={viewMode === ViewMode.Show}
                    required
                    error={errors.firstName}
                    helperText={errors.firstName ? 'First name is required' : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFields({
                        ...fields,
                        ...{ lead: { ...fields.lead!, firstName: e.target.value } },
                      })
                    }
                  />
                </Grid>

                <Grid key={`last-name-container`} item xs={5} md={2}>
                  <MuiTextField
                    fullWidth
                    name='lastName'
                    key={`last-name-text-field`}
                    label='Last Name'
                    variant='filled'
                    required
                    error={errors.lastName}
                    disabled={viewMode === ViewMode.Show}
                    helperText={errors.lastName ? 'Last name is required' : ''}
                    value={fields.lead.lastName}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        ...{ lead: { ...fields.lead!, lastName: e.target.value } },
                      })
                    }
                  />
                </Grid>

                <Grid key={`dateOfBirth-container`} item xs={5} md={2}>
                  <LocalizationProvider name='dateOfBirth' dateAdapter={AdapterDateFns}>
                    <DatePicker
                      key={`dateOfBirth-date-picker`}
                      label='Date of Birth'
                      value={fields.lead.dateOfBirth}
                      disabled={viewMode === ViewMode.Show}
                      onChange={(v) =>
                        v &&
                        setFields((prev) => ({
                          ...prev,
                          ...{ lead: { ...prev.lead!, dateOfBirth: v } },
                        }))
                      }
                      renderInput={(params) => <MuiTextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid key={`bmi-container`} item xs={5} md={2}>
                  <MuiTextField
                    fullWidth
                    name='bmi'
                    key={`bmi-text-field`}
                    label='BMI'
                    variant='filled'
                    disabled={viewMode === ViewMode.Show}
                    value={fields.lead.bmi}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        ...{ lead: { ...fields.lead!, bmi: e.target.value } },
                      })
                    }
                  />
                </Grid>

                <Grid key={'clinicId-container'} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='clinic-label'>Clinic</InputLabel>
                    <Select
                      key={`clinicId-select`}
                      label='Clinic'
                      variant='filled'
                      value={fields.lead.clinicId}
                      inputProps={{
                        name: 'clinicId',
                        required: true,
                      }}
                      disabled={viewMode === ViewMode.Show}
                      error={errors.clinicId}
                      onChange={async (e: SelectChangeEvent<string>) => {
                        setFields({
                          ...{ leadCheck: { ...fields.leadCheck, planId: '', planName: '' } },
                          ...{
                            lead: {
                              ...fields.lead!,
                              clinicId: e.target.value,
                              secondaryPayerId: '',
                              secondaryPayerName: '',
                            },
                          },
                          ...{ payer: { ...fields.payer, payerId: '', name: '' } },
                        });

                        validateField({
                          name: e.target.name,
                          onError: setErrors,
                          removeError: !!e.target.value,
                        });
                      }}>
                      {clinics?.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText
                      style={{
                        color: StyledTheme.redDark,
                      }}>
                      {errors.clinicId ? 'Clinic ID is required' : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            }
          />

          {/* Primary Insurance */}
          <EntitySection
            title='Primary Insurance'
            children={
              <>
                <Grid key={`nationalInsurance-container`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='nationalInsurance-label'>National Insurance</InputLabel>
                    <Select
                      key={`nationalInsurance-select`}
                      label='National Insurance'
                      variant='filled'
                      value={fields.lead.nationalInsurance}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'nationalInsurance',
                        required: true,
                      }}
                      endAdornment={
                        pVerifyResponse
                          ? validatePVerifyData(
                              fields.lead.nationalInsurance,
                              pVerifyResponse?.medicareInfoSummary ? 'medicare' : 'no',
                            )
                          : undefined
                      }
                      error={errors.nationalInsurance}
                      onChange={(e: SelectChangeEvent<string>) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            lead: {
                              ...prev.lead,
                              nationalInsurance: e.target.value as NationalInsurance,
                            },
                          },
                        }))
                      }>
                      {toSelectOptions(Object.values(NationalInsurance))?.map((option) => (
                        <MenuItem key={`nationalInsurance-${option.value}`} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText
                      style={{
                        color: StyledTheme.redDark,
                      }}>
                      {errors.nationalInsurance ? 'National insurance is required' : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid key={'insurance-id-container'} item xs={5} md={2}>
                  <MuiTextField
                    fullWidth
                    name='requestInsuranceId'
                    key={`insurance-id-text-field`}
                    label='Insurance ID'
                    variant='filled'
                    required
                    error={errors.requestInsuranceId}
                    disabled={viewMode === ViewMode.Show}
                    helperText={errors.requestInsuranceId ? 'Insurance ID is required' : ''}
                    value={fields.lead.requestInsuranceId}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        ...{ lead: { ...fields.lead!, requestInsuranceId: e.target.value } },
                      })
                    }
                  />
                </Grid>

                <Grid key={`payer-id-container`} item xs={5} md={2}>
                  <AutocompleteField
                    disabled={viewMode === ViewMode.Show || !fields.lead.clinicId}
                    getOptionLabel={(op) => (op.selected ? op.value.toString() : op.label)}
                    inputProps={{
                      name: 'payerId',
                      label: 'Payer ID',
                      value: fields.payer.payerId,
                      required: fields.lead.nationalInsurance !== NationalInsurance.No,
                      error: { isError: !!errors.payerId, helperText: 'Payer ID is required' },
                      endAdornment:
                        viewMode === ViewMode.Show && !fields.payer.payerId ? (
                          <Tooltip
                            title={
                              'The payer does not exist because the task is autocompleted or is unknown'
                            }
                            placement='top'
                            arrow
                            PopperProps={{ style: { width: 200, textAlign: 'center' } }}>
                            <InfoIcon style={{ alignSelf: 'baseline', display: 'flex' }} />
                          </Tooltip>
                        ) : undefined,
                    }}
                    options={primaryPayerOptions}
                    isLoading={isPrimaryPayerLoading}
                    onChange={(
                      e: SyntheticEvent<Element, Event>,
                      value: string | number | undefined,
                      option: AutocompleteOption | null,
                    ) => {
                      if (!option) {
                        if (value) {
                          fetchPayerOptions(setIsPrimaryPayerLoading, setPrimaryPayerOptions, {
                            payerId: `${value}`,
                            name: `${value}`,
                          });
                          return;
                        } else {
                          setFields({
                            ...fields,
                            payer: {
                              ...fields.payer,
                              payerId: '',
                              name: '',
                            },
                          });
                          return;
                        }
                      }

                      if (option.notFound) {
                        redirect(`/eligibility/payers/create?payerId=${value}`);
                      } else {
                        const matchingPayer = payers.find(
                          (payer) => payer.payerId === option?.value,
                        );
                        if (matchingPayer) {
                          setFields({
                            ...fields,
                            payer: {
                              ...fields.payer,
                              name: matchingPayer.name,
                              payerId: matchingPayer.payerId,
                            },
                          });
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid key={`payer-name-container`} item xs={5} md={4}>
                  <TextInput
                    variant='filled'
                    label='Payer Name'
                    name='payerName'
                    value={fields.payer.name}
                    size='medium'
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid key={`group-id-container`} item xs={12} md={4}>
                  <AutocompleteField
                    disabled={
                      viewMode === ViewMode.Show || !fields.payer.payerId || !fields.lead.clinicId
                    }
                    getOptionLabel={(op) => (op.selected ? op.value.toString() : op.label)}
                    isLoading={isPlansOptionsLoading}
                    inputProps={{
                      name: 'planId',
                      label: 'Group ID',
                      value: fields.leadCheck.planId,
                      required: fields.lead.nationalInsurance === NationalInsurance.No,
                      error: { isError: errors.planId, helperText: 'Group ID is required' },
                      missingOptionLabel: formatInsurancePlanMessage({
                        clinics,
                        fields: {
                          payer: { payerId: fields.payer.payerId },
                          lead: { clinicId: fields.lead.clinicId },
                        },
                      }),
                      endAdornment: pVerifyResponse
                        ? validatePVerifyData(
                            fields.leadCheck.planId,
                            pVerifyResponse?.planCoverageSummary?.groupNumber,
                          )
                        : undefined,
                    }}
                    options={insurancePlansOptions}
                    onChange={async (
                      e: SyntheticEvent<Element, Event>,
                      value: string | number | undefined,
                      option: AutocompleteOption | null,
                    ) => {
                      if (!option) {
                        if (value) {
                          getInsurancePlansOptions({
                            filters: {
                              planId: `${value}`,
                              payerId: fields.payer.payerId,
                              clinicId: fields.lead.clinicId,
                            },
                            optionsLoading: setIsPlansOptionsLoading,
                            setOptions: setInsurancePlansOptions,
                          });
                          return;
                        } else {
                          setFields({
                            ...fields,
                            leadCheck: {
                              ...fields.leadCheck,
                              planId: '',
                              planName: '',
                            },
                          });
                          return;
                        }
                      }

                      if (option.notFound) {
                        redirect(
                          getInsurancePlanRedirectUrl(
                            option.value,
                            fields.payer.payerId,
                            fields.payer.name,
                            fields.lead.clinicId ?? '',
                          ),
                        );
                      } else {
                        const matchingGroup =
                          insurancePlans && insurancePlans.find((plan) => plan.id === option?.id);
                        if (matchingGroup) {
                          setFields({
                            ...fields,
                            leadCheck: {
                              ...fields.leadCheck,
                              planId: matchingGroup.planId,
                              planName: matchingGroup.planName,
                            },
                            insurancePlans: {
                              id: matchingGroup.id,
                              insurancePlanDescription: {
                                planId: matchingGroup.planId,
                                planName: matchingGroup.planName,
                              },
                            },
                          });
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid key={`primary-plan-name-container`} item xs={5} md={2}>
                  {fields.lead.nationalInsurance === NationalInsurance.No ||
                  !!fields.leadCheck.planId ? (
                    <MuiTextField
                      variant='filled'
                      label='Group Name'
                      name='planName'
                      value={fields.leadCheck.planName || ''}
                      disabled
                      size='medium'
                      helperText={'Group Name will be completed based on Group ID info'}
                      fullWidth
                      InputProps={{
                        endAdornment: pVerifyResponse
                          ? validatePVerifyData(
                              fields.leadCheck.planName,
                              pVerifyResponse?.planCoverageSummary?.groupName,
                            )
                          : undefined,
                      }}
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel id='plan-name-label'>Group Name</InputLabel>
                      <Select
                        label='Group Name'
                        variant='filled'
                        value={fields.leadCheck.planName}
                        size='medium'
                        error={errors.primaryGroupName}
                        disabled={viewMode === ViewMode.Show}
                        inputProps={{
                          name: 'primaryGroupName',
                          onChange: (e: ChangeEvent<HTMLInputElement>) => {
                            setFields({
                              ...fields,
                              ...{
                                leadCheck: { ...fields.leadCheck!, planName: e.target.value },
                              },
                            });
                          },
                        }}>
                        {primaryPlanNameOptions?.map(({ planId, planName }) => (
                          <MenuItem key={`${planId}-${planName}`} value={planName}>
                            {planName}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText
                        style={{
                          color: StyledTheme.redDark,
                        }}>
                        {primaryPlanNameOptions.length === 0 ? "There aren't options to show" : ''}
                      </FormHelperText>

                      <FormHelperText
                        style={{
                          color: StyledTheme.redDark,
                        }}>
                        {!fields.payer.payerId || !fields.lead.clinicId
                          ? 'Payer ID and Clinic ID are necessary to fill this field'
                          : errors.primaryGroupName
                          ? ' The Group Name is required'
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>

                <Grid key={`primaryPolicyHolder-container`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='primary-policy-holder-label'>Policy Holder</InputLabel>
                    <Select
                      key={`primaryPolicyHolder-select`}
                      label='Policy Holder'
                      variant='filled'
                      value={fields.lead.primaryPolicyHolder?.toString()}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'primaryPolicyHolder',
                      }}
                      onChange={(e: SelectChangeEvent<string>) =>
                        managePrimarySubscriberData(e.target.value)
                      }>
                      {POLICY_HOLDER_OPTIONS?.map((option) => (
                        <MenuItem
                          key={`primaryPolicyHolder-${option.value?.toString()}`}
                          value={option.value?.toString()}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <GridEmptyLine />
                {!fields.lead.primaryPolicyHolder && (
                  <>
                    <Grid key={`primaryPolicyHolder-relationship-container`} item xs={5} md={2}>
                      <FormControl fullWidth>
                        <InputLabel id='primary-policy-holder-relationship-label'>
                          Subscriber Relationship
                        </InputLabel>
                        <Select
                          key={`primaryPolicyHolder-relationship-select`}
                          label='Relationship'
                          variant='filled'
                          value={fields.leadCheck?.primaryInsuranceSubscriber?.relationship ?? ''}
                          disabled={viewMode === ViewMode.Show}
                          inputProps={{
                            name: 'primaryPolicyHolderRelationship',
                          }}
                          onChange={(e: SelectChangeEvent<string>) =>
                            setFields((prev) => ({
                              ...prev,
                              ...{
                                leadCheck: {
                                  ...prev.leadCheck,
                                  primaryInsuranceSubscriber: {
                                    ...prev.leadCheck.primaryInsuranceSubscriber,
                                    relationship: e.target.value,
                                  },
                                },
                              },
                            }))
                          }>
                          {toSelectOptions(Object.values(EligibilityRelationship))?.map(
                            (option) => (
                              <MenuItem
                                key={`primaryPolicyHolderRelationship-${option.value}`}
                                value={option.value}>
                                {option.label}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid key={`subscriber-city-container`} item xs={5} md={2}>
                      <MuiTextField
                        fullWidth
                        name='City'
                        key={`subscriber-city`}
                        label='Subscriber City'
                        variant='filled'
                        value={fields.leadCheck.primaryInsuranceSubscriber?.city ?? ''}
                        disabled={viewMode === ViewMode.Show}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFields({
                            ...fields,
                            leadCheck: {
                              ...fields.leadCheck,
                              primaryInsuranceSubscriber: {
                                ...fields.leadCheck.primaryInsuranceSubscriber,
                                city: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </Grid>

                    <Grid key={`subscriber-state-container`} item xs={5} md={2}>
                      <MuiTextField
                        fullWidth
                        name='state'
                        key={`subscriber-state`}
                        label='Subscriber State'
                        variant='filled'
                        value={fields.leadCheck.primaryInsuranceSubscriber?.state ?? ''}
                        disabled={viewMode === ViewMode.Show}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFields({
                            ...fields,
                            leadCheck: {
                              ...fields.leadCheck,
                              primaryInsuranceSubscriber: {
                                ...fields.leadCheck.primaryInsuranceSubscriber,
                                state: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={5} md={2}>
                      <MuiTextField
                        fullWidth
                        name='Address'
                        key={`subscriber-address`}
                        label='Subscriber Address'
                        variant='filled'
                        value={fields.leadCheck.primaryInsuranceSubscriber?.address ?? ''}
                        disabled={viewMode === ViewMode.Show}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFields({
                            ...fields,
                            leadCheck: {
                              ...fields.leadCheck,
                              primaryInsuranceSubscriber: {
                                ...fields.leadCheck.primaryInsuranceSubscriber,
                                address: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </Grid>

                    <Grid key={`subscriber-dateOfBirth-container`} item xs={5} md={2}>
                      <LocalizationProvider
                        name='subscriber-dateOfBirth'
                        dateAdapter={AdapterDateFns}>
                        <DatePicker
                          key={`subscriber-dateOfBirth-date-picker`}
                          label='Subscriber Date of Birth'
                          value={fields.leadCheck.primaryInsuranceSubscriber?.dateOfBirth}
                          disabled={viewMode === ViewMode.Show}
                          onChange={(v) =>
                            v &&
                            setFields((prev) => ({
                              ...prev,
                              leadCheck: {
                                ...prev.leadCheck,
                                primaryInsuranceSubscriber: {
                                  ...prev.leadCheck.primaryInsuranceSubscriber,
                                  dateOfBirth: v,
                                },
                              },
                            }))
                          }
                          renderInput={(params) => <MuiTextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid key={`subscriber-firstName-container`} item xs={5} md={2}>
                      <MuiTextField
                        fullWidth
                        name='firstName'
                        key={`subscriber-firstName`}
                        label='Subscriber First Name'
                        variant='filled'
                        value={fields.leadCheck.primaryInsuranceSubscriber?.firstName ?? ''}
                        disabled={viewMode === ViewMode.Show}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFields({
                            ...fields,
                            leadCheck: {
                              ...fields.leadCheck,
                              primaryInsuranceSubscriber: {
                                ...fields.leadCheck.primaryInsuranceSubscriber,
                                firstName: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </Grid>

                    <Grid key={`subscriber-lastName-container`} item xs={5} md={2}>
                      <MuiTextField
                        fullWidth
                        name='lastName'
                        key={`subscriber-lastName`}
                        label='Subscriber Last Name'
                        variant='filled'
                        value={fields.leadCheck.primaryInsuranceSubscriber?.lastName ?? ''}
                        disabled={viewMode === ViewMode.Show}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFields({
                            ...fields,
                            leadCheck: {
                              ...fields.leadCheck,
                              primaryInsuranceSubscriber: {
                                ...fields.leadCheck.primaryInsuranceSubscriber,
                                lastName: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </Grid>

                    <Grid key={`primaryPolicyHolder-gender-container`} item xs={5} md={2}>
                      <FormControl fullWidth>
                        <InputLabel id='primary-policy-holder-gender-label'>
                          Subscriber Gender
                        </InputLabel>
                        <Select
                          key={`primaryPolicyHolder-gender-select`}
                          label='Subscriber Gender'
                          variant='filled'
                          value={fields.leadCheck?.primaryInsuranceSubscriber?.gender || 'Unknown'}
                          disabled={viewMode === ViewMode.Show}
                          inputProps={{
                            name: 'primaryPolicyHolderGender',
                          }}
                          onChange={(e: SelectChangeEvent<string>) =>
                            setFields((prev) => ({
                              ...prev,
                              ...{
                                leadCheck: {
                                  ...prev.leadCheck,
                                  primaryInsuranceSubscriber: {
                                    ...prev.leadCheck.primaryInsuranceSubscriber,
                                    gender: e.target.value,
                                  },
                                },
                              },
                            }))
                          }>
                          {toSelectOptions(Object.values(EligibilityGender))?.map((option) => (
                            <MenuItem
                              key={`primaryPolicyHolderGender-${option.value}`}
                              value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            }
          />

          <EntitySection
            title='Secondary Insurance'
            children={
              <>
                <Grid key={`secondary-nationalInsurance-container`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='secondary-nationalInsurance-label'>
                      National Insurance
                    </InputLabel>
                    <Select
                      key={`secondary-nationalInsurance-select`}
                      label='National Insurance'
                      variant='filled'
                      value={fields.lead.secondaryNationalInsurance}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'secondaryNationalInsurance',
                      }}
                      onChange={(e: SelectChangeEvent<string>) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            lead: {
                              ...fields.lead,
                              secondaryNationalInsurance: e.target.value as NationalInsurance,
                            },
                          },
                        }))
                      }>
                      {toSelectOptions(Object.values(NationalInsurance))?.map((option) => (
                        <MenuItem
                          key={`secondary-nationalInsurance-${option.value}`}
                          value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid key={`secondary-request-insurance-id-container`} item xs={5} md={2}>
                  <MuiTextField
                    fullWidth
                    name='secondaryInsuranceMemberId'
                    key={`secondary-insurance-member`}
                    label='Insurance Member ID'
                    variant='filled'
                    value={fields.lead.secondaryInsuranceMemberId}
                    disabled={viewMode === ViewMode.Show}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFields({
                        ...fields,
                        ...{
                          lead: { ...fields.lead!, secondaryInsuranceMemberId: e.target.value },
                        },
                      })
                    }
                  />
                </Grid>

                <Grid key={`secondaryPolicyHolder-container`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='secondary-policy-holder-label'>Policy Holder</InputLabel>
                    <Select
                      key={`secondaryPolicyHolder-select`}
                      label='Policy Holder'
                      variant='filled'
                      value={fields.lead.secondaryPolicyHolder}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'secondaryPolicyHolder',
                      }}
                      onChange={(e: SelectChangeEvent<string>) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            lead: {
                              ...fields.lead,
                              secondaryPolicyHolder: e.target.value,
                            },
                          },
                        }))
                      }>
                      {POLICY_HOLDER_OPTIONS?.map((option) => (
                        <MenuItem
                          key={`secondaryPolicyHolder-${option.toString()}`}
                          value={option.value.toString()}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid key={`secondary-payer-id-container`} item xs={5} md={2}>
                  <AutocompleteField
                    disabled={viewMode === ViewMode.Show || !fields.lead.clinicId}
                    getOptionLabel={(op) => (op.selected ? op.value.toString() : op.label)}
                    inputProps={{
                      name: 'payerId',
                      label: 'Payer ID',
                      value: fields.lead.secondaryPayerId,
                      endAdornment:
                        viewMode === ViewMode.Show && !fields.lead.secondaryPayerId ? (
                          <Tooltip
                            title={
                              'The payer does not exist because the task is autocompleted or is unknown'
                            }
                            placement='top'
                            arrow
                            PopperProps={{
                              style: { width: 200, textAlign: 'center' },
                            }}>
                            <InfoIcon style={{ alignSelf: 'baseline', display: 'flex' }} />
                          </Tooltip>
                        ) : undefined,
                    }}
                    options={secondaryPayerOptions}
                    isLoading={isSecondaryPayerLoading}
                    onChange={(
                      e: SyntheticEvent<Element, Event>,
                      value: string | number | undefined,
                      option: AutocompleteOption | null,
                    ) => {
                      if (!option) {
                        if (value) {
                          fetchPayerOptions(setIsSecondaryPayerLoading, setSecondaryPayerOptions, {
                            payerId: `${value}`,
                            name: `${value}`,
                          });
                          return;
                        } else {
                          setFields({
                            ...fields,
                            lead: {
                              ...fields.lead,
                              secondaryPayerId: '',
                              secondaryPayerName: '',
                            },
                          });
                          return;
                        }
                      }

                      if (option.notFound) {
                        redirect(`/eligibility/payers/create?payerId=${value}`);
                      } else {
                        const matchingPayer = payers.find((payer) => payer.payerId === value);

                        if (matchingPayer) {
                          setFields({
                            ...fields,
                            lead: {
                              ...fields.lead,
                              secondaryPayerId: matchingPayer.payerId,
                              secondaryPayerName: matchingPayer.name,
                            },
                          });
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid key={`secondary-payer-name-container`} item xs={5} md={2}>
                  <MuiTextField
                    fullWidth
                    name='secondaryPayerName'
                    label='Payer Name'
                    variant='filled'
                    value={fields.lead.secondaryPayerName}
                    disabled
                  />
                </Grid>

                <Grid key={`secondary-group-id-container`} item xs={12} md={4}>
                  <AutocompleteField
                    disabled={
                      viewMode === ViewMode.Show ||
                      !fields.lead.secondaryPayerName ||
                      !fields.lead.clinicId
                    }
                    getOptionLabel={(op) => (op.selected ? op.value.toString() : op.label)}
                    isLoading={isSecondaryPlansOptionsLoading}
                    inputProps={{
                      name: 'planId',
                      label: 'Group ID',
                      value: fields.lead.secondaryGroupId,
                      error: { isError: errors.planId, helperText: 'Group ID is required' },
                      missingOptionLabel: formatInsurancePlanMessage({
                        clinics,
                        fields: {
                          payer: { payerId: fields.lead.secondaryPayerId },
                          lead: { clinicId: fields.lead.clinicId },
                        },
                      }),
                    }}
                    options={secondaryInsurancePlansOptions}
                    onChange={async (
                      e: SyntheticEvent<Element, Event>,
                      value: string | number | undefined,
                      option: AutocompleteOption | null,
                    ) => {
                      if (!option) {
                        if (value) {
                          getInsurancePlansOptions({
                            filters: {
                              planId: `${value}`,
                              payerId: fields.lead.secondaryPayerId,
                              clinicId: fields.lead.clinicId,
                            },
                            optionsLoading: setIsSecondaryPlansOptionsLoading,
                            setOptions: setSecondaryInsurancePlansOptions,
                          });
                          return;
                        } else {
                          setFields({
                            ...fields,
                            leadCheck: {
                              ...fields.leadCheck,
                              planId: '',
                              planName: '',
                            },
                          });
                          return;
                        }
                      }

                      if (option.notFound) {
                        redirect(
                          getInsurancePlanRedirectUrl(
                            option.value,
                            fields.lead.secondaryPayerId,
                            fields.lead.secondaryPayerName,
                            fields.lead.clinicId ?? '',
                          ),
                        );
                      } else {
                        const matchingGroup =
                          insurancePlans && insurancePlans.find((plan) => plan.id === option?.id);
                        if (matchingGroup) {
                          setFields({
                            ...fields,
                            lead: {
                              ...fields.lead,
                              secondaryGroupId: matchingGroup.planId,
                              secondaryGroupName: matchingGroup.planName,
                            },
                            insurancePlans: {
                              id: matchingGroup.id,
                              insurancePlanDescription: {
                                planId: matchingGroup.planId,
                                planName: matchingGroup.planName,
                              },
                            },
                          });
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid key={`secondary-plan-name-container`} item xs={5} md={2}>
                  <MuiTextField
                    variant='filled'
                    label='Group Name'
                    name='secondaryPlanName'
                    value={fields.lead.secondaryGroupName}
                    disabled
                    size='medium'
                    fullWidth
                  />
                </Grid>
              </>
            }
          />

          <EntitySection
            title='Insurance Status'
            children={
              <>
                <Grid key={`insurance-status`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Insurance Status</InputLabel>
                    <Select
                      id='insuranceStatus'
                      key={`insurance-status`}
                      label='Insurance Status'
                      variant='filled'
                      value={fields.leadCheck.activeCoverage}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'insuranceStatus',
                      }}
                      onChange={(e: SelectChangeEvent<string>) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            leadCheck: { ...fields.leadCheck!, activeCoverage: e.target.value },
                          },
                        }))
                      }>
                      {toSelectOptions(Object.values(LeadInsuranceStatus)).map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid key={`deductible-remaining-container`} item xs={5} md={2}>
                  <MuiTextField
                    fullWidth
                    name='deductibleRemaining'
                    key={`deductible-remaining`}
                    label='Deductible remaining'
                    variant='filled'
                    type='number'
                    value={fields.leadCheck.deductibleRemaining}
                    disabled={viewMode === ViewMode.Show}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFields({
                        ...fields,
                        ...{
                          leadCheck: {
                            ...fields.leadCheck!,
                            deductibleRemaining: e.target.value,
                          },
                        },
                      })
                    }
                  />
                </Grid>
                <Grid key={`out-of-pocket-container`} item xs={5} md={2}>
                  <MuiTextField
                    fullWidth
                    name='outOfPocket'
                    key={`outOfPocket`}
                    label='Out Of pocket Max Limit'
                    variant='filled'
                    type='number'
                    value={fields.leadCheck.outOfPocket || ''}
                    disabled={viewMode === ViewMode.Show}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFields({
                        ...fields,
                        ...{
                          leadCheck: {
                            ...fields.leadCheck!,
                            outOfPocket: e.target.value,
                          },
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={5} md={2} mb={3}>
                  <Button
                    startIcon={
                      sectionLoading ? (
                        <CircularProgress style={{ color: '#ffffff' }} size={20} />
                      ) : (
                        <SettingsSuggestIcon />
                      )
                    }
                    variant='contained'
                    style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
                    onClick={getInsuranceStatus}
                    disabled={sectionLoading || viewMode === ViewMode.Show}>
                    Check Status
                  </Button>
                </Grid>
                <GridEmptyLine />
                {pVerifyResponse && (
                  <PVerifySummary
                    leadCheckDate={fields.leadCheck.checkDate}
                    pVerifyResponse={pVerifyResponse}
                  />
                )}
              </>
            }
          />

          <EntitySection
            title='Reference'
            children={
              <>
                <Grid key={`task-source`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Source</InputLabel>
                    <Select
                      key={`task-source`}
                      label='Source'
                      variant='filled'
                      value={fields.lead.sourceRequest}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'sourceRequest',
                      }}
                      onChange={(e: SelectChangeEvent) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            lead: { ...prev.lead!, sourceRequest: e.target.value as TaskSource },
                          },
                        }))
                      }>
                      {toSelectOptions(Object.values(TaskSource))?.map((option) => (
                        <MenuItem key={`task-source-${option.value}`} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid key={`required-date-container`} item xs={5} md={2}>
                  <LocalizationProvider name='requiredDate' dateAdapter={AdapterDateFns}>
                    <DatePicker
                      key={`required-date`}
                      label='Date Required'
                      value={fields.lead.requiredDate}
                      disabled={viewMode === ViewMode.Show}
                      onChange={(v) =>
                        v &&
                        setFields((prev) => ({
                          ...prev,
                          ...{ lead: { ...prev.lead!, requiredDate: v } },
                        }))
                      }
                      renderInput={(params) => <MuiTextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid key={`check-date-container`} item xs={5} md={2}>
                  <LocalizationProvider name='checkDate' dateAdapter={AdapterDateFns} fullWidth>
                    <DatePicker
                      key={`check-date`}
                      label='Requested Date'
                      value={fields.leadCheck.checkDate}
                      disabled={viewMode === ViewMode.Show}
                      onChange={(v) =>
                        v &&
                        setFields((prev) => ({
                          ...prev,
                          ...{ leadCheck: { ...prev.leadCheck!, checkDate: v } },
                        }))
                      }
                      renderInput={(params) => <MuiTextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid key={`crmId-container`} item xs={5} md={2}>
                  <MuiTextField
                    name='crmId'
                    fullWidth
                    key={`crmId`}
                    label='CRM ID'
                    variant='filled'
                    error={errors.crmId}
                    disabled={viewMode === ViewMode.Show}
                    required={fields.lead.sourceRequest !== TaskSource.AdvancedEligibility}
                    helperText={errors.crmId ? 'Crm ID is required' : ''}
                    value={fields.lead.crmId}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFields({
                        ...fields,
                        ...{ lead: { ...fields.lead!, crmId: e.target.value } },
                      })
                    }
                  />
                </Grid>
                <Grid key={`memberId-container`} item xs={5} md={2}>
                  <MuiTextField
                    name='memberId'
                    fullWidth
                    key={`memberId`}
                    label='Member ID'
                    variant='filled'
                    type='number'
                    error={errors.memberId}
                    disabled={viewMode === ViewMode.Show}
                    value={fields.lead.memberId}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFields({
                        ...fields,

                        ...{ lead: { ...fields.lead!, memberId: e.target.value } },
                      })
                    }
                  />
                </Grid>
                <Grid key={`partnerPatientId-container`} item xs={5} md={2}>
                  <MuiTextField
                    name='partnerPatientId'
                    fullWidth
                    key={`partnerPatientId`}
                    label='Partner Patient ID'
                    variant='filled'
                    error={errors.partnerPatientId}
                    disabled={viewMode === ViewMode.Show}
                    helperText={errors.partnerPatientId}
                    value={fields.lead.partnerPatientId}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFields({
                        ...fields,
                        ...{ lead: { ...fields.lead!, partnerPatientId: e.target.value } },
                      })
                    }
                  />
                </Grid>
                <Grid key={`lead-owner-container`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Lead Owner</InputLabel>
                    <Select
                      key={`lead-owner`}
                      label='Lead Owner'
                      variant='filled'
                      value={fields.lead.leadOwnerId}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'leadOwnerId',
                      }}
                      onChange={(e: SelectChangeEvent) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{ lead: { ...prev.lead!, leadOwnerId: e.target.value } },
                        }))
                      }>
                      {leadsOwners?.map(({ id, first_name, last_name }) => (
                        <MenuItem key={id} value={id}>{`${first_name} ${last_name}`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid key={`task-owner-container`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Task Owner</InputLabel>
                    <Select
                      key={`taskOwner`}
                      label='Task Owner'
                      variant='filled'
                      value={fields.lead.taskOwner}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'taskOwner',
                      }}
                      onChange={(e: SelectChangeEvent) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            lead: {
                              ...prev.lead!,
                              taskOwner: e.target.value,
                            },
                          },
                        }))
                      }>
                      {taskOwners?.map(({ id, first_name, last_name }) => (
                        <MenuItem key={id} value={id}>{`${first_name} ${last_name}`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid key={`membership-container`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Membership</InputLabel>
                    <Select
                      key={`membership-select`}
                      label='Membership'
                      variant='filled'
                      value={fields.leadCheck.enaraPlanId}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'membership',
                      }}
                      onChange={(e: SelectChangeEvent<string>) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            leadCheck: {
                              ...prev.leadCheck!,
                              enaraPlanId: e.target.value,
                            },
                          },
                        }))
                      }>
                      {enaraPlans &&
                        enaraPlans.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid key={`task-status-container`} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='task-status-label'>Task Status</InputLabel>
                    <Select
                      key={`task-status-select`}
                      label='Task Status'
                      variant='filled'
                      value={fields.lead.status}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'status',
                        required: true,
                      }}
                      error={errors.status}
                      onChange={(e: SelectChangeEvent<string>) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            lead: {
                              ...prev.lead,
                              status: e.target.value.toString() as TaskStatus,
                            },
                          },
                        }))
                      }>
                      {toSelectOptions(Object.values(TaskStatus))?.map((option) => (
                        <MenuItem key={`taskStatus-${option.value}`} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText
                      style={{
                        color: StyledTheme.redDark,
                      }}>
                      {errors.status ? 'The Task Status is required' : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid key={'program-type-container'} item xs={5} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='program-type-label'>Program Type</InputLabel>
                    <Select
                      key={`program-type-select`}
                      label='Program Type'
                      variant='filled'
                      value={fields.leadCheck.programType}
                      disabled={viewMode === ViewMode.Show}
                      inputProps={{
                        name: 'programType',
                      }}
                      onChange={(e: SelectChangeEvent<string>) =>
                        setFields((prev) => ({
                          ...prev,
                          ...{
                            leadCheck: {
                              ...prev.leadCheck!,
                              programType: e.target.value.toString() as ProgramType,
                            },
                          },
                        }))
                      }>
                      {toSelectOptions(Object.values(ProgramType))?.map((option) => (
                        <MenuItem key={`programType-${option.value}`} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <GridEmptyLine />
                <Grid key={`notes-container`} item xs={4}>
                  <MuiTextField
                    fullWidth
                    multiline
                    name='notes'
                    key={`notes`}
                    label='Notes'
                    variant='filled'
                    value={fields.lead.notes}
                    disabled={viewMode === ViewMode.Show}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();

                      setFields({ ...fields, lead: { ...fields.lead, notes: e.target.value } });
                    }}
                  />
                </Grid>
                <GridEmptyLine />
                <Grid key={`button-membership-container`} item xs={5} md={3}>
                  <Button
                    startIcon={
                      membershipLoading ? (
                        <CircularProgress style={{ color: '#ffffff' }} size={20} />
                      ) : (
                        <SettingsSuggestIcon />
                      )
                    }
                    variant='contained'
                    fullWidth
                    style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
                    onClick={getSuggestedMemberships}
                    disabled={membershipLoading || viewMode === ViewMode.Show}>
                    Suggest Membership
                  </Button>
                </Grid>

                {leadId && (
                  <Grid item xs={5} md={2}>
                    <CustomModal
                      buttonProps={{
                        variant: 'contained',
                        sx: {
                          background: StyledTheme.tealDark,
                          '&:hover': {
                            backgroundColor: '#1E83A0',
                          },
                        },
                        fullWidth: true,
                        buttonText: fields.lead.insuranceCardFront
                          ? 'Insurance card image'
                          : 'No insurance card',
                        startIcon: <CreditCardIcon />,
                        disabled: !fields.lead.insuranceCardFront,
                      }}>
                      {fields.lead.insuranceCardFront && (
                        <img
                          alt='Insurance card'
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            backgroundColor: '#EBEBEF',
                          }}
                          src={fields.lead.insuranceCardFront}
                        />
                      )}
                    </CustomModal>
                  </Grid>
                )}

                {leadId && (
                  <Grid item xs={5} md={2}>
                    {fields.lead.crmId ? (
                      <Button
                        variant='contained'
                        target='_blank'
                        href={getLeadDetailsUrl(fields.lead.crmId)}
                        style={{ background: StyledTheme.tealDark }}
                        fullWidth
                        startIcon={<DescriptionIcon color='inherit' />}>
                        {'Lead Details'}
                      </Button>
                    ) : (
                      <Chip
                        icon={<SdCardAlertIcon />}
                        label='Lead details are not available'></Chip>
                    )}
                  </Grid>
                )}

                <Grid key={`button-athena-container`} item xs={5} md={2}>
                  {fields.lead?.externalEHRProfileLink &&
                    fields.lead.sourceRequest === TaskSource.AdvancedEligibility && (
                      <Button
                        variant='contained'
                        target='_blank'
                        href={fields.lead.externalEHRProfileLink}
                        style={{ background: StyledTheme.tealDark }}
                        fullWidth
                        startIcon={<OpenInNewIcon color='inherit' />}>
                        View in EHR
                      </Button>
                    )}
                </Grid>
                {membershipResult && (
                  <Grid key={`membership-result`} item xs={12} md={12}>
                    {membershipSuggested}
                  </Grid>
                )}
              </>
            }
          />

          {leadId && (
            <EntitySection
              title='Lead Documents'
              hint='Links expire in 30 minutes. To review them again refresh the page.'
              direction='column'
              children={(() => {
                const lsqNotes = fields.lead.notesFromCrm?.leadsquared?.notes;
                const filesUrlExist = lsqNotes && lsqNotes.some((n) => n.fileUrl);

                return lsqNotes && filesUrlExist ? (
                  lsqNotes.map(
                    (note, i) =>
                      note.fileUrl && (
                        <Grid item sx={{ typography: 'body1' }} xs={5} md={2}>
                          <Link target='_blank' href={note.fileUrl}>
                            {!note.fileName ? `File #${i}` : note.fileName}
                          </Link>
                        </Grid>
                      ),
                  )
                ) : (
                  <Grid item xs={12} md={12}>
                    <Typography>No document related to the lead found</Typography>
                  </Grid>
                );
              })()}
            />
          )}

          {showRelatedInsurancePlan && (
            <EntitySection
              title='Related Insurance Plan'
              children={
                <RelatedInsurancePlan
                  insurancePlan={{
                    id: fields.insurancePlans?.id!,
                    payerName: fields.payer.name,
                    payerId: fields.payer.payerId,
                    groupId: fields.insurancePlans?.insurancePlanDescription?.planId || '',
                    groupName: fields.insurancePlans?.insurancePlanDescription?.planName || '',
                  }}
                />
              }
            />
          )}

          {fields?.audit && (
            <EntitySection
              title='Audit Information'
              children={
                <Grid item xs={12} md={12}>
                  <AuditInformation entity='tasks' audit={fields.audit} />
                </Grid>
              }
            />
          )}
        </SimpleForm>

        <Portal>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={!!resultStatus}
            autoHideDuration={10000}
            onClose={() => {
              setStatusResult(null);
              setIsSaving(false);
            }}
            message={resultStatus?.message}
            action={
              !resultStatus?.withErrors && (
                <Button size='small' onClick={() => redirect('/eligibility/tasks')}>
                  View
                </Button>
              )
            }
          />
        </Portal>
      </div>
    </>
  );
};
