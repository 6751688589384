import mixpanel, { Dict } from 'mixpanel-browser';
import React, { useRef, useState } from 'react';

mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`);

export type TrackableElementTypes = 'input' | 'checkbox';

export const TrackableElement: React.FC<
  React.PropsWithChildren<{
    properties: { Section: string; Name?: string };
    callback?: Function;
    type?: TrackableElementTypes;
  }>
> = ({ children, properties, callback, type }) => {
  const [oldValue, setOldValue] = useState<string>();
  const [focused, setFocused] = useState<boolean>(false);

  const originalValue = useRef<string | undefined>(undefined);

  const getTarget = (event: React.FocusEvent<HTMLInputElement>): HTMLInputElement | undefined => {
    const { target } = event;
    if (target.tagName !== 'INPUT' && target.nextElementSibling) {
      const sibling = target.nextElementSibling;
      if (sibling.tagName !== 'INPUT') return undefined;
      return sibling as HTMLInputElement;
    }
    return target;
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = getTarget(event) ?? {};
    const parsedValue = !!value?.toString ? value.toString() : value;
    if (parsedValue !== oldValue) {
      if (originalValue.current === undefined) originalValue.current = oldValue;

      MixpanelService.track('Field Was Edited', {
        Name: name,
        'New Value': value,
        'Old Value': oldValue,
        Section: properties.Section,
      });
    }

    setFocused(false);
  };

  const onChangeCheckbox = (event: any) => {
    const checked = event && event.length === 1;

    if (originalValue.current === undefined) originalValue.current = oldValue;

    MixpanelService.track('Field Was Edited', {
      Name: properties.Name,
      'New Value': checked,
      'Old Value': !checked,
      Section: properties.Section,
    });

    callback?.({
      name: properties.Name,
      newValue: checked,
      originalValue: originalValue.current,
    });
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = getTarget(event) ?? {};
    if (value !== undefined && !focused) {
      setOldValue(value);
      setFocused(true);
    }
  };

  const childrenWithEventHandlers = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (type === 'checkbox') {
        return React.cloneElement(child, { onChange: onChangeCheckbox });
      }
      return React.cloneElement(child, { onBlur, onFocus });
    }
    return child;
  });

  return <>{childrenWithEventHandlers}</>;
};

export default class MixpanelService {
  public static track(eventName: string, properties?: Dict) {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.track(eventName, properties);
    } else {
      console.log('[MIXPANEL]', eventName, properties);
    }
  }

  /**
   * General use
   */

  public static trackPageView({ pathname, search }: { pathname?: string; search?: string }) {
    MixpanelService.track('Page View', { Route: pathname, Search: search });
  }

  public static trackButtonClicked(properties?: Dict) {
    MixpanelService.track('Button Clicked', properties);
  }

  public static trackSearch(search: string, path: string) {
    if (search === '') {
      return;
    }

    const tabName = MixpanelService.getTabNameFromPath(path);
    MixpanelService.track('Search bar usage', {
      Tab: tabName,
      Search: search,
    });
  }

  public static trackCreate(path: string) {
    const tabName = MixpanelService.getTabNameFromPath(path);
    MixpanelService.track('Create', {
      Field: 'Create ' + tabName,
      Tab: tabName,
      Type: 'link',
      source: path,
    });
  }

  private static getTabNameFromPath(path: string): string {
    const routes: string[] = path.split('/');
    const tabName: string = routes[routes.length - 1];

    const modifiedTabName = tabName
      .split('-')
      .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
      .join(' ');

    return modifiedTabName;
  }
}
