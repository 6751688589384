import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { StyledTheme } from '../../styles/styleTheme';

type Props = {
  onChange: (page: number) => void;
  page: number;
  pages: number;
};

const Pagination: FC<Props> = ({ page, pages, onChange }) => {
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' my={2}>
      {page > 1 && (
        <Button onClick={() => onChange(page - 1)} style={{ color: StyledTheme.tealDark }}>
          Previous page
        </Button>
      )}
      <Button disabled>{page}</Button>
      {page < pages && (
        <Button onClick={() => onChange(page + 1)} style={{ color: StyledTheme.tealDark }}>
          Next page
        </Button>
      )}
    </Box>
  );
};

Pagination.defaultProps = {
  page: 1,
  pages: 1,
};

export default Pagination;
