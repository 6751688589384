import { AuditInfo, AuditType } from '../../interfaces/eligibility';
import { Column, CustomTable } from '../common/CustomTable';

export const AuditInformation = ({ entity, audit }: { entity: string; audit: AuditInfo }) => {
  const columns = [
    { key: 'action', label: 'Action' },
    { key: 'userId', label: 'User Id', align: 'right' },
    { key: 'firstName', label: 'First Name', align: 'right' },
    { key: 'lastName', label: 'Last Name', align: 'right' },
    { key: 'date', label: 'Date', align: 'right' },
  ] as Column[];
  const rows = [];

  const { create, update } = audit;

  if (create) {
    rows.push({
      action: AuditType.Create,
      userId: create?.id,
      firstName: create?.firstName,
      lastName: create?.lastName,
      date: create?.date,
    });
  }

  if (update) {
    rows.push({
      action: AuditType.LastUpdate,
      userId: update?.id,
      firstName: update?.firstName,
      lastName: update?.lastName,
      date: update?.date,
    });
  }

  return <CustomTable columns={columns} key={`audit-info-${entity}`} rows={rows} />;
};
