import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormAction } from '../../../../interfaces/common';
import { Payer, PayerWithAuditDetails } from '../../../../interfaces/eligibility';
import eligibilityService from '../../../../services/eligibility';
import MixpanelService from '../../../../services/mixpanel';
import { EligibilityTab } from '../../eligibility.types';
import { PayerForm } from './PayerForm';
import { PayerFormFields } from './payer.types';

export const PayersUpdate = () => {
  const [resultMessage, setResultMessage] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [fields, setFields] = useState<PayerFormFields | null>(null);

  const { payerId } = useParams<{ payerId: string }>();

  const handleChange = async (formFields: PayerFormFields) => {
    fields && setFields(formFields);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const response = await eligibilityService.editDetails<Payer>({
      id: payerId,
      fields: fields!,
      resource: EligibilityTab.Payers,
    });

    setIsLoaded(true);

    MixpanelService.track('Update Payer', {
      Fields: fields,
    });

    if (response) {
      const { message, success } = response;

      if (success) {
        setResultMessage('Payer was successfully updated');
      } else if (message) {
        setResultMessage(message);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const payer = await eligibilityService.getDetails<PayerWithAuditDetails>({
        id: payerId,
        resource: EligibilityTab.Payers,
      });

      if (payer) {
        setFields({
          name: payer.name,
          payerId: payer.payerId,
          memberCallNumber: payer.memberCallNumber,
          providerCallNumber: payer.providerCallNumber,
          notes: payer.notes,
          externalId: payer.externalId,
          audit: payer.audit,
          aperoPayerId: payer.aperoPayerId,
          payerClinics: payer.payerClinics,
        });
      }

      setIsLoaded(true);
    })();
  }, [payerId]);

  return (
    <PayerForm
      isLoaded={isLoaded}
      promptProps={{ message: resultMessage }}
      actionButtonProps={{ label: 'Update' }}
      title={`Edit Payer #${payerId}`}
      initialFields={fields}
      onChange={handleChange}
      onSubmit={handleSubmit}
      action={FormAction.UPDATE}>
      <Grid item xs={12}>
        <Typography>{`Payer: ${fields?.name}`}</Typography>
      </Grid>
    </PayerForm>
  );
};
