import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { FC } from 'react';
import { AlertComponentProps } from '../../interfaces/common';

export const CustomAlertComponent: FC<AlertComponentProps> = (props) => (
  <Alert severity={props.severity} style={{ marginTop: 10, marginBottom: 10 }}>
    <AlertTitle>
      <b>{props.title}</b>
    </AlertTitle>
    {props.content}
  </Alert>
);
