import axios from 'axios';
import {
  NotEngagedReferrals,
  ReferralNotEngagedReferralsResponse,
  ReferralResultResponse,
  UploadUrlResult,
} from '../interfaces/referral';
import { normalizeReferralFileName } from '../util/stringFormat';
import { AuthService } from './';

const REFERRAL_BASE_URL = process.env.REACT_APP_PUBLIC_REFERRAL_BASE_URL;

const DEBUG_PHONE_PARAM = 'debugPhone';

class ReferralService {
  public getDebugPhoneFromUrl(urlSearch: string): string | null {
    return new URLSearchParams(urlSearch).get(DEBUG_PHONE_PARAM);
  }

  public async createUploadUrl(fileName: string): Promise<UploadUrlResult | null> {
    const authData = AuthService.getAuth();

    try {
      const { uploadUrl, destination } = (
        await axios.post<UploadUrlResult>(
          `${REFERRAL_BASE_URL}/v1/referrals/create-upload-link`,
          { fileName: normalizeReferralFileName(fileName), fileType: 'application/pdf' },
          { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
        )
      ).data;

      return { uploadUrl, destination };
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async pushContentAndParseReferral(
    clinicCode: string,
    uploadUrl: string,
    sourceFile: string,
    referral: File,
  ): Promise<ReferralResultResponse | null> {
    const authData = AuthService.getAuth();

    try {
      // Upload referral file to S3
      await axios.put(uploadUrl, referral, {
        timeout: 60 * 1000,
      });

      const url: string = `${REFERRAL_BASE_URL}/v1/clinic/${clinicCode}/fax/${sourceFile}/parse`;

      // Parse uploaded referral fax
      const parseResponse = (
        await axios.get<ReferralResultResponse>(url, {
          timeout: 60 * 1000,
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
        })
      ).data;

      return parseResponse;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async sendSmsForReferral(
    parsingResult: ReferralResultResponse,
    debugPhone: string | null,
  ): Promise<{ status: boolean; reason: string | null }> {
    const {
      referral: { clinicReferralId },
    } = parsingResult;

    if (!clinicReferralId) {
      return { status: false, reason: 'Missing referral ID' };
    }

    const authData = AuthService.getAuth();

    try {
      if (!debugPhone) {
        const referral = await this.fetchReferral(clinicReferralId);

        if (referral) {
          const {
            referral: {
              member: { smsNotifications },
            },
          } = referral;

          if (smsNotifications?.find(({ type }) => type === 'partner-referral')) {
            return {
              status: false,
              reason: `The sms was already sent for referral ID  - ${clinicReferralId}`,
            };
          }
        }
      }

      const { smsContent } = (
        await axios.post<{ smsContent: string }>(
          `${REFERRAL_BASE_URL}/v1/referrals/${clinicReferralId}/send`,
          { debugPhone },
          { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
        )
      ).data;

      return { status: true, reason: smsContent };
    } catch (error) {
      console.error(error);

      return { status: false, reason: (error as any).message };
    }
  }

  public async fetchNotEngagedReferrals(): Promise<NotEngagedReferrals | null> {
    const authData = AuthService.getAuth();

    try {
      const { referrals } = (
        await axios.get<ReferralNotEngagedReferralsResponse>(
          `${REFERRAL_BASE_URL}/v1/referrals/not-engaged`,
          { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
        )
      ).data;

      return referrals
        .sort((r1, r2) => (Date.parse(r2.referralDate) || -1) - (Date.parse(r1.referralDate) || -1))
        .reduce((notEngaged, r) => {
          const { clinicReferralId, ...rest } = r;
          return { ...notEngaged, [r.clinicReferralId]: { ...rest, id: clinicReferralId } };
        }, {} as NotEngagedReferrals);
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public async fetchReferral(clinicReferralId: string): Promise<ReferralResultResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const referral = (
        await axios.get<ReferralResultResponse>(
          `${REFERRAL_BASE_URL}/v1/referrals/${clinicReferralId}`,
          { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
        )
      ).data;

      return referral;
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}

const referralService = new ReferralService();

export default referralService;
