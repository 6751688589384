import { EligibilityTab, EligibilityTabComponent } from './eligibility.types';
import { AllInsurancePlans } from './insurancePlans/AllInsurancePlans';
import { AllPayers } from './payers/AllPayers/AllPayers';
import { AllLeadsChecks } from './tasks/AllTasks';

export const ELIGIBILITY_ELEMENTS_PER_PAGE = 20;

export const eligibilityTabs: EligibilityTabComponent[] = [
  {
    label: 'Insurance Plans',
    name: EligibilityTab.InsurancePlans,
    href: '/eligibility/insurance-plans',
    ListComponent: AllInsurancePlans,
  },
  {
    label: 'Tasks',
    name: EligibilityTab.Tasks,
    href: '/eligibility/tasks',
    ListComponent: AllLeadsChecks,
  },
  {
    label: 'Payers',
    name: EligibilityTab.Payers,
    href: '/eligibility/payers',
    ListComponent: AllPayers,
  },
];
