export const UpperCaseWords = (str: string) =>
  str?.replace(/^(.)|\s+(.)/g, (c) => c?.toUpperCase());

export const GetClinicNameFromClinicCode = (clinicCode: string) =>
  clinicCode?.split('.')[2]?.replace('-', ' ') ?? '';

export const GetFullClinicNameFromClinicCode = (clinicCode: string) => {
  const clinic = clinicCode?.split('.');
  const group = clinic[1]?.replace('-', ' ') ?? '';
  const name = clinic[2]?.replace('-', ' ') ?? '';
  return `${group} ${name}`;
};

export const normalizeReferralFileName = (fileName: string): string => {
  return fileName.replaceAll(/\s+/g, '-');
};
