import { Chip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const EntitySection = ({
  title,
  children,
  direction = 'row',
  hint,
}: {
  title: string;
  hint?: string;
  children: React.ReactNode;
  direction?: 'column' | 'row';
}) => {
  return (
    <Card sx={{ width: '100%', marginBottom: 2 }}>
      <CardContent>
        <Typography variant='h5' component='div'>
          {title}
          {hint && <Chip label={hint} sx={{ marginLeft: 2 }} />}
        </Typography>
        <Grid container spacing={2} columns={10} my={1} display='flex' flexDirection={direction}>
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
};
