import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { PriorityLevel } from './tasks.types';

export const TaskPriorityHint = ({ priority }: { priority: number }) => {
  switch (priority) {
    case PriorityLevel.Highest:
      return <KeyboardDoubleArrowUpIcon color='error' />;
    case PriorityLevel.High:
      return <KeyboardControlKeyIcon color='error' />;
    case PriorityLevel.Medium:
      return <DragHandleIcon style={{ color: 'orange' }} />;
    default:
      return <KeyboardArrowDownIcon color='info' />;
  }
};
