import { Button, CircularProgress, Snackbar, Typography } from '@material-ui/core';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Alert, AlertTitle } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Authenticated, FileField, FileInput, SelectInput, SimpleForm } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';
import { getClinicsForSelect } from '../../lib/clinic';
import MixpanelService from '../../services/mixpanel';
import referralService from '../../services/referral';

export const ReferralsUploader: FC = () => {
  const [selectedReferral, setSelectedReferral] = useState<File | null>(null);
  const [processingReferral, setProcessingReferral] = useState<boolean>(false);
  const [snackbarText, setSnackbarText] = useState<string | null>(null);
  const [smsContent, setSmsContent] = useState<string | null>(null);
  const [clinics, setClinics] = useState<{ id: string; name: string }[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<string>('');
  const history = useHistory();
  const { pathname } = useLocation();

  const handleOnUploadReferral = async (): Promise<void> => {
    if (!selectedClinic) {
      return setSnackbarText('Please select a clinic');
    }

    if (!selectedReferral) {
      return setSnackbarText('Missing referral fax. Select one to start the process.');
    }

    setSmsContent(null);
    setProcessingReferral(true);

    const uploadUrlResult = await referralService.createUploadUrl(selectedReferral.name);

    if (!uploadUrlResult) {
      return setSnackbarText('Could not create upload URL.');
    }

    const parsingResult = await referralService.pushContentAndParseReferral(
      selectedClinic,
      uploadUrlResult.uploadUrl,
      uploadUrlResult.destination,
      selectedReferral
    );

    if (!parsingResult) {
      return setSnackbarText(
        'An error occurred while trying to upload the referral and parse its content.'
      );
    }
    MixpanelService.trackButtonClicked({
      Field: 'Upload',
      source: pathname,
      referral: { ...parsingResult?.referral },
    });

    const { status, reason } = await referralService.sendSmsForReferral(
      parsingResult,
      referralService.getDebugPhoneFromUrl(history.location.search)
    );

    if (!status) {
      return setSnackbarText(
        `It was not possible to send the SMS to the referred user. ${reason}.`
      );
    }

    setSmsContent(reason);
    setSnackbarText('The SMS was successfully sent to the referred user');
  };

  useEffect(() => {
    const getClinics = async () => {
      const ids: number[] = JSON.parse(`${process.env.REACT_APP_REFERRALS_ALLOWED_CLINIC_IDS}`);
      const clinicsAllowed = await getClinicsForSelect({
        ids,
      });
      const clinicsForSelect = clinicsAllowed.map((clinic) => ({
        id: clinic.slug,
        name: clinic.name,
      }));
      setClinics(clinicsForSelect);
    };
    getClinics();
  }, []);

  return (
    <Authenticated>
      <>
        <Alert variant='filled' severity='warning'>
          <AlertTitle>Important</AlertTitle>
          Processing multiple referrals at the same time is not supported yet!
        </Alert>

        <Typography variant='h4' style={{ marginTop: 20 }}>
          Referrals - <strong>One file at a time</strong>
        </Typography>

        <SimpleForm toolbar={false}>
          <SelectInput
            source='clinic'
            choices={clinics}
            isRequired
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setSelectedClinic(event.target.value)
            }
          />
          <FileInput
            source={'files'}
            accept={'application/pdf'}
            label={'Fax'}
            isRequired
            multiple={false}
            placeholder={
              <div>
                Drop (or click to upload) the PDF file of the referral that will be processed
              </div>
            }
            onChange={setSelectedReferral}>
            <FileField source={'src'} title={'title'} />
          </FileInput>
        </SimpleForm>

        <div style={{ width: 200 }}>
          <Button
            variant='contained'
            onClick={handleOnUploadReferral}
            startIcon={<FileUploadIcon />}
            disabled={!selectedReferral || processingReferral}>
            Upload
            {processingReferral && <CircularProgress style={{ marginLeft: 5 }} size={20} />}
          </Button>
        </div>

        {smsContent && (
          <Alert style={{ marginTop: 20 }}>
            The SMS sent to the user contains the following information: {smsContent}
          </Alert>
        )}

        <Snackbar
          open={!!snackbarText}
          autoHideDuration={10000}
          onClose={() => {
            setSnackbarText(null);
            setProcessingReferral(false);
          }}
          message={snackbarText}
        />
      </>
    </Authenticated>
  );
};
