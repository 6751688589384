export const defaultStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '35px 30px 45px 30px',
  width: '700px',
  backgroundColor: '#fff',
  boxShadow:
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  height: '500px',
  border: '0px',
  borderRadius: '5px',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
};

export const buttonDefaultStyles = {
  textTransform: 'none',
  backgroundColor: '#1A667A',
  borderRadius: '8px',
  borderWidth: 0,
  color: '#ffff',
  cursor: 'pointer',
  display: 'inline-block',
  fontFamily: '"Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  listStyle: 'none',
  margin: 0,
  padding: '10px 12px',
  textAlign: 'center',
  transition: 'all 200ms',
  verticalAlign: 'baseline',
  whiteSpace: 'nowrap',
  userSelect: 'none',
  touchAction: 'manipulation',
  '&:hover': {
    backgroundColor: '#2292B3',
    boxShadow: 'none',
  },
};
