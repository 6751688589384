import { DatesSetArg, EventClickArg, EventContentArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { ClaimStatus, ClaimType, EhrClaim } from '../../interfaces/billing';

interface CalendarProps {
  onEventClick: (clickInfo: EventClickArg) => void;
  onDateSetClick: (startDate: string, endDate: string) => void;
  claims: EhrClaim[];
  isLoading: boolean;
}

const CALENDAR_RIGHT_OPTIONS = ['dayGridMonth', 'listWeek'].join(',');
const CALENDAR_LEFT_OPTIONS = 'prev,next today';

const renderEventContent = (eventContent: EventContentArg) => {
  const { event } = eventContent;
  const className = event.extendedProps.source === ClaimType.RPM ? 'rpm-event' : 'other-event';

  return (
    <Tooltip title={`Claim ID: ${event.id} - ${event.extendedProps.status}`} placement='top' arrow>
      <Box display='flex' alignItems='center' maxWidth='100%' overflow='hidden'>
        {event.extendedProps.status === ClaimStatus.UploadedWithErrors && (
          <WarningIcon className={className} />
        )}
        {event.extendedProps.status === ClaimStatus.Processed && (
          <CheckCircleIcon className={className} />
        )}
        {event.extendedProps.status === ClaimStatus.Pending && (
          <AccessTimeFilledIcon color='warning' />
        )}
        {event.extendedProps.status === ClaimStatus.Uploaded && (
          <CloudSyncIcon className={className} />
        )}

        <Typography variant='body2' className={className} noWrap>
          {event.title}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export const ClaimsCalendar: React.FC<CalendarProps> = ({
  claims,
  isLoading,
  onDateSetClick,
  onEventClick,
}) => {
  const events = claims.map((claim) => ({
    id: `${claim.id}`,
    title: claim.corePatient?.firstName
      ? `${claim.corePatient?.firstName} ${claim.corePatient?.lastName}`
      : claim.id
      ? `Claim ID: ${claim.id?.toString()}`
      : 'Unknown',
    start: moment.utc(claim.serviceDate).toISOString(),
    end: moment.utc(claim.serviceDate).toISOString(),
    extendedProps: claim,
  }));

  const handleDatesSet = (arg: DatesSetArg) => {
    onDateSetClick(arg.startStr, arg.endStr);
  };

  return (
    <Box mt={4} position='relative'>
      {isLoading && (
        <div className='loading-overlay'>
          <div className='loading-spinner'></div>
        </div>
      )}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        initialView='dayGridMonth'
        headerToolbar={{
          left: CALENDAR_LEFT_OPTIONS,
          center: 'title',
          right: CALENDAR_RIGHT_OPTIONS,
        }}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        events={events}
        eventClick={onEventClick}
        eventContent={renderEventContent}
        datesSet={handleDatesSet}
        timeZone='UTC'
        height={'calc(100vh - 225px)'}
      />
    </Box>
  );
};
