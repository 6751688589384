import PostAddIcon from '@mui/icons-material/PostAdd';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { useRedirect } from 'react-admin';
import MixpanelService from '../../services/mixpanel';
import { StyledTheme } from '../../styles/styleTheme';

export const SpeedDialCustom = ({ pathname, title }: { pathname: string; title: string }) => {
  const redirect = useRedirect();
  return (
    <SpeedDial
      ariaLabel='speed-dial-options'
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 54,
        '& .MuiSpeedDial-fab': {
          background: StyledTheme.tealDark,
          color: StyledTheme.white,
          boxShadow:
            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
        },
        '& .MuiSpeedDial-fab:hover': {
          background: StyledTheme.blueMunsell,
        },
      }}
      direction='up'
      icon={<SpeedDialIcon />}>
      <SpeedDialAction
        key={`speed-dial-${new Date().getTime()}`}
        tooltipTitle={title}
        icon={<PostAddIcon />}
        sx={{
          background: StyledTheme.aquamarine,
          color: StyledTheme.white,
          boxShadow:
            'box-shadow: rgba(21, 142, 112, 0.2) 0px 40px 40px,rgba(21, 142, 112, 0.2) 0px -12px 20px,rgba(21, 142, 112, 0.2) 0px 4px 6px,rgba(21, 142, 112, 0.2) 0px 12px 9px,rgba(21, 142, 112, 0.09) 0px -3px 5px;',
        }}
        onClick={() => {
          redirect(pathname);
          MixpanelService.trackCreate(pathname);
        }}
      />
    </SpeedDial>
  );
};
