import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { SnackBarProps } from '../../interfaces/common';

export const CustomSnackbar = ({
  autoHideDuration,
  horizontal = 'center',
  message,
  severity,
  vertical = 'bottom',
  open,
  onClose,
}: SnackBarProps) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      open={open}
      autoHideDuration={autoHideDuration || 10000}
      key={`snackbar-${vertical}-${horizontal}`}
      style={{ top: '15%' }}
      onClose={onClose}>
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
