import axios from 'axios';
import {
  AperoSyncClinicsResponse,
  BillingTransform,
  EHRClaimSync,
  EHRSyncPayload,
  EhrClaimResponse,
  EhrClaimResyncPayload,
  EhrClaimsResponse,
} from '../interfaces/billing';
import { AuthService } from './auth';

const ENARA_API_URL = process.env.REACT_APP_ENARA_BASE_URL;

export class BillingService {
  public async getTransformedFile(
    data: FormData,
    clinicId: number,
    isHL7: boolean,
  ): Promise<BillingTransform[] | null> {
    const authData = AuthService.getAuth();
    try {
      const response = await axios.post(`${ENARA_API_URL}/billing/billing/v1/transform`, data, {
        headers: {
          Authorization: `Bearer ${authData?.auth_token}`,
          'content-type': 'multipart/form-data',
          'Clinic-Id': `${clinicId}`,
          ...(isHL7 && { Hl7: `${isHL7}` }),
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async runEhrClaimSync(payload: EHRSyncPayload): Promise<AperoSyncClinicsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.post<AperoSyncClinicsResponse>(
        `${ENARA_API_URL}/billing/billing/v1/ehr-claims-syncs`,
        payload,
        { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async reSyncEhrClaim(
    claimId: number,
    payload?: EhrClaimResyncPayload,
  ): Promise<EhrClaimResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.post<EhrClaimResponse>(
        `${ENARA_API_URL}/billing/billing/v1/ehr-claims/${claimId}/syncs`,
        payload,
        { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaims(payload: EHRClaimSync): Promise<EhrClaimsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get(`${ENARA_API_URL}/billing/billing/v1/ehr-claims-syncs`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
        params: payload,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaim(id: number): Promise<EhrClaimResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get(`${ENARA_API_URL}/billing/billing/v1/ehr-claims/${id}`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
