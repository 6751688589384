import { Save } from '@mui/icons-material';
import { Button, Grid, Portal, Snackbar, Stack } from '@mui/material';
import { SimpleFormProps } from 'ra-ui-materialui';
import { useEffect, useState } from 'react';
import { SimpleForm, Title, useRedirect } from 'react-admin';
import { StyledTheme } from '../../styles/styleTheme';
import { BackButton } from '../common/BackButton';
import { Loader } from '../common/Loader';
import { WrapperFormProps } from './eligibility.types';

type Props = SimpleFormProps & WrapperFormProps;

export const WrapperForm = ({
  children,
  title,
  promptProps,
  actionButtonProps,
  isLoaded,
  ...restProps
}: Props): JSX.Element => {
  const [message, setMessage] = useState<string>('');

  const redirect = useRedirect();

  useEffect(() => {
    promptProps.message && setMessage(promptProps.message);
  }, [promptProps.message]);

  if (!isLoaded) {
    return <Loader backButton={{ basePath: restProps.basePath }} />;
  }

  return (
    <>
      <Stack>
        <Title title={title} />
        <SimpleForm toolbar={false} noValidate {...restProps}>
          <>
            <Grid container justifyContent='space-between' alignItems='center' display='flex'>
              <Grid item mb={2} xs={12} display='flex' justifyContent='space-between'>
                <BackButton basePath={restProps.basePath} />
                <Button
                  type='submit'
                  startIcon={<Save />}
                  variant='contained'
                  style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
                  disabled={!isLoaded}>
                  {actionButtonProps.label}
                </Button>
              </Grid>
            </Grid>
            {[children]}
          </>
        </SimpleForm>
      </Stack>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!message}
          autoHideDuration={10000}
          onClose={() => setMessage('')}
          message={message}
          action={
            <Button size='small' onClick={() => redirect(restProps.basePath)}>
              {promptProps.buttonLabel}
            </Button>
          }
        />
      </Portal>
    </>
  );
};
