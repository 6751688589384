import { Box, Card, Grid, LinearProgress, Typography } from '@mui/material';
import { StyledTheme } from '../../styles/styleTheme';
import { BackButton } from './BackButton';

type Props = {
  backButton?: { basePath: string };
};

export const Loader = ({ backButton }: Props) => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '32px',
      }}>
      <Grid container display='flex' justifyContent='center' mb={6}>
        <Grid item display='flex' alignItems='center' flexDirection='column'>
          <Typography style={{ marginBottom: '30px' }}>
            Wait a moment please... it might take a few seconds
          </Typography>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              sx={{
                backgroundColor: StyledTheme.grayLight,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: StyledTheme.tealDark,
                },
              }}
            />
          </Box>
          {backButton && <BackButton basePath={backButton.basePath} includeMargin />}
        </Grid>
      </Grid>
    </Card>
  );
};
