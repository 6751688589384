import { IClinic } from '../../../interfaces/clinic';

export type TaskView = {
  id: number;
  name: string;
  clinicId: number;
  leadOwnerId: number;
  crmId: number;
  requestedDate: string;
  requiredDate: string;
  status: string;
  taskOwner: string;
  sourceRequest: string;
  checkDate: string;
  membership: string;
  memberId: string;
  rowId: number;
};

export type TaskWithLeadOwnerAndClinic = TaskView & {
  leadOwner: string;
  clinic?: IClinic;
  originalId: number;
};

export enum PriorityLevel {
  Highest = 1,
  High = 2,
  Medium = 3,
  Low = 4,
}
