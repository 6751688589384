import { DataProvider } from 'react-admin';

/**
 * Fake provider, required because react-admin requires a dataProvider
 * However, it seems we are not using it at all!
 * We could move out of React Admin and use a more simple solution.
 * @deprecated
 * @returns
 */
const dataProvider = (): DataProvider => {
  return {
    getList: () => Promise.reject(),
    getOne: () => Promise.reject(),
    getMany: () => Promise.reject(),
    getManyReference: () => Promise.reject(),
    update: () => Promise.reject(),
    updateMany: () => Promise.reject(),
    create: () => Promise.reject(),
    delete: () => Promise.reject(),
    deleteMany: () => Promise.reject(),
  };
};

export default dataProvider;
