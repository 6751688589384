import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import MixpanelService from '../../../../services/mixpanel';
import { StyledTheme } from '../../../../styles/styleTheme';
import { formatDateStringToMMDDYYYY } from '../../../../util/dateFormat';
import { InsuranceStatusResponse } from './pVerify.types';

type pVerifySummaryProps = {
  leadCheckDate: string;
  pVerifyResponse: InsuranceStatusResponse;
};

export const PVerifySummary: React.FC<pVerifySummaryProps> = ({
  leadCheckDate,
  pVerifyResponse,
}) => {
  return (
    <Card sx={{ width: '100%', margin: 5, bgcolor: '#dddddd' }} variant='outlined'>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ fontWeight: 'bold', marginRight: '25px' }} variant='subtitle1'>
            Plan Coverage Summary
          </Typography>
          {!!pVerifyResponse?.detailsURL && (
            <Button
              size='small'
              variant='contained'
              style={{ background: StyledTheme.tealDark, color: StyledTheme.white }}
              startIcon={<DescriptionIcon />}
              onClick={() => {
                MixpanelService.trackButtonClicked({
                  Field: 'View',
                  source: 'pVerify-summary',
                  Target: 'View Complete Report',
                  Type: 'link',
                });

                window.open(pVerifyResponse.detailsURL, '_blank');
              }}>
              Complete Report
            </Button>
          )}
        </Box>
        <Typography fontSize={13}>
          Last Check pVerify:{' '}
          {leadCheckDate
            ? formatDateStringToMMDDYYYY(leadCheckDate)
            : formatDateStringToMMDDYYYY(new Date().toISOString())}
        </Typography>
        <br />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontWeight: 'bold' }} variant='subtitle1'>
            Primary Insurance:
          </Typography>
          <Box sx={{ marginLeft: 10 }}>
            <Typography variant='subtitle2' component='div'>
              Payer Name: {pVerifyResponse?.payerName || 'N/A'}
            </Typography>
            <Typography variant='subtitle2' component='div'>
              Group ID: {pVerifyResponse?.planCoverageSummary?.groupNumber}
            </Typography>
            {pVerifyResponse?.planCoverageSummary?.groupName && (
              <Typography variant='subtitle2' component='div'>
                Group Name: {pVerifyResponse.planCoverageSummary.groupName}
              </Typography>
            )}
            <Typography variant='subtitle2' component='div'>
              National Insurance: {!!pVerifyResponse?.medicareInfoSummary ? 'Medicare' : 'No'}
            </Typography>
            {pVerifyResponse.demographicInfo && (
              <Typography variant='subtitle2' component='div'>
                Relationship to Subscriber:{' '}
                {pVerifyResponse?.planCoverageSummary?.subscriberRelationship || 'Self'}
              </Typography>
            )}
          </Box>
        </Box>

        {!!pVerifyResponse?.otherPayerInfo?.secondaryPayer && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 'bold' }} variant='subtitle1'>
              Secondary Insurance:
            </Typography>
            <Typography variant='subtitle2' component='div'>
              Payer Name: {pVerifyResponse?.otherPayerInfo?.secondaryPayer}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
