import { useEffect } from 'react';
import { Layout, LayoutProps } from 'react-admin';
import { useLocation } from 'react-router-dom';
import MixpanelService from '../services/mixpanel';
import { CustomAppBar } from './customAppBar';
import { CustomMenu } from './customMenu';

export const CustomLayout: React.FC<LayoutProps> = (props) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    MixpanelService.trackPageView({ pathname, search });
  }, [pathname, search]);

  return <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} />;
};
