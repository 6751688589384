import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { EhrClaimLogEntry } from '../../interfaces/billing';
import { StyledTheme } from '../../styles/styleTheme';

interface ClaimLogsProps {
  logs: EhrClaimLogEntry[];
}

export const ClaimLogs: React.FC<ClaimLogsProps> = ({ logs = [] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedLogs = logs.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  const paginatedLogs = sortedLogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Table size='small'>
        <TableHead style={{ background: StyledTheme.grayLight }}>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedLogs.map((log, index) => (
            <TableRow key={index}>
              <TableCell>
                <b>{log.createdAt}</b>
              </TableCell>
              <TableCell>{log.type}</TableCell>
              <TableCell>{log.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={logs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
